import ApolloClient from 'vue-apollo'
import { config } from '~/config/'

export default class BaseService {
  protected client: ApolloClient
  protected nonPersistedClient?: ApolloClient
  protected tessituraVersion: '15' | '16' = config.TESSITURA_VERSION

  constructor(client: ApolloClient, nonPersistedClient: ApolloClient) {
    this.client = client
    this.nonPersistedClient = nonPersistedClient
  }

  /**
   * Utility to reduce boilerplate parsing gql responses
   *
   * @param { any } response - response data
   *
   * @return { any } data - the data parsed
   */
  public respond(response) {
    const { data } = response

    return data
  }

  /**
   * Call this method to force the page to hard refresh if we
   * hit an odd response and need a reset
   *
   * @return { void }
   */
  public hardRetry(): void {
    if (process.client) {
      const params = new URLSearchParams(window.location.search)
      const reload = params.get('reload')

      if (!reload) {
        window.location.search = '?reload=1'
      }
    }
  }

  public isLoading(): boolean {
    return this.client.loading
  }
}
