import * as customer from '~/api/queries/customer.gql'

import {
  Customer,
  Country,
  State,
  CustomerInput,
  CreateCustomerInput,
  NewsletterInput,
  CustomerAddressInput,
} from '~/@types/skyway'
import BaseService from '~/services/base'

export default class AccountService extends BaseService {
  async getActiveCustomer(
    fetchPolicy,
    query = 'getActiveCustomer'
  ): Promise<{ getActiveCustomer: Customer }> {
    const response = await this.client.query({
      query: customer[query],
      fetchPolicy,
    })

    return this.respond(response)
  }

  async getCountries(fetchPolicy): Promise<{ getCountries: Country[] }> {
    const response = await this.client.query({
      query: customer['getCountries'],
      fetchPolicy,
    })

    return this.respond(response)
  }

  async getStates(fetchPolicy): Promise<{ getStates: State[] }> {
    const response = await this.client.query({
      query: customer['getStates'],
      context: {
        public: true,
      },
      fetchPolicy,
    })

    return this.respond(response)
  }

  async createCustomer(
    input: CreateCustomerInput,
    recaptcha: string
  ): Promise<boolean> {
    const response = await this.client.mutate({
      mutation: customer['createCustomer'],
      variables: {
        createCustomerInput: input,
        recaptcha,
      },
    })

    return this.respond(response)
  }

  async createConstituent(args: {
    customerInput: CustomerInput
    customerAddressInput?: CustomerAddressInput
  }): Promise<{ createConstituent: Customer }> {
    const response = await this.client.mutate({
      mutation: customer['createConstituent'],
      variables: {
        customerInput: args.customerInput,
        customerAddressInput: args.customerAddressInput,
      },
    })

    return this.respond(response)
  }

  async getInterestTypes() {
    const response = await this.client.query({
      query: customer['getInterestTypes'],
      context: {
        public: true,
      },
    })

    return this.respond(response)
  }

  async newsletterSignup(newsletterInput: NewsletterInput, recaptcha: string) {
    const response = await this.client.mutate({
      mutation: customer['newsletterSignup'],
      variables: {
        newsletterInput,
        recaptcha,
      },
    })

    return this.respond(response)
  }
}
