
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import CheckboxField from '~/components/ui/fields/v2/UICheckboxField.vue'

import {
  ContactPreferenceType,
  CustomerContactPreference,
} from '~/@types/skyway'

@Component({
  components: { CheckboxField },
})
export default class CustomerPreferenceItem extends Vue {
  @Prop({ type: Object, required: true })
  contactPreferenceType!: ContactPreferenceType
  @Prop({ type: Array, required: true })
  value!: CustomerContactPreference[]
  @Prop({ type: Boolean, default: false }) editing!: boolean

  get isSelected() {
    const existing = this.value.find(
      (p) =>
        parseInt(p.type_ref) === parseInt(this.contactPreferenceType.type_ref)
    )
    return !!existing
  }

  set isSelected(value) {
    const existing = this.value.findIndex(
      (p) =>
        parseInt(p.type_ref) === parseInt(this.contactPreferenceType.type_ref)
    )

    /**
     * doesn't exist - let's add and output new array
     * or does exist - remove and output new array
     */
    if (existing === -1) {
      this.$emit('input', [...this.value, this.contactPreferenceType])
    } else {
      this.$emit(
        'input',
        this.value.filter(
          (p) => p && p.type_ref !== this.contactPreferenceType.type_ref
        )
      )
    }
  }
}
