import { ActionTree, MutationTree, GetterTree, ActionContext } from 'vuex'

import { RootState } from './types'
import { Event, Instance, Seat } from '~/@types/skyway'

export const name = 'selection'

/**
 * The selection store module is concerned with storing customer options
 * client side, prior to being pushed to the basket, it gives us a central
 * place to access details about selected seats, etc. without having to rely
 * on local component state or the event bus
 *
 * We should aim to make this the single source of truth
 */

export interface PriceSelection {
  price_type_ref?: any
  instance_ref?: any
  qty?: any
  zone_ref?: any
  special_requests?: string
}

export interface State {
  event?: Event
  multiple?: Event
  instance?: Instance
  zone?: Number
  package?: Number
  prices?: Array<PriceSelection>
  selection?: Array<PriceSelection>
  seats?: Array<Seat>
}

export const state = (): State => ({
  event: undefined,
  multiple: undefined,
  instance: undefined,
  zone: undefined,
  package: undefined,
  prices: [],
  selection: [],
  seats: [],
})

export const types = {
  SET_EVENT: 'SET_EVENT',
  SET_MULTIPLE_TIMED: 'SET_MULTIPLE_TIMED',
  SET_INSTANCE: 'SET_INSTANCE',
  CLEAR_SELECTION: 'CLEAR_SELECTION',
  CLEAR_PRICES: 'CLEAR_PRICES',
  SET_PRICE: 'SET_PRICE',
  SET_SELECTION: 'SET_SELECTION',
  SET_SEAT: 'SET_SEAT',
  SET_ZONE: 'SET_ZONE',
  SET_PACKAGE: 'SET_PACKAGE',
  REMOVE_SEAT: 'REMOVE_SEAT',
  CLEAR_SEATS: 'CLEAR_SEATS',
}

export const getters: GetterTree<State, RootState> = {
  /**
   * Format the parameters for adding a ticket to the basket
   * If a zone has already been selected by the user, we pass that.
   * Otherwise we use the zone from the selected price type
   */
  getTicketSelection: (state: State): Array<PriceSelection> | null => {
    if (state.prices) {
      return state.prices
    }

    return null
  },

  getSelectedEvent: (state: State): Event | null => {
    if (state.event) {
      return state.event
    }

    return null
  },

  getSelectedInstance: (state: State): Instance | null => {
    if (state.instance) {
      return state.instance
    }

    return null
  },
}

export const actions: ActionTree<State, RootState> = {
  setSelectedInstance(
    context: ActionContext<State, RootState>,
    payload: Instance
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      context.commit(types.SET_INSTANCE, payload)
      resolve(true)
    })
  },
}

export const mutations: MutationTree<State> = {
  [types.SET_EVENT](state: State, payload: Event) {
    state.event = payload
  },
  [types.SET_MULTIPLE_TIMED](state, payload: Event[]) {
    const newEvent = payload.reduce(
      (result, event) => {
        event.instances &&
          event.instances.forEach((instance) => {
            result.instances.push(instance)
          })

        // push the first instance modes of sale up to event level.
        // quick hack... see https://alienation.atlassian.net/browse/HCF-431
        const modes_of_sale =
          event.instances &&
          event.instances[0] &&
          event.instances[0].modes_of_sale

        return Object.assign({}, event, { ...result, modes_of_sale })
      },
      {
        instances: [],
      }
    )

    state.multiple = newEvent
  },
  [types.SET_INSTANCE](state: State, payload: Instance) {
    state.instance = payload
  },
  [types.SET_PRICE](state: State, payload: any) {
    if (state.prices) {
      const index = state.prices.findIndex((price) => {
        return price.price.price_type_ref == payload.price.price_type_ref
      })

      if (index !== -1) {
        if (payload.qty === 0) {
          state.prices.splice(index, 1, payload)
        } else {
          state.prices[index] = payload
        }
      } else if (payload.qty > 0) {
        state.prices.push(payload)
      }
    }
  },
  [types.SET_SELECTION](state: State, payload: any) {
    if (state.selection) {
      const index = state.selection.findIndex((price) => {
        return Number(price.price_type_ref) === Number(payload.price_type_ref)
      })

      if (index !== -1) {
        if (payload.qty === 0) {
          state.selection.splice(index, 1, payload)
        } else {
          state.selection[index] = payload
        }
      } else if (payload.qty > 0) {
        state.selection.push(payload)
      }
    }
  },
  [types.CLEAR_SELECTION](state: State) {
    state.instance = undefined
    state.event = undefined
    state.prices = []
    state.selection = []
    state.seats = []
  },
  [types.CLEAR_PRICES](state: State) {
    state.prices = []
  },
  [types.CLEAR_SEATS](state: State) {
    state.seats = []
  },
  [types.SET_SEAT](
    state: State,
    payload: { seat: Seat; price_type_ref: number }
  ) {
    if (state.seats) {
      const index = state.seats.findIndex(
        (seat) =>
          parseInt(seat.seat.seat_ref) === parseInt(payload.seat.seat_ref)
      )

      if (index === -1) {
        state.seats.push(payload)
      }
    }
  },
  [types.SET_ZONE](state: State, payload: number) {
    state.zone = payload
  },
  [types.SET_PACKAGE](state: State, payload: number) {
    state.package = payload
  },
  [types.REMOVE_SEAT](state: State, payload: Seat) {
    if (state.seats) {
      state.seats.splice(
        state.seats.findIndex(
          (seat) => parseInt(seat.seat_ref) === parseInt(payload.seat_ref)
        ),
        1
      )
    }
  },
}
