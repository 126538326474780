
import { Component, Prop, Vue } from 'nuxt-property-decorator'

import { Facility } from '~/@types/skyway'

@Component()
export default class OurVenuesVenueGettingHere extends Vue {
  @Prop({ type: Object }) venue?: Facility
  @Prop({ type: String }) cssClass?: string

  getDirections(): void {
    window.open(this.directionsHref, '_blank')
  }

  get directionsHref(): string {
    return `https://maps.google.com/?q=${this.venue.address1},${this.venue.address2},${this.venue.city},${this.venue.county},${this.venue.postcode}`
  }
}
