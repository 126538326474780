import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import fetch from 'node-fetch'
import { config } from '~/config'
import { decodeToken } from '../utils/decodeToken'

/**
 * The token "link" is actually two links, which first validate and refresh the token
 * and then secondly attach the token to the queued operation.
 */
export class TokenLink {
  protected expires: string = ''
  protected tokenName: string = 'token'
  protected refreshVarName: string = 'token_refresh'
  protected ctx: any
  protected options: any
  protected hasCookie: boolean = false

  constructor(ctx, options = {}) {
    this.ctx = ctx
    this.options = options
  }

  getLinks(): Array<any> {
    return process.client ? [this.getRefreshLink(), this.getAttachLink()] : []
  }

  getRefreshLink(): TokenRefreshLink {
    return new TokenRefreshLink({
      isTokenValidOrUndefined: this.hasValidToken.bind(this),
      fetchAccessToken: this.fetch.bind(this),
      handleFetch: (accessToken) => {
        this.setAccessToken(accessToken)
      },
      handleResponse: (operation, accessTokenField) => async (token) => {
        return {
          access_token: token,
        }
      },
      handleError: this.handleError.bind(this),
    })
  }

  handleError(err): void {
    // full control over handling token fetch Error
    console.warn("Your token can't be refreshed.")
    console.error(err)
    this.ctx.app.$eventBus.notifyFailure(
      'There was an error connecting to our ticketing system. This could be caused by unusually high demand, please try again in a moment'
    )
  }

  getAttachLink(): ApolloLink {
    return setContext((operation, previousContext) => {
      // If this is a public request, don't attach the bearer token
      // which would scope the cache to the current user
      if (previousContext.public) {
        return previousContext
      }

      const token = this.getAccessToken()
      const { headers } = previousContext

      return {
        ...previousContext,
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : null,
        },
      }
    })
  }

  hasValidToken(): boolean {
    const accessToken = this.getAccessToken()

    let valid = Boolean(
      accessToken && typeof accessToken === 'string' && accessToken.length > 0
    )

    if (valid) {
      valid = !this.isTokenExpired(accessToken)
    }

    return valid
  }

  fetch(): Promise<any> {
    console.log('FETCHING ACCESS TOKEN')
    return this.fetchRefreshedToken()
  }

  fetchRefreshedToken(): Promise<any> {
    const input = {}
    input[this.refreshVarName] = this.getAccessToken()

    const body = JSON.stringify({
      query: `query RefreshToken($refreshTokenInput: RefreshTokenInput!) {
                refreshToken(input: $refreshTokenInput){
                    ${this.tokenName}
                }
            }`,
      variables: {
        refreshTokenInput: input,
      },
      fetchPolicy: 'no-cache',
    })

    return fetch(this.getEndpoint(), {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((json) =>
        json.data.refreshToken ? json.data.refreshToken[this.tokenName] : null
      )
      .catch((err) => {
        console.log('error in fetch', err)
      })
  }

  /**
   * Set the access token in the customer store for use in future requests
   */
  setAccessToken(accessToken: string): void {
    const accessTokenDecrypted = decodeToken(accessToken)

    this.ctx.store.commit(`customer/SET_TOKEN`, accessToken)

    if (
      accessTokenDecrypted.data &&
      accessTokenDecrypted.data.logged_in === true &&
      !this.isAnonymousUser(accessTokenDecrypted)
    ) {
      this.ctx.store.commit(`customer/SET_LOGGED_IN`, true)
    } else {
      this.ctx.store.commit(`customer/SET_LOGGED_IN`, false)
    }
  }

  /**
   * Get the access token from the customer store
   */
  getAccessToken(): string {
    return this.ctx.store.state['customer'].token
  }

  isTokenExpired(accessToken: string): boolean {
    const accessTokenDecrypted = decodeToken(accessToken)

    const expiry = this.ctx.$moment.unix(accessTokenDecrypted.exp)

    if (expiry) {
      const now = this.ctx.$moment()

      return expiry.isBefore(now)
    }

    return true
  }

  isAnonymousUser(accessTokenDecrypted: any): boolean {
    console.log(
      'Anon user received: ',
      Boolean(
        accessTokenDecrypted.data &&
          accessTokenDecrypted.data.user &&
          accessTokenDecrypted.data.user.includes('anonymous')
      )
    )
    return Boolean(
      accessTokenDecrypted.data &&
        accessTokenDecrypted.data.user &&
        accessTokenDecrypted.data.user.includes('anonymous')
    )
  }

  getEndpoint(): string {
    return config.GRAPHQL_ENDPOINT
  }
}
