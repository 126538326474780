
import { Component, Vue, Prop } from 'vue-property-decorator'
import SvgClock from '~/static/images/sprites/clock.svg'
import SvgLocation from '~/static/images/sprites/location.svg'
import SvgCalendar from '~/static/images/sprites/calendar.svg'
import SvgLocalActivity from '~/static/images/sprites/local-activity.svg'
import SvgDonate from '~/static/images/sprites/donate.svg'

@Component({
  components: {
    SvgClock,
    SvgLocation,
    SvgCalendar,
    SvgLocalActivity,
    SvgDonate
  }
})
export default class MetaNavItem extends Vue {
  @Prop({ type: Object, default: () => {} }) item

  onClickMetaNavItem(): void {
    this.$eventBus.emit('meta-nav-item:click', this.item)
  }
}
