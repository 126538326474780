import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _dbca914c = () => interopDefault(import('../src/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _63892340 = () => interopDefault(import('../src/pages/adult-classes/index.vue' /* webpackChunkName: "pages/adult-classes/index" */))
const _1aced0a2 = () => interopDefault(import('../src/pages/archive/index.vue' /* webpackChunkName: "pages/archive/index" */))
const _390eff26 = () => interopDefault(import('../src/pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _3e260213 = () => interopDefault(import('../src/pages/classes/index.vue' /* webpackChunkName: "pages/classes/index" */))
const _c39e907c = () => interopDefault(import('../src/pages/collections/index.vue' /* webpackChunkName: "pages/collections/index" */))
const _5e168a22 = () => interopDefault(import('../src/pages/dates.vue' /* webpackChunkName: "pages/dates" */))
const _6b341062 = () => interopDefault(import('../src/pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _3a367508 = () => interopDefault(import('../src/pages/field-trips/index.vue' /* webpackChunkName: "pages/field-trips/index" */))
const _fb192d18 = () => interopDefault(import('../src/pages/forgotten-password.vue' /* webpackChunkName: "pages/forgotten-password" */))
const _0434aa06 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _6a5450ba = () => interopDefault(import('../src/pages/password-reset.vue' /* webpackChunkName: "pages/password-reset" */))
const _71d6d229 = () => interopDefault(import('../src/pages/register.vue' /* webpackChunkName: "pages/register" */))
const _7d9008d2 = () => interopDefault(import('../src/pages/resources/index.vue' /* webpackChunkName: "pages/resources/index" */))
const _5e807ba4 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _620499c2 = () => interopDefault(import('../src/pages/sign-in.vue' /* webpackChunkName: "pages/sign-in" */))
const _0c569600 = () => interopDefault(import('../src/pages/stories/index.vue' /* webpackChunkName: "pages/stories/index" */))
const _8dfeb7ce = () => interopDefault(import('../src/pages/youth-classes/index.vue' /* webpackChunkName: "pages/youth-classes/index" */))
const _bf0b3f04 = () => interopDefault(import('../src/pages/account/membership.vue' /* webpackChunkName: "pages/account/membership" */))
const _4e54ef82 = () => interopDefault(import('../src/pages/account/saved-items.vue' /* webpackChunkName: "pages/account/saved-items" */))
const _7bc3f6ef = () => interopDefault(import('../src/pages/account/tickets.vue' /* webpackChunkName: "pages/account/tickets" */))
const _37899481 = () => interopDefault(import('../src/pages/purchase/checkout/index.vue' /* webpackChunkName: "pages/purchase/checkout/index" */))
const _602f5a00 = () => interopDefault(import('../src/pages/purchase/checkout/cancelled.vue' /* webpackChunkName: "pages/purchase/checkout/cancelled" */))
const _0e86b9f5 = () => interopDefault(import('../src/pages/purchase/checkout/payment.vue' /* webpackChunkName: "pages/purchase/checkout/payment" */))
const _1ebe8172 = () => interopDefault(import('../src/pages/purchase/checkout/success.vue' /* webpackChunkName: "pages/purchase/checkout/success" */))
const _aea2254c = () => interopDefault(import('../src/pages/purchase/gifts/membership.vue' /* webpackChunkName: "pages/purchase/gifts/membership" */))
const _4b21abe6 = () => interopDefault(import('../src/pages/purchase/gifts/redeem-gift-membership.vue' /* webpackChunkName: "pages/purchase/gifts/redeem-gift-membership" */))
const _226e53da = () => interopDefault(import('../src/pages/purchase/gifts/voucher.vue' /* webpackChunkName: "pages/purchase/gifts/voucher" */))
const _a2c62288 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _63429e53 = () => interopDefault(import('../src/pages/purchase/packages/_id/index.vue' /* webpackChunkName: "pages/purchase/packages/_id/index" */))
const _fb45bbb6 = () => interopDefault(import('../src/pages/purchase/schools/_id/index.vue' /* webpackChunkName: "pages/purchase/schools/_id/index" */))
const _67dc20e6 = () => interopDefault(import('../src/pages/purchase/tickets/_id/index.vue' /* webpackChunkName: "pages/purchase/tickets/_id/index" */))
const _42c2c3aa = () => interopDefault(import('../src/pages/adult-classes/_id/index.vue' /* webpackChunkName: "pages/adult-classes/_id/index" */))
const _4f39db7e = () => interopDefault(import('../src/pages/classes/_id/index.vue' /* webpackChunkName: "pages/classes/_id/index" */))
const _caf7bca6 = () => interopDefault(import('../src/pages/collections/_id/index.vue' /* webpackChunkName: "pages/collections/_id/index" */))
const _0eea2a4d = () => interopDefault(import('../src/pages/events/_id/index.vue' /* webpackChunkName: "pages/events/_id/index" */))
const _173dcbf3 = () => interopDefault(import('../src/pages/field-trips/_id/index.vue' /* webpackChunkName: "pages/field-trips/_id/index" */))
const _4e0a1847 = () => interopDefault(import('../src/pages/forms/_id/index.vue' /* webpackChunkName: "pages/forms/_id/index" */))
const _2c183234 = () => interopDefault(import('../src/pages/timed-entry/_id/index.vue' /* webpackChunkName: "pages/timed-entry/_id/index" */))
const _8f6feaf8 = () => interopDefault(import('../src/pages/youth-classes/_id/index.vue' /* webpackChunkName: "pages/youth-classes/_id/index" */))
const _6c34d89c = () => interopDefault(import('../src/pages/timed-entry/_id/_date/index.vue' /* webpackChunkName: "pages/timed-entry/_id/_date/index" */))
const _885eb1e2 = () => interopDefault(import('../src/pages/timed-entry/_id/_date/_time/index.vue' /* webpackChunkName: "pages/timed-entry/_id/_date/_time/index" */))
const _06bfcbe6 = () => interopDefault(import('../src/pages/stories/_.vue' /* webpackChunkName: "pages/stories/_" */))
const _e7d07442 = () => interopDefault(import('../src/pages/resources/_.vue' /* webpackChunkName: "pages/resources/_" */))
const _daef9e6e = () => interopDefault(import('../src/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'link--active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account/",
    component: _dbca914c,
    pathToRegexpOptions: {"strict":true},
    name: "account"
  }, {
    path: "/adult-classes/",
    component: _63892340,
    pathToRegexpOptions: {"strict":true},
    name: "adult-classes"
  }, {
    path: "/archive/",
    component: _1aced0a2,
    pathToRegexpOptions: {"strict":true},
    name: "archive"
  }, {
    path: "/cart/",
    component: _390eff26,
    pathToRegexpOptions: {"strict":true},
    name: "cart"
  }, {
    path: "/classes/",
    component: _3e260213,
    pathToRegexpOptions: {"strict":true},
    name: "classes"
  }, {
    path: "/collections/",
    component: _c39e907c,
    pathToRegexpOptions: {"strict":true},
    name: "collections"
  }, {
    path: "/dates/",
    component: _5e168a22,
    pathToRegexpOptions: {"strict":true},
    name: "dates"
  }, {
    path: "/events/",
    component: _6b341062,
    pathToRegexpOptions: {"strict":true},
    name: "events"
  }, {
    path: "/field-trips/",
    component: _3a367508,
    pathToRegexpOptions: {"strict":true},
    name: "field-trips"
  }, {
    path: "/forgotten-password/",
    component: _fb192d18,
    pathToRegexpOptions: {"strict":true},
    name: "forgotten-password"
  }, {
    path: "/maintenance/",
    component: _0434aa06,
    pathToRegexpOptions: {"strict":true},
    name: "maintenance"
  }, {
    path: "/password-reset/",
    component: _6a5450ba,
    pathToRegexpOptions: {"strict":true},
    name: "password-reset"
  }, {
    path: "/register/",
    component: _71d6d229,
    pathToRegexpOptions: {"strict":true},
    name: "register"
  }, {
    path: "/resources/",
    component: _7d9008d2,
    pathToRegexpOptions: {"strict":true},
    name: "resources"
  }, {
    path: "/search/",
    component: _5e807ba4,
    pathToRegexpOptions: {"strict":true},
    name: "search"
  }, {
    path: "/sign-in/",
    component: _620499c2,
    pathToRegexpOptions: {"strict":true},
    name: "sign-in"
  }, {
    path: "/stories/",
    component: _0c569600,
    pathToRegexpOptions: {"strict":true},
    name: "stories"
  }, {
    path: "/youth-classes/",
    component: _8dfeb7ce,
    pathToRegexpOptions: {"strict":true},
    name: "youth-classes"
  }, {
    path: "/account/membership/",
    component: _bf0b3f04,
    pathToRegexpOptions: {"strict":true},
    name: "account-membership"
  }, {
    path: "/account/saved-items/",
    component: _4e54ef82,
    pathToRegexpOptions: {"strict":true},
    name: "account-saved-items"
  }, {
    path: "/account/tickets/",
    component: _7bc3f6ef,
    pathToRegexpOptions: {"strict":true},
    name: "account-tickets"
  }, {
    path: "/purchase/checkout/",
    component: _37899481,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-checkout"
  }, {
    path: "/purchase/checkout/cancelled/",
    component: _602f5a00,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-checkout-cancelled"
  }, {
    path: "/purchase/checkout/payment/",
    component: _0e86b9f5,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-checkout-payment"
  }, {
    path: "/purchase/checkout/success/",
    component: _1ebe8172,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-checkout-success"
  }, {
    path: "/purchase/gifts/membership/",
    component: _aea2254c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-gifts-membership"
  }, {
    path: "/purchase/gifts/redeem-gift-membership/",
    component: _4b21abe6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-gifts-redeem-gift-membership"
  }, {
    path: "/purchase/gifts/voucher/",
    component: _226e53da,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-gifts-voucher"
  }, {
    path: "/",
    component: _a2c62288,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/purchase/packages/:id/",
    component: _63429e53,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-packages-id"
  }, {
    path: "/purchase/schools/:id/",
    component: _fb45bbb6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-schools-id"
  }, {
    path: "/purchase/tickets/:id/",
    component: _67dc20e6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tickets-id"
  }, {
    path: "/adult-classes/:id/",
    component: _42c2c3aa,
    pathToRegexpOptions: {"strict":true},
    name: "adult-classes-id"
  }, {
    path: "/classes/:id/",
    component: _4f39db7e,
    pathToRegexpOptions: {"strict":true},
    name: "classes-id"
  }, {
    path: "/collections/:id/",
    component: _caf7bca6,
    pathToRegexpOptions: {"strict":true},
    name: "collections-id"
  }, {
    path: "/events/:id/",
    component: _0eea2a4d,
    pathToRegexpOptions: {"strict":true},
    name: "events-id"
  }, {
    path: "/field-trips/:id/",
    component: _173dcbf3,
    pathToRegexpOptions: {"strict":true},
    name: "field-trips-id"
  }, {
    path: "/forms/:id/",
    component: _4e0a1847,
    pathToRegexpOptions: {"strict":true},
    name: "forms-id"
  }, {
    path: "/timed-entry/:id/",
    component: _2c183234,
    pathToRegexpOptions: {"strict":true},
    name: "timed-entry-id"
  }, {
    path: "/youth-classes/:id/",
    component: _8f6feaf8,
    pathToRegexpOptions: {"strict":true},
    name: "youth-classes-id"
  }, {
    path: "/timed-entry/:id?/:date/",
    component: _6c34d89c,
    pathToRegexpOptions: {"strict":true},
    name: "timed-entry-id-date"
  }, {
    path: "/timed-entry/:id?/:date?/:time/",
    component: _885eb1e2,
    pathToRegexpOptions: {"strict":true},
    name: "timed-entry-id-date-time"
  }, {
    path: "/stories/*",
    component: _06bfcbe6,
    pathToRegexpOptions: {"strict":true},
    name: "stories-all"
  }, {
    path: "/resources/*",
    component: _e7d07442,
    pathToRegexpOptions: {"strict":true},
    name: "resources-all"
  }, {
    path: "/*",
    component: _daef9e6e,
    pathToRegexpOptions: {"strict":true},
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
