import { Vue } from 'nuxt-property-decorator'
import { gtmProductsTransformer } from '~/modules/helpers/gtmProductsTransformer'
import { sumBy } from 'lodash'

export default ({ app }) => {

    /**
     * SOURCE ID
     */
    app.$eventBus.on('update_source', (source) => {
        if (source != undefined && source > 0) {
            app.store.dispatch('basket/setSource', parseInt(source));
        }
    });

    /**
     * PROMO CODE
     */
    app.$eventBus.on('update_promo_code', (promo) => {
      if (promo != undefined) {
        app.store.dispatch('basket/applyPromoCode', promo).then((res) => {
          app.$eventBus.emit('promo_code_applied', promo)
        })
      }
    })


    /**
     * MODE OF SALE
     */
    app.$eventBus.on('update_mode_of_sale', (mos) => {
        if (mos != undefined && mos > 0) {
            app.store.dispatch('basket/setModeOfSale', parseInt(mos));
        }
    });

    app.$eventBus.on('revert_mode_of_sale', () => {
        app.store.dispatch('basket/revertModeOfSale');
    });

    /**
   * GOOGLE ANALYTICS - GA4
   */

  // incomplete; currently no way of adding correct product value
  app.$eventBus.on('gtm:add-to-basket', (product) => {
    const payload  = {
      instance_ref: product.id,
      name: product.name,
      qty: product.quantity,
      //value: product.value,
      category: product.category,
    }
    app.$gtmEvents.addToCart(payload)
  })

  app.$eventBus.on('gtm:remove-from-basket', (product) => {
    const payload  = {
      instance_ref: product.id,
      name: product.name,
      qty: product.quantity,
      value: product.value,
      category: product.category,
    }
    app.$gtmEvents.removeFromCart(payload)
  })

  // app.$eventBus.on('gtm:select-delivery-method', ({step, option}) => {
  //   app.$gtm.trackEvent({
  //     event: 'checkoutOption',
  //     ecommerce: {
  //       currencyCode: 'GBP',
  //       checkout_option: {
  //         actionField: {
  //           step: step,
  //           option: option
  //         }
  //       }
  //     }
  //   })
  // })

  // app.$eventBus.on('gtm:proceed-to-delivery', (products) => {
  //   app.$gtm.trackEvent({
  //     event: 'checkout',
  //     ecommerce: {
  //       checkout: {
  //         actionField: {
  //           step: 1
  //         },
  //         products: gtmProductsTransformer(products)
  //       }
  //     }
  //   })
  // })

  // app.$eventBus.on('gtm:proceed-to-payment', (params) => {
  //   app.$gtm.trackEvent({
  //     event: 'checkout',
  //     ecommerce: {
  //       checkout: {
  //         actionField: {
  //           step: 2,
  //           option: params.deliveryMethodName
  //         },
  //         products: gtmProductsTransformer(params.products)
  //       }
  //     }
  //   })
  // })

  // app.$eventBus.on('gtm:complete-transaction', ({order, promo}) => {
  //   app.$gtm.trackEvent({
  //     ecommerce: {
  //       purchase: {
  //         actionField: {
  //           id: order.order_ref,
  //           affiliation: 'Southbank Centre',
  //           revenue: order.total,
  //           tax: 0,
  //           shipping: order.order_fees.length
  //             ? sumBy(order.order_fees, (fee) => fee.amount)
  //             : 0,
  //           coupon: promo
  //         },
  //         products: gtmProductsTransformer(order.groups)
  //       }
  //     }
  //   })
  // })

}
