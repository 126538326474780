import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex'

import { RootState } from './types'
import * as instance from '~/api/queries/instance.gql'
import { Instance } from '~/@types/skyway'

export const name = 'instance'

export const namespaced = true

export const types = {
  SET_INSTANCE: 'SET_INSTANCE',
  SET_INSTANCES: 'SET_INSTANCES',
}

export type ViewOptions = 'list' | 'grid'

export interface State {
  instance?: Instance
  instances?: [Instance]
}

/**
 * Initial state, empty array of events
 */
export const state = (): State => ({
  instance: undefined,
  instances: undefined,
})

export const getters: GetterTree<State, RootState> = {}

export const actions: ActionTree<State, RootState> = {
  async getInstance(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: instance['getInstance'],
      variables: {
        id: parseInt(id),
      },
    })

    const { data } = response

    context.commit(types.SET_INSTANCE, data.getInstanceByRef)

    return data.getInstanceByRef
  },

  async getInstanceByRef(
    context: ActionContext<State, RootState>,
    instance_ref: number
  ): Promise<any> {
    const response = await this.app.$apolloNonPersisted.query({
      query: instance['getInstanceByRef'],
      variables: {
        instance_ref,
      },
      fetchPolicy: 'network-only',
    })

    const { data } = response

    context.commit(types.SET_INSTANCE, data.getInstanceByRef)
    return data.getInstanceByRef
  },

  async getInstancesByDateRange(
    context: ActionContext<State, RootState>,
    { date_from, date_to }
  ): Promise<any> {
    const client = this.app.$apollo

    const date_from_str = new Date(
      date_from.getTime() - date_from.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split('T')[0]

    const date_to_str = new Date(
      date_to.getTime() - date_to.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split('T')[0]

    const response = await client.query({
      query: instance['getInstancesByDateRange'],
      variables: {
        range: {
          from: date_from_str,
          to: date_to_str,
        },
      },
    })

    const { data } = response

    context.commit(types.SET_INSTANCES, data.getInstancesByDateRange)

    return data.getInstancesByDateRange
  },

  async getInstancesByTag(
    context: ActionContext<State, RootState>,
    tag: string
  ): Promise<Array<Instance>> {
    const client = this.app.$apollo

    const response = await client.query({
      query: instance['getInstancesByTag'],
      variables: {
        tag: tag,
      },
    })

    const { data } = response

    return data.tag.instances
  },

  async getInstancePriceTypesByRef(
    context: ActionContext<State, RootState>,
    instance_ref: number
  ): Promise<any> {
    const response = await this.app.$apollo.query({
      query: instance['getInstancePriceTypesByRef'],
      variables: {
        instance_ref,
      },
      fetchPolicy: 'network-only',
    })

    const { data } = response

    return data.getInstanceByRef
  },

  async getInstanceAvailability(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const response = await this.app.$apolloNonPersisted.query({
      query: instance['getInstanceAvailability'],
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
    })

    const { data } = response

    return data.instance
  },
}

export const mutations: MutationTree<State> = {
  [types.SET_INSTANCE](state: State, payload: Instance): void {
    state.instance = payload
  },
  [types.SET_INSTANCES](state: State, payload: [Instance]): void {
    state.instances = payload
  },
}
