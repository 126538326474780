
import { Component, Vue } from 'nuxt-property-decorator'
import FooterLogo from '~/components/layout/footer/FooterLogo.vue'
import FooterNewsletter from '~/components/layout/footer/FooterNewsletter.vue'
import SvgFacebook from '~/static/images/sprites/fb.svg'
import SvgTwitter from '~/static/images/sprites/tw.svg'
import SvgYoutube from '~/static/images/sprites/yt.svg'
import SvgLinkedIn from '~/static/images/sprites/in.svg'

type Address = {
  line_1: string
  line_2: string
}

type Location = {
  title: string
  phone: string
  address: Address
}

type Social = {
  icon: string
  url: string
  brand: string
}

@Component({
  components: {
    FooterLogo,
    FooterNewsletter,
    SvgFacebook,
    SvgTwitter,
    SvgYoutube,
    SvgLinkedIn
  }
})
export default class Footer extends Vue {
  get locations(): Array<Location> {
    // replace these with store calls once we can connect to skyway
    return [
      {
        title: 'The Honolulu Museum of Art',
        phone: '808.532.8700',
        address: {
          line_1: '900 South Beretania Street',
          line_2: 'Honolulu, Hawaii 96814'
        }
      },
      {
        title: 'Honolulu Museum of Art School',
        phone: '808.532.8741',
        address: {
          line_1: '1111 Victoria Street',
          line_2: 'Honolulu, Hawaii 96814'
        }
      },
      /*
      {
        title: 'First Hawaiian Center',
        phone: '808.532.8700',
        address: {
          line_1: '999 Bishop Street',
          line_2: 'Honolulu, Hawaii 96813'
        }
      }
      */
    ]
  }

  get contact(): object {
    return {
      email: 'info@honolulumuseum.org',
      phone: '808.532.8700'
    }
  }

  get socials(): Array<Social> {
    return [
      {
        icon: 'SvgFacebook',
        url: 'https://facebook.com/honolulumuseum/',
        brand: 'facebook'
      },
      {
        icon: 'SvgLinkedIn',
        url: 'https://www.instagram.com/honolulumuseum/',
        brand: 'instagram'
      },
      {
        icon: 'SvgTwitter',
        url: 'https://twitter.com/honolulumuseum',
        brand: 'twitter'
      },
      {
        icon: 'SvgYoutube',
        url: 'https://www.youtube.com/channel/UCk6S25379SPuvfKwwdVNiNA',
        brand: 'youtube'
      }
    ]
  }
}
