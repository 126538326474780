
import { Component, Vue } from 'vue-property-decorator'
import SvgLanguage from '~/static/images/sprites/language.svg'

@Component({
  components: {
    SvgLanguage,
  },
})
export default class LanguageSwitcher extends Vue {
  /**
   * Data
   */
  protected currentLocaleCode: string = ''

  /**
   * Computed
   */
  get locales(): Array<string> {
    return this.$i18n.locales
  }

  /**
   * Methods
   */
  protected changeLanguage(localeCode): void {
    this.currentLocaleCode = localeCode
    this.$i18n.setLocale(localeCode)
    this.$router.go(0)
  }

  created(): void {
    this.currentLocaleCode = this.$i18n.locale
  }
}
