
import { Component, Vue } from 'vue-property-decorator'
import Svgcart from '~/static/images/sprites/cart.svg'

@Component({
  components: {
    Svgcart
  }
})
export default class MetaNavItemBasket extends Vue {
  public loaded: boolean = false

  get count() {
    if (this.loaded) {
      let total = 0
      this.groups.forEach((group) => {
        group.items.forEach((item) => {
          total = total + item.children.length
        })
      })
      return total
    } else {
      return 0
    }
  }

  get groups() {
    return this.$store.state.basket.basket
      ? this.$store.state.basket.basket.groups
      : []
  }

  getBasket() {
    this.$store.dispatch('basket/getBasket').then(() => {
      this.loaded = true
    })
  }

  created() {
    this.getBasket()
  }
}
