
import { Component, Vue } from 'nuxt-property-decorator'
import { Event } from '~/@types/skyway'
import * as instance from '~/api/queries/instance.gql'

import Loader from '~/components/ui/Loader.vue'
import SvgChevronRight from '~/static/images/sprites/chevron-right.svg'

@Component({
  components: {
    Loader,
    SvgChevronRight,
  },
})
export default class DrawerContentOnToday extends Vue {
  public instances: Array<Event> = []
  public loading: boolean = false

  /**
   * We are triggering the query here and storing locally
   * so it only happens when the drawer is opened
   * and doesn't overwrite event data in our store
   *
   * @return void
   */
  async getEventsToday() {
    try {
      const client = this.$apollo
      const response = await client.query({
        query: instance.GetIntancesByDateRange,
        variables: {
          from_date: this.today.format('YYYY-MM-DD'),
          to_date: this.today.clone().add(1, 'day').format('YYYY-MM-DD'),
        },
      })

      const { data } = response

      if (data && data.getInstancesByDateRange) {
        this.instances = data.getInstancesByDateRange
      }
    } catch (err_) {
      const { data } = err_

      if (data && data.getInstancesByDateRange) {
        this.instances = data.getInstancesByDateRange
      }
    }
  }

  onClickViewAll() {
    this.$eventBus.emit('meta-nav-item:trigger-close')

    this.$router.push(this.$config.get('URLS').whats_on)
  }

  /**
   * Format the time of each instance for display
   *
   * @param string - date_time from the instance
   *
   * @return string - formatted time
   */
  formatTime(date: string) {
    const dateObj = this.$moment(date)
    return this.$d(dateObj.format('H:mm'), 'on_today_drawer')
  }

  /**
   * Get today date object
   *
   * @return this.$moment.Moment
   */
  get today(): any {
    return this.$moment()
  }

  /**
   * Get string date formatted
   *
   * @return string
   */
  get todayFormatted(): string {
    return this.today.format('llll')
  }

  /**
   * Trigger actions when the component is first created
   * Because the component is conditionally rendered in a v-if
   * block, then this won't trigger unless the user clicks to open the panel
   *
   * @return void
   */
  created(): void {
    this.loading = true
    this.getEventsToday()
      .then(() => (this.loading = false))
      .catch(() => (this.loading = false))
  }
}
