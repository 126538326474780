import { ActionTree, MutationTree, GetterTree, ActionContext } from 'vuex'
import { RootState } from './types'

export const name = 'giftaid'

export interface GiftAidRequest {
  eligible?: boolean
  type_ref?: number
}

export interface State {
  requests?: [GiftAidRequest]
}

export const state = (): State => ({
  requests: undefined
})

export const types = {
  SET_REQUEST: 'SET_REQUEST',
  CLEAR_REQUESTS: 'CLEAR_REQUESTS'
}

export const getters: GetterTree<State, RootState> = {
  /**
   * Format the parameters for adding a ticket to the basket
   * If a zone has already been selected by the user, we pass that.
   * Otherwise we use the zone from the selected price type
   */
  getRequests: (state: State): Array<GiftAidRequest> | null => {
    if (state.requests) {
      return state.requests
    }

    return null
  }
}

export const actions: ActionTree<State, RootState> = {
  async setRequest(
    context: ActionContext<State, RootState>,
    payload: any
  ): Promise<any> {
    return new Promise((res, rej) => {

      // set up a listener so that gift aid requests will be processed on login
      // only on first request added to pile
      if (this.getters['giftaid/getRequests'] == null) {
        this.app.$eventBus.on('login', () => {
          this.dispatch('giftaid/processRequests')
        })
      }

      context.commit(types.SET_REQUEST, payload)
      res()
    })
  },

  async processRequests(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    return new Promise((res, rej) => {
      const requests = this.getters['giftaid/getRequests']
      if (requests != null) {
        this.dispatch('customer/updateGiftAid', requests)
        context.commit(types.CLEAR_REQUESTS)
      }
      res()
    })
  }
}

export const mutations: MutationTree<State> = {
  [types.SET_REQUEST](state: State, payload: GiftAidRequest) {
    if (state.requests != undefined) {
      const current = state.requests.findIndex(r => r.type_ref == payload.type_ref)
      if (current !== -1) {
        state.requests[current] = payload
      } else {
        state.requests.push(payload)
      }
    } else {
      state.requests = [payload]
    }
  },
  [types.CLEAR_REQUESTS](state: State) {
    state.requests = undefined;
  }
}
