const middleware = {}

middleware['auth'] = require('../src/middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['basket'] = require('../src/middleware/basket.ts')
middleware['basket'] = middleware['basket'].default || middleware['basket']

middleware['customer'] = require('../src/middleware/customer.ts')
middleware['customer'] = middleware['customer'].default || middleware['customer']

middleware['guest'] = require('../src/middleware/guest.ts')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['has-basket'] = require('../src/middleware/has-basket.ts')
middleware['has-basket'] = middleware['has-basket'].default || middleware['has-basket']

middleware['maintenance'] = require('../src/middleware/maintenance.ts')
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

middleware['malicious'] = require('../src/middleware/malicious.ts')
middleware['malicious'] = middleware['malicious'].default || middleware['malicious']

middleware['query'] = require('../src/middleware/query.ts')
middleware['query'] = middleware['query'].default || middleware['query']

export default middleware
