import { ActionTree, ActionContext } from 'vuex'
import { RootState } from '../types'
import * as customer from '~/api/queries/customer.gql'
import * as email from '~/api/queries/emails.gql'
import { State, types } from './'
import {
  Order,
  Customer,
  CreateCustomerInput,
  CustomerPhoneInput,
  CustomerAddressInput,
  TokenSet,
  UpdatePasswordInput,
  GiftAidDeclarationInput,
  CustomerConstituencyInput,
  CustomerAttributeInput,
  CustomerContactPreferenceInput,
  CustomerInterestInput,
  CustomerInput,
  AffiliationInput,
  AssociationInput,
  AccountInput,
  NewsletterInput,
} from '~/@types/skyway'
import { decodeToken } from '~/modules/helpers/decodeToken'

const actions: ActionTree<State, RootState> = {
  async init(context) {
    if (this.app.$cookies) {
      const knownUsers = this.app.$cookies.get('known_users') || []
      context.commit(types.SET_KNOWN_USERS, knownUsers)
    }
  },

  async checkEmailExists(
    context: ActionContext<State, RootState>,
    email: string
  ): Promise<number> {
    const data = await this.$auth.checkEmailExists(email)

    return data.checkEmailExists
  },

  /**
   * Login and fetch the active customer details if successful
   */
  async login(
    context: ActionContext<State, RootState>,
    { username, password }
  ): Promise<any> {
    const data = await this.$auth.login({ username, password })

    return data.login
  },

  async externalLogin(
    context: ActionContext<State, RootState>,
    email
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['externalLogin'],
      variables: {
        email,
      },
      context: {
        credentials: 'include',
      },
    })

    const { data } = response

    return data.externalLogin
  },

  async tokenLogin(
    context: ActionContext<State, RootState>,
    { email, token }
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['tokenLogin'],
      variables: {
        email,
        token,
      },
      context: {
        credentials: 'include',
      },
    })

    const { data } = response

    return data.tokenLogin
  },

  /**
   * Social Login
   */
  async socialLogin(
    context: ActionContext<State, RootState>,
    input
  ): Promise<any> {
    const data = await this.$auth.socialLogin(input)

    return data.socialLogin
  },

  /**
   * Remove the refresh cookies and customer details from store
   * Will need to hit an endpoint to clear the cookies
   */
  async logout(context: ActionContext<State, RootState>) {
    const data = await this.$auth.logout()

    if (data) {
      this.dispatch('customer/clearSession')
    }
  },

  /**
   * If we identify that the session is erroneous but we don't want to kill the backend session
   * we can reset the client session using this method
   * @param context
   */
  async clearSession(context: ActionContext<State, RootState>) {
    this.app.$eventBus.emit('clear_cookie')

    return new Promise((resolve, reject) => {
      context.commit(types.SET_LOGGED_IN, false)
      context.commit(types.SET_TOKEN, undefined)
      context.commit(types.LOGOUT)
      console.log('SESSION CLEARED')
      resolve()
    })
  },

  /**
   * Request the details for the logged in user.
   *
   * If we can't get the active customer, we need to bail out in the catch block
   * and fully logout
   */
  async getActiveCustomer(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (this.state.customer.logged_in) {
      const data = await this.$account.getActiveCustomer(fetchPolicy)

      if (
        data.getActiveCustomer.display_name &&
        data.getActiveCustomer.display_name === 'Anonymous User'
      ) {
        context.dispatch('logout')
        return false
      } else {
        context.commit(types.SET_CUSTOMER, data.getActiveCustomer)

        return data.getActiveCustomer
      }
    } else {
      return false
    }
  },

  async getCustomerContactDetails(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (this.state.customer.logged_in) {
      const data = await this.$account.getActiveCustomer(
        fetchPolicy,
        'getCustomerContactDetails'
      )

      context.commit(types.SET_CUSTOMER_CONTACT_DETAILS, data.getActiveCustomer)

      return data.getActiveCustomer
    } else {
      return null
    }
  },

  async getCustomerInterests(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (this.state.customer.logged_in) {
      const client = this.app.$apollo

      const response = await client.query({
        query: customer['getCustomerInterests'],
        fetchPolicy,
      })

      const { data } = response

      context.commit(types.SET_CUSTOMER_INTERESTS, data.getActiveCustomer)

      return data.getActiveCustomer.interests
    } else {
      return null
    }
  },

  async getCustomerAttributes(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (this.state.customer.logged_in) {
      const client = this.app.$apollo

      const response = await client.query({
        query: customer['getCustomerAttributes'],
        fetchPolicy,
      })

      const { data } = response

      context.commit(types.SET_CUSTOMER_ATTRIBUTES, data.getActiveCustomer)

      return data.getActiveCustomer.attributes
    } else {
      return null
    }
  },

  async getCustomerConstituencies(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (this.state.customer.logged_in) {
      const client = this.app.$apollo

      const response = await client.query({
        query: customer['getCustomerConstituencies'],
        fetchPolicy,
      })

      const { data } = response

      context.commit(types.SET_CUSTOMER_CONSTITUENCIES, data.getActiveCustomer)

      return data.getActiveCustomer.constituencies
    } else {
      return null
    }
  },

  async getCustomerBalances(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (this.state.customer.logged_in) {
      const client = this.app.$apollo

      const response = await client.query({
        query: customer['getCustomerBalances'],
        fetchPolicy,
      })

      const { data } = response

      context.commit(types.SET_CUSTOMER_BALANCES, data.getActiveCustomer)

      return data.getActiveCustomer.balances
    }
  },

  async getCustomerMemberships(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (this.state.customer.logged_in) {
      const client = this.app.$apollo

      const response = await client.query({
        query: customer['getCustomerMemberships'],
        fetchPolicy,
      })

      const { data } = response

      context.commit(types.SET_CUSTOMER_MEMBERSHIPS, data.getActiveCustomer)

      return data.getActiveCustomer.memberships
    } else {
      return null
    }
  },

  async getCustomerGiftAid(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (this.state.customer.logged_in) {
      const client = this.app.$apollo

      const response = await client.query({
        query: customer['getCustomerGiftAid'],
        fetchPolicy,
      })

      const { data } = response

      context.commit(types.SET_CUSTOMER_GIFT_AID, data.getActiveCustomer)

      return data.getActiveCustomer.giftaid
    } else {
      return null
    }
  },

  async getCustomerContactPreferences(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (this.state.customer.logged_in) {
      const client = this.app.$apollo

      const response = await client.query({
        query: customer['getCustomerContactPreferences'],
        fetchPolicy,
      })

      const { data } = response

      context.commit(
        types.SET_CUSTOMER_CONTACT_PREFERENCES,
        data.getActiveCustomer
      )

      return data.getActiveCustomer.preferences
    } else {
      return null
    }
  },

  async getCustomerTickets(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (this.state.customer.logged_in) {
      const client = this.app.$apollo

      const response = await client.query({
        query: customer['getCustomerTickets'],
        fetchPolicy,
      })

      const { data } = response

      context.commit(types.SET_TICKET_HISTORY, data.getActiveCustomer)

      return data.getActiveCustomer.tickets
    } else {
      return null
    }
  },

  async getCustomerOrderHistory(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<Order[]> {
    const client = this.app.$apollo

    const response = await client.query({
      query: customer['getCustomerOrderHistory'],
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_CONTRIBUTION_HISTORY, data.getActiveCustomer)
    context.commit(types.SET_TICKET_HISTORY, data.getActiveCustomer)
    context.commit(types.SET_ORDER_HISTORY, data.getActiveCustomer.orders)

    return data.orderHistory
  },

  async getOrderHistory(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<Order[]> {
    const client = this.app.$apollo

    const response = await client.query({
      query: customer['orderHistory'],
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_ORDER_HISTORY, data.orderHistory)

    return data.orderHistory
  },

  async getOrder(
    context: ActionContext<State, RootState>,
    orderId: string
  ): Promise<Order> {
    const client = this.app.$apollo

    const response = await client.query({
      query: customer['order'],
      variables: {
        id: parseInt(orderId),
      },
    })

    const { data } = response
    return data.order
  },

  async getCustomerMemberships(
    context: ActionContext<State, RootState>,
    currentOnly: boolean = false
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.query({
      query: customer['getCustomerMemberships'],
      variables: {
        current: currentOnly,
        includeAffiliates: false,
      },
      fetchPolicy: 'network-only',
    })

    const { data } = response

    context.commit(types.SET_MEMBERSHIP_HISTORY, data.getCustomerMemberships)

    return data
  },

  /**
   * Update the active customer's details
   * @todo change input: to UpdateCustomerInput
   */
  async updateCustomer(
    context: ActionContext<State, RootState>,
    input: any
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: customer['updateCustomer'],
      variables: {
        customerInput: input,
      },
    })

    const { data } = response

    this.app.$eventBus.notifySuccess(
      'Your details have been updated successfully'
    )
    context.dispatch('getActiveCustomer')

    return data
  },

  /**
   * Update a houseold member of the active customer
   * @todo change input: to UpdateCustomerInput
   */
  async updateHouseholdMember(
    context: ActionContext<State, RootState>,
    input: any
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: customer['updateHouseholdMember'],
      variables: {
        customerInput: input,
      },
    })

    const { data } = response

    this.app.$eventBus.notifySuccess(
      'Your household details have been updated successfully'
    )

    return data
  },

  async updatePhones(
    context: ActionContext<State, RootState>,
    input: Array<CustomerPhoneInput>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['updatePhones'],
      variables: {
        customerPhonesInput: input,
      },
    })

    const { data } = response

    return data.updatePhones
  },

  async updateAddresses(
    context: ActionContext<State, RootState>,
    input: Array<CustomerAddressInput>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['updateAddresses'],
      variables: {
        customerAddressesInput: input,
      },
    })

    const { data } = response

    return data.updateAddresses
  },

  async updateAddress(
    context: ActionContext<State, RootState>,
    input: CustomerAddressInput
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['updateAddress'],
      variables: {
        customerAddressInput: input,
      },
    })

    const { data } = response

    return data.updateAddress
  },

  async deleteAddress(
    context: ActionContext<State, RootState>,
    address_ref: number
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['deleteAddress'],
      variables: {
        address_ref,
      },
    })

    const { data } = response

    return data.deleteAddress
  },

  async updatePassword(
    context: ActionContext<State, RootState>,
    input: UpdatePasswordInput
  ): Promise<boolean> {
    const response = await this.app.$apollo.mutate({
      mutation: customer['updatePassword'],
      variables: {
        updatePasswordInput: input,
      },
    })

    const { data } = response

    return data.updatePassword
  },

  async updateGiftAid(
    context: ActionContext<State, RootState>,
    input: [GiftAidDeclarationInput]
  ) {
    const response = this.app.$apollo.mutate({
      mutation: customer['updateGiftAidDeclarations'],
      variables: {
        giftAidDeclarations: input,
      },
    })
  },

  async processLogin(
    context: ActionContext<State, RootState>,
    input: TokenSet
  ): Promise<any> {
    // delete all values from apollo cache to make sure all data is fetched fresh from server
    Object.keys(this.app.$apolloNonPersisted.cache.data.data).forEach((key) =>
      this.app.$apolloNonPersisted.cache.data.delete(key)
    )

    context.commit(types.SET_TOKEN, input.token)
    context.commit(types.SET_LOGGED_IN, true)

    const customer = await context.dispatch('getActiveCustomer', 'network-only')
    await this.dispatch('basket/getBasketProperties', {
      fetchPolicy: 'network-only',
    })

    if (customer) {
      this.app.$eventBus.emit('login')
      this.app.$eventBus.emit('update_cookie', {
        is_logged_in: true,
      })

      return customer
    } else {
      context.commit(types.SET_LOGGED_IN, false)
      context.commit(types.SET_TOKEN, undefined)
      return false
    }
  },

  async sendResetToken(
    context: ActionContext<State, RootState>,
    email
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: customer['sendResetToken'],
      variables: {
        email,
      },
    })

    const { data } = response

    return data.sendResetToken
  },

  /**
   * Create a new customer (register)
   */
  async createCustomer(
    context: ActionContext<State, RootState>,
    params: { input: CreateCustomerInput; recaptcha: string }
  ): Promise<any> {
    const data = await this.app.$account.createCustomer(
      params.input,
      params.recaptcha
    )

    return data.createCustomer
  },

  async newsletterSignup(
    context: ActionContext<State, RootState>,
    params: { input: NewsletterInput; recaptcha: string }
  ): Promise<Customer> {
    const data = await this.app.$account.newsletterSignup(
      params.input,
      params.recaptcha
    )

    return data.newsletterSignup
  },

  async createHouseholdMember(
    context: ActionContext<State, RootState>,
    input: CustomerInput
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['createHouseholdMember'],
      variables: {
        customerInput: input,
      },
    })

    const { data } = response

    return data.createHouseholdMember
  },

  async updateAffiliation(
    context: ActionContext<State, RootState>,
    input: AffiliationInput
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['updateAffiliations'],
      variables: {
        updateAffiliationInput: input,
      },
    })

    const { data } = response

    return data.updateAffiliations
  },

  async updateAssociation(
    context: ActionContext<State, RootState>,
    input: AssociationInput
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['updateAssociation'],
      variables: {
        updateAssociationInput: input,
      },
    })

    const { data } = response

    return data.updateAssociation
  },

  async transferSession(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<void> {
    const client = this.app.$apollo

    const response = await client.query({
      query: customer['transferSession'],
      fetchPolicy,
      context: {
        credentials: 'include',
      },
    })

    const { data } = response

    context.commit(types.SET_TOKEN, data.transferSession.token)
  },

  async updateContactPreferences(
    context: ActionContext<State, RootState>,
    input: Array<CustomerContactPreferenceInput>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['updateContactPreferences'],
      variables: {
        customerContactPreferencesInput: input,
      },
    })

    const { data } = response

    return data.updateContactPreferences
  },

  async updateInterests(
    context: ActionContext<State, RootState>,
    input: Array<CustomerInterestInput>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['updateInterests'],
      variables: {
        customerInterestsInput: input,
      },
    })

    const { data } = response

    return data.updateInterests
  },

  async updateConstituencies(
    context: ActionContext<State, RootState>,
    input: Array<CustomerConstituencyInput>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['updateConstituencies'],
      variables: {
        customerConstituenciesInput: input,
      },
    })

    const { data } = response

    return data.updateActiveCustomerConstituencies
  },

  async deleteConstituency(
    context: ActionContext<State, RootState>,
    constituency_ref
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['deleteConstituency'],
      variables: {
        constituency_ref,
      },
    })

    const { data } = response

    return data.deleteConstituency
  },

  async updateAttributes(
    context: ActionContext<State, RootState>,
    input: Array<CustomerAttributeInput>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['updateAttributes'],
      variables: {
        customerAttributesInput: input,
      },
    })

    const { data } = response

    return data.updateActiveCustomerAttributes
  },

  async deleteAttribute(
    context: ActionContext<State, RootState>,
    attribute_ref
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['deleteAttribute'],
      variables: {
        attribute_ref,
      },
    })

    const { data } = response

    return data.deleteAttribute
  },

  async getGiftCertificateBalance(
    context: ActionContext<State, RootState>,
    code: string
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['getGiftCertificateBalance'],
      variables: {
        code,
      },
    })

    const { data } = response

    return data.getGiftCertificateBalance
  },

  async addDirectDebitAccount(
    context: ActionContext<State, RootState>,
    account: AccountInput
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['addDirectDebitAccount'],
      variables: {
        account,
      },
    })

    const { data } = response

    return data.addDirectDebitAccount
  },

  async getPrimaryHouseholdMembers(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: customer['getPrimaryHouseholdMembers'],
    })

    const { data } = response

    return data.getPrimaryHouseholdMembers
  },

  async getCountries(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const data = await this.app.$account.getCountries()

    return (
      (data.getCountries &&
        data.getCountries.filter((country) => country.name !== '')) ||
      []
    )
  },

  async getStates(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const data = await this.app.$account.getStates()

    return (data && data.getStates) || []
  },

  async getCustomerDetailsHtml(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.query({
      query: email['getCustomerDetails'],
    })

    const { data } = response

    return data.getCustomerDetails.body ? data.getCustomerDetails.body : null
  },

  async getOrderConfirmationHtml(
    context: ActionContext<State, RootState>,
    order_ref: number
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.query({
      query: email['getOrderConfirmation'],
      variables: {
        order_ref,
      },
    })

    const { data } = response

    return data.getOrderConfirmation.body
      ? data.getOrderConfirmation.body
      : null
  },

  async getTicketsHtml(
    context: ActionContext<State, RootState>,
    order_ref: number
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.query({
      query: email['getTickets'],
      variables: {
        order_ref,
      },
    })

    const { data } = response

    return data.getTickets.body ? data.getTickets.body : null
  },

  async printTicketsHtml(
    context: ActionContext<State, RootState>,
    order_ref: number
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.query({
      query: email['printTickets'],
      variables: {
        order_ref,
      },
    })

    const { data } = response
    return data.printTickets.body ? data.printTickets.body : null
  },

  async getGiftCertificates(
    context: ActionContext<State, RootState>,
    customer_ref: number
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: customer['getGiftCertificates'],
      variables: {
        customer_ref: `${customer_ref}`, // must be passed in as string
      },
    })

    const { data } = response

    return data.execute && data.execute.response ? data.execute.response : null
  },

  async flagMaliciousActivity(
    context: ActionContext<State, RootState>
  ): Promise<boolean> {
    return new Promise((resolve) => {
      context.commit(types.SET_MALICIOUS_ACTIVITY_FLAG, true)
      resolve(true)
    })
  },
}

export default actions
