import {
  AccountService,
  AuthService,
  BasketService,
  EventsService,
  InstancesService,
  SeatsService,
  DonationsService,
  MembershipsService,
} from 'Services'

const createService = (Name, client1, client2 = null) => {
  const service = new Name(client1, client2)

  return service
}

export default ({ app }, inject) => {
  const accountService = createService(AccountService, app.$apolloNonPersisted)
  const authService = createService(AuthService, app.$apolloNonPersisted)
  const basketService = createService(BasketService, app.$apolloNonPersisted)
  const seatsService = createService(SeatsService, app.$apolloNonPersisted)
  const donationsService = createService(DonationsService, app.$apollo)
  const eventsService = createService(
    EventsService,
    app.$apollo,
    app.$apolloNonPersisted
  )
  const instancesService = createService(
    InstancesService,
    app.$apollo,
    app.$apolloNonPersisted
  )
  const membershipsService = createService(MembershipsService, app.$apollo)

  inject('account', accountService)
  inject('auth', authService)
  inject('basket', basketService)
  inject('donations', donationsService)
  inject('instances', instancesService)
  inject('events', eventsService)
  inject('memberships', membershipsService)
  inject('seats', seatsService)
}
