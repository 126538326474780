
import { Component, Vue, Watch, namespace } from 'nuxt-property-decorator'
import MainNav from '~/components/menu/MainNav.vue'
import MetaNav from '~/components/menu/MetaNav.vue'
import MobileNav from '~/components/menu/MobileNav.vue'
import AnnouncementBanner from '~/components/ui/AnnouncementBanner.vue'

const options = namespace('options')
const post = namespace('post')

@Component({
  components: {
    MetaNav,
    MainNav,
    MobileNav,
    AnnouncementBanner,
  },
  data() {
    return {
      classes: 'header',
    }
  },
})
export default class Header extends Vue {
  public classes: string = 'header'
  public announcementHeight: number = 76

  @options.State
  showAnnouncement

  @post.State
  fixed

  get announcement() {
    return this.fixed && this.fixed.announcement
      ? this.fixed.announcement
      : false
  }

  get announcementEnabled(): boolean {
    return this.announcement && this.announcement.data
      ? this.announcement.data.enabled && this.showAnnouncement
      : false
  }

  @Watch('showAnnouncement')
  onPropertyChanged(newValue, oldValue) {
    setTimeout(() => {
      this.$emit('header:height-changed', this.$refs.header.clientHeight)
    }, 600)
  }

  onAfterAppear() {
    this.classes = 'header is-loaded'
    Vue.nextTick(() => {
      this.$emit('header:height-changed', this.$refs.header.clientHeight)
    })
  }

  onAnnouncementHeightChange(height) {
    this.announcementHeight = height
    Vue.nextTick(() => {
      this.$emit('header:height-changed', this.$refs.header.clientHeight)
    })
  }

  mounted() {
    const header = this.$refs.header
    Vue.nextTick(() => {
      this.$emit('header:height-changed', header && header.clientHeight)
    })

    // sometimes header isnt fully rendered when hit is set
    setTimeout(() => {
      this.$emit('header:height-changed', header && header.clientHeight)
    }, 1000)
  }
}
