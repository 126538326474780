
import { Component, Prop, Vue, namespace } from 'nuxt-property-decorator'
import { sumBy } from 'lodash'
import { Instance, Event } from '~/@types/skyway'
import { validateModeOfSale } from '~/modules/helpers/validateModeOfSale'

const basket = namespace('basket')

@Component
export default class BookNowButton extends Vue {
  @Prop({ type: Object }) instance?: Instance
  @Prop({ type: Object }) event?: Event
  @Prop({ type: String, default: null }) btnText?: string
  @Prop({ type: String, default: null }) bookingLink?: string
  @Prop({ type: String, default: 'btn btn--primary' }) classes?: string
  @Prop({ type: Boolean, default: false }) useInstanceDate!: boolean

  public off_sale: boolean = false
  public has_valid_mos: boolean = false

  @basket.State
  properties

  protected special_types = ['adult-class', 'youth-class', 'field-trip']

  get localEvent(): Event | null | undefined {
    return this.event ? this.event : this.instance ? this.instance.event : null
  }

  get localInstances(): Maybe<Instance[]> | null {
    if (this.instance) {
      return [this.instance]
    } else {
      return this.event && this.event.instances
        ? this.event.instances
        : this.instance
        ? [this.instance]
        : null
    }
  }

  get link(): string | null {
    if (this.bookingLink && this.bookingLink !== '') {
      return this.bookingLink
    } else if (
      this.localEvent &&
      this.localEvent.on_sale &&
      this.localEvent.external_booking
    ) {
      return this.localEvent.external_booking
    } else if (this.localEvent && this.localEvent.on_sale) {
      return `/${this.cardType}/${this.cardId}/`
    } else {
      return null
    }
  }

  get cardType() {
    if (this.instance) {
      if (
        this.localEvent &&
        this.localEvent.type &&
        this.localEvent.type === 'field-trip'
      ) {
        return 'purchase/schools'
      } else {
        return 'purchase/tickets'
      }
    } else if (
      this.localEvent &&
      this.localEvent.type &&
      this.special_types.includes(this.localEvent.type)
    ) {
      if (
        this.localEvent.type.substring(this.localEvent.type.length - 1) === 's'
      ) {
        return `${this.localEvent.type}es`
      } else {
        return `${this.localEvent.type}s`
      }
    } else {
      return 'events'
    }
  }

  get btnLabel() {
    const limitedAvailability =
      this.localEvent && this.localEvent.limited_availability
        ? this.localEvent.limited_availability
        : 10

    let defaultLabel = this.instance ? 'Book Now' : 'More details'
    switch (this.localEvent.type) {
      case 'field-trip':
      case 'adult-class':
      case 'youth-class':
        defaultLabel = this.instance ? 'Register Now' : 'More details'
        break
    }

    // has specific custom button text passed in as Prop
    if (this.btnText) {
      return this.btnText

      // has defined button text in CMS
    } else if (
      this.localEvent &&
      this.localEvent.extra &&
      this.localEvent.extra.book_now_text &&
      this.localEvent.extra.book_now_text !== '' &&
      this.localInstances &&
      this.localInstances.length > 1
    ) {
      return this.$t(this.localEvent.extra.book_now_text)

      // Past event
    } else if (this.eventHasPassed) {
      return this.$t('More info')

      // has valid MOS and either enough availability of no availability specified
    } else if (
      this.has_valid_mos &&
      (this.availability > limitedAvailability ||
        this.availability === undefined)
    ) {
      return this.$t(defaultLabel)

      // has valid MOS and less availability that the limited availability figure
    } else if (
      this.has_valid_mos &&
      this.availability > 0 &&
      this.availability <= limitedAvailability
    ) {
      // return this.$t('Limited availability')
      return this.$t(defaultLabel)

      // has valid mode of sale, but no availability
    } else if (this.has_valid_mos && this.availability === 0) {
      return this.$t('Sold out')

      // not on sale
    } else {
      return this.$t('Coming soon')
    }
  }

  get cardId(): string | null {
    return this.instance
      ? this.instance.instance_ref
      : this.localEvent
      ? this.localEvent.event_ref
      : null
  }

  get eventHasPassed(): boolean {
    let lastDate
    if (this.useInstanceDate && this.instance) {
      lastDate = this.instance.date_time
    } else if (this.localEvent) {
      lastDate = this.localEvent.last_date
    }
    if (this.useInstanceDate || this.localEvent) {
      const now = this.$moment()

      console.log({ now: now, last: this.$moment(lastDate) })

      return !!this.$moment(lastDate).isBefore(now)
    } else {
      return true
    }
  }

  get availability(): number {
    return sumBy(this.localInstances, (i) => i.total_availability)
  }

  get notOnSale(): boolean {
    if (this.eventHasPassed) {
      return false
    } else if (this.has_valid_mos && this.availability === 0) {
      return true
    } else if (!this.has_valid_mos) {
      return true
    } else {
      return false
    }
  }

  // only clickable if it's a link through to the event page, or is on sale
  get isClickable() {
    return !this.notOnSale || !this.instance
  }

  async checkForValidModeOfSale(): Promise<void> {
    let has_mos_data = false
    this.localInstances.forEach((i) => {
      if (i.modes_of_sale !== undefined) has_mos_data = true
    })

    if (has_mos_data) {
      if (!this.properties) {
        await this.$store.dispatch('basket/getBasketProperties')
      }

      if (this.properties) {
        for (const k in this.localInstances) {
          const instance = this.localInstances[k]
          const valid = validateModeOfSale(
            instance,
            this.properties.mode_of_sale_ref,
            this.$moment
          )

          // if single valid mode of sale is found then we're good to go
          if (valid) {
            this.has_valid_mos = valid
            return
          }
        }
      }
    } else {
      // fake having a valid MOS if we havent retrieved the MOS data at this stage
      this.has_valid_mos = true
    }
  }

  onClick() {
    if (this.isClickable) {
      if (this.instance) {
        this.$store.commit(`selection/SET_INSTANCE`, this.instance)
      }
      this.$router.push(this.link)
    }
  }

  mounted() {
    this.checkForValidModeOfSale()
  }
}
