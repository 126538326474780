import BaseService from '~/services/base'
import * as instances from '~/api/queries/instance.gql'

export default class InstancesService extends BaseService {
  async getInstances(): Promise<any> {
    const response = await this.client.query({
      query: instances.getInstances,
    })
    return this.respond(response)
  }

  async getInstanceByRef(instance_ref: string): Promise<any> {
    const client = this.nonPersistedClient || this.client
    const response = await client.query({
      query: instances.getInstanceByRef,
      variables: {
        instance_ref,
      },
      fetchPolicy: 'network-only',
    })

    return this.respond(response)
  }

  async getInstanceAvailability(id: number): Promise<any> {
    const response = await this.client.query({
      query: instances.getInstanceAvailability,
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
    })

    return this.respond(response)
  }

  async getInstancesByDateRange(
    range: { from: Date; to: Date },
    brief: boolean,
    type: string,
    fetchPolicy
  ): Promise<any> {
    const response = await this.client.query({
      query: instances.getInstancesByDateRange,
      variables: {
        range,
        brief,
        type,
      },
      fetchPolicy,
    })

    return this.respond(response)
  }

  async getInstancePriceTypesByRef(instance_ref: number): Promise<any> {
    const client = this.nonPersistedClient || this.client
    const response = await client.query({
      query: instances.getInstancePriceTypesByRef,
      variables: {
        instance_ref,
      },
      fetchPolicy: 'network-only',
    })

    return this.respond(response)
  }
}
