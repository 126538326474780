
import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class AccountLogout extends Vue {
  onClickLogout() {
    // dispatch store action to remove tokens and cookies
    this.$store.dispatch('customer/logout').then(() => {
      localStorage.removeItem('redirect')
      this.$router.push('/sign-in/')
    })
  }
}
