import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex'

import { RootState } from './types'
import * as funds from '~/api/queries/funds.gql'
import { Fund } from './types'

export const name = 'funds'

export const namespaced = true

export const types = {
  SET_CONTRIBUTIONS: 'SET_CONTRIBUTIONS'
}

export interface State {
  funds?: Array<Fund>
}

/**
 * Initial state, empty array of events
 */
export const state = (): State => ({
  funds: []
})

export const getters: GetterTree<State, RootState> = {}

export const actions: ActionTree<State, RootState> = {
  async getFunds(context: ActionContext<State, RootState>): Promise<any> {
    const client = this.app.$apollo;
    const response = await client.query({
      query: funds['getFunds']
    })

    const { data } = response

    context.commit(types.SET_CONTRIBUTIONS, data.getFunds)
    return data.getFunds
  },

  async addContribution(
    context: ActionContext<State, RootState>,
    input
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: funds['addContribution'],
      variables: {
        fund_ref: parseInt(input.fund_ref),
        amount: input.amount
      }
    })

    const { data } = response

    return data.addContribution
  },

  async removeContribution(
    context: ActionContext<State, RootState>,
    id
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: funds['removeContribution'],
      variables: {
        id
      }
    })

    const { data } = response

    return data.removeContribution
  },
}

export const mutations: MutationTree<State> = {
  [types.SET_CONTRIBUTIONS](state: State, payload: Array<Fund>): void {
    state.funds = payload
  }
}
