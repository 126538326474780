import axios from 'axios'
import fileDownload from 'js-file-download'

export const createPDF: Promise<any> = async ($vm: any, html: string, title: string = '', htmlHeader: string = '', htmlFooter: string = '') => {
  if (htmlHeader == '') {
    htmlHeader = `<h1 style="font-size: 14px; padding-left: 70px">Honolulu Museum of Art${title != '' ? ` - ${title}` : '' }</h1>`
  }

  const htmlDoc = `
    <!doctype html>
    <html lang="en">
      <head>
        <meta charset="utf-8">
        <title>Honolulu Museum of Art${title != '' ? ` - ${title}` : '' }</title>
        <style>
        font-family: Arial, Helvetica, sans-serif;
        </style>
      </head>
      <body>
        ${html}
      </body>
    </html>`

  const formData = new FormData();
  const body = new Blob([htmlDoc], {type: 'text/plain'});
  formData.append("body", body, "index.html");

  const header = new Blob([`<!doctype html><html lang="en"><body>${htmlHeader}</body></html>`], {type: 'text/plain'});
  formData.append("header", header, "header.html");

  if (htmlFooter != '') {
    const footer = new Blob([`<!doctype html><html lang="en"><body>${htmlFooter}</body></html>`], {type: 'text/plain'});
    formData.append("footer", footer, "footer.html");
  }

  const headers = {
    "Content-Type": "multipart/form-data",
  };

  return axios({
      method: 'post',
      url: `${$vm.$config.get('PDF_ENDPOINT')}`, 
      data: formData,
      headers: headers,
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, 'homa-download.pdf', 'application/pdf')
    })
    .catch(err => {
      console.log(err)
    });
}
