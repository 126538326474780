
import { Vue } from 'nuxt-property-decorator'
import { Component, Mixins } from 'vue-mixin-decorator'
import AccountHeader from '~/components/account/AccountHeader.vue'
import AccountTabs from '~/components/account/AccountTabs.vue'
import SkipToContent from '~/components/SkipToContent.vue'
import Header from '~/components/layout/header/Header.vue'
import Footer from '~/components/layout/footer/Footer.vue'
import AlertNotification from '~/components/ui/AlertNotification.vue'
import MetaNavPrimary from '~/components/menu/MetaNavPrimary.vue'
import { FocusOnRouteChange } from '~/mixins/FocusOnRouteChange'

@Component({
  head() {
    return {
      titleTemplate: '%s | Honolulu Museum of Art'
    }
  },
  components: {
    SkipToContent,
    Header,
    Footer,
    AlertNotification,
    MetaNavPrimary,
    AccountTabs,
    AccountHeader
  },
  async fetch() {
    if (!this.customer) {
      await this.$store.dispatch('customer/getActiveCustomer')
    }
    this.loading = false
  }
})
export default class AccountLayout extends Mixins(FocusOnRouteChange) {
  protected loading: boolean = true
  private headerHeight: number = 0

  public tabs = [
    {
      label: 'Membership',
      link: '/account/membership/'
    },
    {
      label: 'Tickets & order history',
      link: '/account/tickets/'
    },
    {
      label: 'Saved Items',
      link: '/account/saved-items/'
    },
    {
      label: 'My details',
      link: '/account/'
    }
  ]

  public selectedTab?: ITab = {
    label: 'My details',
    link: '/account/'
  }

  get mainSectionStyle() {
    return `margin-top: ${this.headerHeight}px`
  }

  get loggedIn(): boolean {
    return this.$store.state.customer.logged_in
  }

  get customer() {
    return this.$store.state.customer.customer
  }

  get navigation() {
    return this.$store.state.navigation.primaryNav.items
  }

  get basketExpired() {
    return this.$store.getters['basket/basketExpiry'] > 0
  }

  onHeaderHeightChanged(value) {
    this.headerHeight = value
  }

  created(): void {
    Vue.nextTick(() => {
      if (this.loggedIn && !this.customer) {
        this.$store.dispatch('customer/getActiveCustomer').then((res) => {
          this.loading = false
          return res
        })
      }
      if (this.navigation.length < 1) {
        this.$store.dispatch('navigation/getPrimaryNav')
      }
    })
  }
}
