
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class SkipToContent extends Vue {
  @Prop({ type: String, default: 'main' }) element?: string

  public focusElement = null

  onSkipToContent(): void {
    if (this.focusElement) {
      this.focusElement.focus()
    }
  }

  mounted() {
    this.focusElement = document.getElementById(this.element)
  }
}
