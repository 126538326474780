export const validateModeOfSale = (input, customer_mos, moment) => {
  if (input && input.modes_of_sale && input.modes_of_sale.length) {
    const input_mos = input.modes_of_sale.find(
      (mos) => mos.mode_of_sale_ref == customer_mos
    )

    if (
      !input_mos ||
      !moment().isBetween(input_mos.start_date, input_mos.end_date)
    ) {
      return false
    }

    let hasValidMos = input.modes_of_sale.filter((mos) =>
      moment().isBetween(mos.start_date, mos.end_date)
    )
    if (hasValidMos && hasValidMos.length) {
      return true
    }
  }
  return false
}
