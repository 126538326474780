import VueApollo from 'vue-apollo'
import { DefaultHttpLink, ErrorLink } from './links/default'
import { ApolloLink } from 'apollo-link'
import ApolloClient from 'apollo-client'
import { TokenLink } from './links/token'
import { LogLink } from './links/logger'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createPersistedQueryLink } from 'apollo-link-persisted-queries'

export default (ctx, inject) => {
  const tokenLink = new TokenLink(ctx)
  const errorLink = new ErrorLink(ctx)
  let isIE = false

  if (!process.server) {
    isIE = window && window.document.documentMode
  }

  /**
   * Persisted Links
   */
  const links = ApolloLink.from([
    LogLink,
    ...tokenLink.getLinks(),
    createPersistedQueryLink({ useGETForHashedQueries: !isIE }),
    errorLink.getLink(),
    DefaultHttpLink,
  ])

  /**
   * Non-persisted Links
   */
  const nonPersistedLinks = ApolloLink.from([
    LogLink,
    ...tokenLink.getLinks(),
    errorLink.getLink(),
    DefaultHttpLink,
  ])

  const persistedClient = new ApolloClient({
    ssrMode: process.server,
    link: ctx.isDev ? nonPersistedLinks : links,
    ssrForceFetchDelay: process.browser ? 100 : 0,
    cache: new InMemoryCache(),
    connectToDevTools: process.browser,
    defaultOptions:
      typeof window === 'undefined'
        ? {
            watchQuery: {
              fetchPolicy: 'no-cache',
            },
            query: {
              fetchPolicy: 'no-cache',
              errorPolicy: 'all',
            },
          }
        : undefined,
  })

  const nonPersistedClient = new ApolloClient({
    ssrMode: process.server,
    link: nonPersistedLinks,
    ssrForceFetchDelay: process.browser ? 100 : 0,
    link: nonPersistedLinks,
    cache: new InMemoryCache(),
    connectToDevTools: process.browser,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  })

  /**
   * Provider
   */
  const apolloProvider = new VueApollo({
    defaultClient: persistedClient,
    clients: {
      persisted: persistedClient,
      nonPersisted: nonPersistedClient,
    },
  })

  /**
   * Inject our default apollo client to make it available across the application
   */
  inject('apollo', apolloProvider.defaultClient)
  inject('apolloNonPersisted', apolloProvider.clients.nonPersisted)
}
