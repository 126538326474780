
import { Vue, Component, Prop } from 'vue-property-decorator'
import Accordion from '~/components/ui/Accordion.vue'
import SvgAccordionIcon from '~/static/images/sprites/chevron-down.svg'

@Component({
  components: {
    Accordion,
    SvgAccordionIcon
  }
})
export default class MobileNavItem extends Vue {
  @Prop({ type: Object }) item?: any

  goToPage(page: string): void {
    this.$eventBus.$emit('mobile-nav:click-item', page)

    if (page.includes('http')) {
      window.location.href = page
    } else {
      this.$router.push(page)
    }
  }
}
