import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex'
import * as basket from '~/api/queries/basket.gql'
import * as emails from '~/api/queries/emails.gql'
import { RootState } from './types'
import { Basket, ApplyGiftMembershipInput } from '~/@types/skyway'

export const name = 'gifts'

export const namespaced = true

export const types = {
  ADD_GIFT_VOUCHER: 'ADD_GIFT_VOUCHER',
  REMOVE_GIFT_VOUCHER: 'REMOVE_GIFT_VOUCHER',
  ADD_GIFT_MEMBERSHIP: 'ADD_GIFT_MEMBERSHIP',
  REMOVE_GIFT_MEMBERSHIP: 'REMOVE_GIFT_MEMBERSHIP',
  REDEMPTION_CODE: 'REDEMPTION_CODE',
  REDEMPTION_VOUCHER: 'REDEMPTION_VOUCHER'
}

export interface State {
  gift_vouchers?: object
  gift_memberships?: object
  redemption_code?: string
  redemption_voucher?: object
}

export const state = (): State => ({
  gift_vouchers: undefined,
  gift_memberships: undefined,
  redemption_code: undefined,
  redemption_voucher: undefined
})

export const getters: GetterTree<State, RootState> = {
  redemptionCode: (state: State): string | undefined => {
    return state.redemption_code
  },
  redemptionVoucher: (state: State): object | undefined => {
    return state.redemption_voucher
  }
}

export const actions: ActionTree<State, RootState> = {
  async addGiftMembership(
    context: ActionContext<State, RootState>,
    addGiftMembershipInput
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket['addGiftMembership'],
      variables: {
        addGiftMembershipInput: addGiftMembershipInput.input
      }
    })

    const { data } = response

    if (data.addGiftMembership !== false) {
      context.commit(types.ADD_GIFT_MEMBERSHIP, {
        id: data.addGiftMembership,
        details: addGiftMembershipInput
      })
    }

    return data.addGiftMembership
  },

  async processGiftMembershipConfirmations(
    context: ActionContext<State, RootState>,
    order
  ): Promise<any> {
    if (context.state.gift_memberships != undefined) {
      await this.app.$recaptcha.init()
      const token = await this.app.$recaptcha.execute('gift_membership')

      const vouchers = order.groups.find(
        (group) => group.name == 'GiftCertificate'
      )

      const results = Object.keys(context.state.gift_memberships).map(
        async (id) => {
          if (
            context.state.gift_memberships &&
            context.state.gift_memberships[id]
          ) {
            const details = context.state.gift_memberships[id]
            const recipient = details.recipient
            const voucher = vouchers.items.find((v) => v.item_ref == id)

            if (recipient && voucher) {
              const client = this.app.$apollo
              const response = await client.mutate({
                mutation: emails['sendEmail'],
                variables: {
                  emailInput: {
                    sender: {
                      name: details.from
                    },
                    recipient: {
                      name: details.input.name,
                      email: details.recipient
                    },
                    template_code: 'gift_membership',
                    extra: {
                      ref: voucher.extra.certificate_reference,
                      amount: Math.round(details.input.amount * 1e2) / 1e2, // Use math.round rather than toFixed so output is float rather than string
                      message: details.message
                    }
                  },
                  recaptcha: token
                }
              })

              context.commit(types.REMOVE_GIFT_MEMBERSHIP, voucher.item_ref)

              this.app.$recaptcha.destroy()

              const { data } = response

              return data.sendEmail
            }
          }
        }
      )

      return Promise.all(results).then((res) => true)
    }
  },

  async addGiftCertificate(
    context: ActionContext<State, RootState>,
    addGiftCertificateInput
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket['addGiftCertificate'],
      variables: {
        addGiftCertificateInput: addGiftCertificateInput.input
      }
    })

    const { data } = response

    if (data.addGiftCertificate !== false) {
      context.commit(types.ADD_GIFT_VOUCHER, {
        id: data.addGiftCertificate,
        details: addGiftCertificateInput
      })
    }

    return data.addGiftCertificate
  },

  async removeGiftCertificate(
    context: ActionContext<State, RootState>,
    gc_no
  ): Promise<any> {
    // remove the voucher from the store so we don't send any email on order completion
    const vouchers = this.getters['basket/vouchers']
    const id = vouchers.find((v) => v.extra.certificate_reference == gc_no)
    if (id) {
      // same method is used to remove vouchers and memberships, so check both stores
      context.commit(types.REMOVE_GIFT_VOUCHER, id.item_ref)
      context.commit(types.REMOVE_GIFT_MEMBERSHIP, id.item_ref)
    }

    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket['removeGiftCertificate'],
      variables: {
        gc_no
      }
    })

    const { data } = response

    return data.removeGiftCertificate
  },

  async processGiftCertificateConfirmations(
    context: ActionContext<State, RootState>,
    order
  ): Promise<any> {
    if (context.state.gift_vouchers != undefined) {
      await this.app.$recaptcha.init()
      const token = await this.app.$recaptcha.execute('gift_voucher')

      const vouchers = order.groups.find(
        (group) => group.name == 'GiftCertificate'
      )

      const results = Object.keys(context.state.gift_vouchers).map(
        async (id) => {
          if (context.state.gift_vouchers && context.state.gift_vouchers[id]) {
            const details = context.state.gift_vouchers[id]
            const recipient = details.recipient
            const voucher = vouchers.items.find((v) => v.item_ref == id)

            if (recipient && voucher) {
              const client = this.app.$apollo
              const response = await client.mutate({
                mutation: emails['sendEmail'],
                variables: {
                  emailInput: {
                    sender: {
                      name: details.from
                    },
                    recipient: {
                      name: details.input.name,
                      email: details.recipient
                    },
                    template_code: 'gift_voucher',
                    extra: {
                      ref: voucher.extra.certificate_reference,
                      amount: Math.round(details.input.amount * 1e2) / 1e2, // Use math.round rather than toFixed so output is float rather than string
                      message: details.message
                    }
                  },
                  recaptcha: token
                }
              })

              context.commit(types.REMOVE_GIFT_VOUCHER, voucher.item_ref)

              this.app.$recaptcha.destroy()

              const { data } = response

              return data.sendEmail
            }
          }
        }
      )

      return Promise.all(results).then((res) => true)
    }
  },

  async setRedemptionCode(
    context: ActionContext<State, RootState>,
    code: string
  ) {
    const voucher = await this.dispatch(
      'customer/getGiftCertificateBalance',
      code
    )

    context.commit(types.REDEMPTION_CODE, code)
    context.commit(types.REDEMPTION_VOUCHER, voucher)

    return voucher
  },

  async applyGiftMembership(
    context: ActionContext<State, RootState>,
    applyGiftMembershipInput: ApplyGiftMembershipInput
  ) {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket['applyGiftMembership'],
      variables: {
        applyGiftMembershipInput
      }
    })

    const { data } = response

    return data.applyGiftMembership
  }
}

export const mutations: MutationTree<State> = {
  [types.ADD_GIFT_VOUCHER](state: State, payload: any): void {
    if (state.gift_vouchers === undefined) {
      state.gift_vouchers = {}
    }
    state.gift_vouchers[payload.id] = payload.details
  },
  [types.REMOVE_GIFT_VOUCHER](state: State, payload: any): void {
    if (
      state.gift_vouchers !== undefined &&
      state.gift_vouchers[payload] !== undefined
    ) {
      delete state.gift_vouchers[payload]
    }
  },
  [types.ADD_GIFT_MEMBERSHIP](state: State, payload: any): void {
    if (state.gift_memberships === undefined) {
      state.gift_memberships = {}
    }
    state.gift_memberships[payload.id] = payload.details
  },
  [types.REMOVE_GIFT_MEMBERSHIP](state: State, payload: any): void {
    if (
      state.gift_memberships !== undefined &&
      state.gift_memberships[payload] !== undefined
    ) {
      delete state.gift_memberships[payload]
    }
  },
  [types.REDEMPTION_CODE](state: State, payload: any): void {
    state.redemption_code = payload
  },
  [types.REDEMPTION_VOUCHER](state: State, payload: any): void {
    state.redemption_voucher = payload
  }
}
