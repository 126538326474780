import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex'
import { groupBy, findKey } from 'lodash'
import { RootState } from './types'
import * as forms from '~/api/queries/forms.gql'
import { Form } from '~/@types/skyway'

export const name = 'forms'

export const namespaced = true

export const types = {
  SET_FORM: 'SET_FORM',
  SET_FORMS: 'SET_FORMS',
  SET_FORMS_VIEW: 'SET_FORMS_VIEW',
  SET_SUBMISSION: 'SET_SUBMISSION',
  SET_SUBMISSIONS: 'SET_SUBMISSIONS',
  UNSET_SUBMISSION: 'UNSET_SUBMISSION',
  UNSET_SUBMISSIONS: 'UNSET_SUBMISSIONS',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  UPDATE_ORDER: 'UPDATE_ORDER',
}

export interface UnsetSubmission {
  type: string
  ref: number | string
}

export interface FormSubmission {
  id: number
  title: string
  ticket_number?: number
  basket_item_ref?: number
  data: JSON
}

export interface State {
  form?: Form
  forsm?: Form[]
  errors?: any
  submissions?: Array<FormSubmission>
}

/**
 * Initial state, empty array of forms
 */
export const state = (): State => ({
  form: undefined,
  errors: undefined,
  submissions: [],
})

export const getters: GetterTree<State, RootState> = {
  submissionsByInstance(state: State, getters: any): any[] {
    if (state.submissions && state.submissions.length) {
      const submissionData: any[] = state.submissions.map((s) => s.data)
      const byInstance = groupBy(submissionData, (data) => data.instance_ref)
      return byInstance
    } else {
      return []
    }
  },

  uniqueSubmissions(state: State, getters: any): any[] {
    if (state.submissions) {
      return state.submissions.filter(
        (object, index) =>
          index ===
          state.submissions.findIndex(
            (obj) => JSON.stringify(obj) === JSON.stringify(object)
          )
      )
    } else {
      return []
    }
  },
}

export const actions: ActionTree<State, RootState> = {
  async getForm(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: forms.getForm,
      variables: {
        id,
      },
    })

    const { data } = response

    if (data && data.form) {
      context.commit(types.SET_FORM, data.form)

      return data.form
    }

    return false
  },

  async getFormsByEventType(
    context: ActionContext<State, RootState>,
    type: string
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: forms['getFormsByEventType'],
      variables: {
        type,
      },
    })

    const { data } = response

    context.commit(types.SET_FORMS, data.getFormsByEventType)

    return data.form
  },

  /**
   * Submit a form
   */
  async submitForm(
    context: ActionContext<State, RootState>,
    input: FormSubmission
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: forms.submitForm,
      variables: {
        id: input.id,
        data: input.data,
      },
    })

    const { data } = response

    return data.submitForm
  },

  /**
   * Store a form to be submitted once checkout is complete
   */
  deferForm(
    context: ActionContext<State, RootState>,
    input: FormSubmission
  ): boolean {
    context.commit(types.SET_SUBMISSION, input)
    return true
  },

  async cleanSubmissions(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    if (context.state.submissions !== undefined) {
      const basket = await this.dispatch('basket/getBasket')
      let basketItemRefs: number[] = []

      if (basket && basket.groups.length) {
        const output: any[] = []
        basket.groups.forEach((group) => {
          if (
            group.name === 'Performance' ||
            group.name === 'Classes' ||
            group.name === 'Package'
          ) {
            group.items.forEach((i) => {
              output.push(i)
            })
          }
        })
        basketItemRefs = output.map((t) => parseInt(t.item_ref))
      }

      // get unique forms
      const submissions = context.state.submissions.filter(
        (object, index) =>
          index ===
          context.state.submissions.findIndex(
            (obj) => JSON.stringify(obj) === JSON.stringify(object)
          )
      )

      const output: FormSubmission[] = []
      for (const submission in submissions) {
        const input = submissions[submission]
        if (input.basket_item_ref) {
          // if form is linked to an basket item which is no longer in the basket, ignore it
          if (!basketItemRefs.includes(input.basket_item_ref)) continue
        }
        output.push(input)
      }
      context.commit(types.SET_SUBMISSIONS, output)
    }
  },

  /**
   * Process all submissions
   */
  async processSubmissions(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    if (context.state.submissions !== undefined) {
      if (context.rootState.customer.customer === undefined) {
        await this.dispatch('customer/getActiveCustomer')
      }

      for (const submission in context.state.submissions) {
        const input = context.state.submissions[submission]
        const inputData = JSON.parse(JSON.stringify(input.data))

        const client = this.app.$apollo
        await client.mutate({
          mutation: forms.submitForm,
          variables: {
            id: input.id,
            data: Object.assign(inputData, {
              customer_ref: this.state.customer.customer.customer_ref,
            }),
          },
        })
      }

      context.commit(types.UNSET_SUBMISSIONS)
      return true
    }
  },
}

export const mutations: MutationTree<State> = {
  [types.SET_FORMS](state: State, payload: Form[]): void {
    state.forms = payload
  },
  [types.SET_FORM](state: State, payload: Form): void {
    state.form = payload
  },
  [types.SET_SUBMISSION](state: State, payload: FormSubmission): void {
    if (state.submissions === undefined) state.submissions = []

    if (payload.basket_item_ref) {
      const current = state.submissions.findIndex(
        (s) =>
          s.basket_item_ref === payload.basket_item_ref &&
          s.ticket_number === payload.ticket_number
      )
      if (current !== -1) {
        state.submissions[current] = payload
      } else {
        state.submissions.push(payload)
      }
    } else {
      state.submissions.push(payload)
    }
  },
  [types.SET_SUBMISSIONS](state: State, payload: FormSubmission[]): void {
    state.submissions = payload
  },
  [types.UNSET_SUBMISSION](state: State, payload: UnsetSubmission): void {
    const match = findKey(state.submissions, (sub) => {
      if (sub && sub.data) {
        return (
          parseInt(sub.data[`${payload.type}_ref`]) === parseInt(payload.ref)
        )
      }
    })
    if (match && state.submissions) state.submissions.splice(match, 1)
  },
  [types.UNSET_SUBMISSIONS](state: State): void {
    state.submissions = []
  },
  [types.UPDATE_CUSTOMER](state: State, payload: number | string): void {
    if (state.submissions && state.submissions.length) {
      state.submissions.forEach((sub) => {
        sub.data.customer_ref = parseInt(payload)
      })
    }
  },
  [types.UPDATE_ORDER](state: State, payload: number | string): void {
    if (state.submissions && state.submissions.length) {
      state.submissions.forEach((sub) => {
        sub.data.order_ref = parseInt(payload)
      })
    }
  },
}
