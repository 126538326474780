
import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { Event, Instance } from '~/@types/skyway.ts'
import SvgLocation from '~/static/images/sprites/location.svg'
import SvgClock from '~/static/images/sprites/clock.svg'
import SvgCalendar from '~/static/images/sprites/calendar.svg'
import SvgMember from '~/static/images/sprites/member.svg'
import SvgNew from '~/static/images/sprites/exclamation.svg'
import BookNowButton from '~/components/ui/BookNowButton'

@Component({
  components: {
    SvgLocation,
    SvgClock,
    SvgCalendar,
    SvgMember,
    SvgNew,
    BookNowButton,
  },
})
export default class EventCard extends Vue {
  @Prop(Object) event!: Event
  @Prop(Object) instance?: Instance
  @Prop(String) dateTime?: string

  protected dateFormat = 'MMM D'

  onClickCard(): void {
    this.$router.push(this.link)
  }

  get cardType() {
    if (this.event && this.event.type) {
      const special_types = ['adult-class', 'youth-class', 'field-trip']
      const map = {
        'adult-class': 'adult-classes',
        'youth-class': 'youth-classes',
        'field-trip': 'field-trips',
      }
      if (special_types.includes(this.event.type)) {
        return map[this.event.type] || 'events'
      } else {
        return 'events'
      }
    } else {
      return 'events'
    }
  }

  get link(): string {
    return `/${this.cardType}/${this.event.event_ref}/`
  }

  /**
   * Use computed props to transform event data
   */
  get date() {
    const firstDate = this.event.first_date.substr(0, 10)
    const lastDate = this.event.last_date.substr(0, 10)
    if (this.instance && this.event.type !== 'film') {
      return this.$moment(firstDate).format(this.dateFormat)
    } else {
      return firstDate != lastDate
        ? this.$moment(firstDate).format(this.dateFormat) +
            ' - ' +
            this.$moment(lastDate).format(this.dateFormat)
        : this.$moment(firstDate).format(this.dateFormat)
    }
  }

  /**
   * Static venue for now
   */
  get venue() {
    return this.event.facility ? this.event.facility.title : ''
  }

  get title() {
    return this.event.title
    // return this.instance ? this.instance.title : this.event.title
  }

  /**
   * only show time for single events
   */
  get time() {
    if (this.instance && this.event.type !== 'film') {
      return this.$moment(this.instance.date_time).format('LT')
    } else if (this.event.instances && this.event.instances.length === 1) {
      return this.$moment(this.event.instances[0].date_time).format('LT')
    } else {
      return ''
    }
  }

  /**
   * Lorem image for now if the event has nothing uploaded
   */
  get imageStyle(): string {
    if (this.event.images) {
      return `background-image: url('${this.$config.get('ASSET_PATH')}/${
        this.event.images.cta
      }');`
    } else {
      return `background-image: url('https://picsum.photos/350/240?k=${Math.random()}')`
    }
  }

  get price() {
    return this.event.price ? this.$formatCurrency(this.event.price) : ''
  }

  get membersEvent() {
    return this.event.type === 'member'
  }

  get isNew() {
    if (this.instance) {
      return this.instance.new ? this.instance.new : false
    } else {
      return this.event && this.event.extra.new ? this.event.extra.new : false
    }
  }
}
