import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex'
import { RootState } from './types'
import * as csi from '~/api/queries/csi.gql'
//import { findKey } from 'lodash'

import { CreateCustomerServiceIssueInput } from '~/@types/skyway'

export const name = 'csi'

export const namespaced = true

export const types = {
    SET_CSI: 'SET_CSI',
    CLEAR_CSI: 'CLEAR_CSI',
    CLEAR_ALL: 'CLEAR_ALL'
}

export interface State {
    csis?: Array<CreateCustomerServiceIssueInput>
}

export const state = (): State => ({
    csis: []
})

export const actions: ActionTree<State, RootState> = {
    async processCsi(
        context: ActionContext<State, RootState>,
        createCustomerServiceIssueInput: CreateCustomerServiceIssueInput
    ): Promise<any> {
        const response = await this.app.$apollo.mutate({
            mutation: csi['createCustomerServiceIssue'],
            variables: {
                createCustomerServiceIssueInput
            }
        })

        const { data } = response

        return data.createCustomerServiceIssue
    }
}

export const mutations: MutationTree<State> = {
    /**
     * Push a CSI onto the stack to be processed later
     */
    /*
    [types.SET_CSI](
      state: State,
      payload: CreateCustomerServiceIssueInput
    ): void {
      const exists = _.findKey(state.csis, c => {
        return (
          c.activity_type_ref == payload.activity_type_ref && 
          (!c.instance_ref || c.instance_ref == payload.instance_ref)
        )
      })
      if (exists) {
        state.csis[exists] = payload
      } else {
        state.csis.push(payload)
      }
    },
    [types.CLEAR_ALL](state: State): void {
      state.csis.splice(0, state.csis.length)
    }
    */
}
