import BasketServiceV15 from '~/services/v15/basket'
import * as basket from '~/api/queries/basket.gql'

export default class BasketService extends BasketServiceV15 {
  async removeMembership(id: string) {
    const response = await this.client.mutate({
      mutation: basket['removeMembership'],
      variables: {
        id: parseInt(id),
      },
    })

    return this.respond(response)
  }
}
