
import { Vue, Prop } from 'nuxt-property-decorator'
import { Component, Mixins } from 'vue-mixin-decorator'
import NotFound from '~/components/errors/NotFound.vue'
import Fatal from '~/components/errors/Fatal.vue'
import BasketEmpty from '~/components/errors/BasketEmpty.vue'
import MetaNavPrimary from '~/components/menu/MetaNavPrimary.vue'
import { FocusOnRouteChange } from '~/mixins/FocusOnRouteChange'

@Component({
  components: {
    BasketEmpty,
    Fatal,
    NotFound,
    MetaNavPrimary,
  },
})
export default class ErrorLayout extends Mixins(FocusOnRouteChange) {
  @Prop() error
  private headerHeight: number = 0

  get mainSectionStyle() {
    return `margin-top: ${this.headerHeight}px`
  }

  onHeaderHeightChanged(value) {
    this.headerHeight = value
  }
}
