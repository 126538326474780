
import { Component, Vue, Watch } from 'nuxt-property-decorator'
import StoryCard from '~/components/cards/StoryCard.vue'
import FAQCard from '~/components/cards/FAQCard.vue'
import EventCard from '~/components/cards/EventCard.vue'
import ArtifactCard from '~/components/cards/ArtifactCard.vue'
import SvgMenuClose from '~/static/images/sprites/menu-close.svg'
import SvgSearch from '~/static/images/sprites/search.svg'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { facets } from '~/config/global-facets'

@Component({
  components: {
    SvgMenuClose,
    SvgSearch,
    FAQCard,
    StoryCard,
    EventCard,
    ArtifactCard,
  },
})
export default class GlobalSearch extends Vue {
  public searchTerm: string = ''
  public open: boolean = false
  public offsetElement: any = null
  public topOffset: number | null = null
  public windowHeight: number | null = null
  public field: any = null
  public fixtureElement: any = null

  @Watch('$route')
  onPropertyChanged(newVal, oldVal) {
    this.closeModal()
  }

  openModal() {
    this.open = true
    this.fixtureElement = document.getElementById(
      `global-search-fixture${this._uid}`
    )
    disableBodyScroll(this.fixtureElement)
    this.focusInput()
    this.getTopOffset()
  }

  getTopOffset() {
    if (this.offsetElement && this.$mq != 'sm') {
      this.topOffset = this.offsetElement.clientHeight
    } else {
      this.topOffset = null
    }
  }

  focusInput() {
    setTimeout(() => {
      if (this.field) {
        this.field.focus()
      }
    }, 500)
  }

  closeModal() {
    this.open = false
    if (this.field) {
      this.field.value = ''
    }
    clearAllBodyScrollLocks()
  }

  get components() {
    return facets
  }

  updateterm(newTerm) {
    this.searchTerm = newTerm
  }

  created() {
    this.$reactive.setIndexes(['*'])
    facets.settings = this.$reactive.getSettings()
    facets.datasearch.placeholder = this.$t(facets.datasearch.placeholder)
  }

  mounted() {
    this.$nextTick(() => {
      this.offsetElement = document.querySelector('.main-menu')

      const field = this.$refs.global_search
      if (field) {
        this.field = field.$el.querySelector('.catalogue-search__input')
      }
    })
  }
}
