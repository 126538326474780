export const Notification = {
  NETWORK_ERROR:
    'Our ticketing server is not available, please try again shortly.',
  GQL_ERROR: {
    generic: 'Loading data failed.',
    getEvents: 'Could not retrieve events from the ticketing server.',
    getBasket: 'Failed to load basket.',
    getActiveCustomer: 'Failed to load customer data.',
    login:
      'We could not log you in to our ticketing system, please check your details and try again',
    addTicket:
      'That ticket could not be added to your basket. Please make another selection and try again',
    getCustomerTickets: 'Your tickets could not be loaded, please try again.',
    '{Could not find seats for specified criteria}':
      'Warning: The number of tickets you have requested exceeds current capacity. Please reduce your ticket quantity and try again.',
    '{This seat combination would leave single seats}':
      "Sorry, we require that your selection doesn't leave a single seat at the end of a row please check your selected seats and remove any that leave a single seat.",
    'Token is invalid':
      'Sorry, we were unable to log you in using this password reset token. It may have expired. Please try again',
    'Multiple membership contributions to the same Membership Organization are not allowed.':
      'You can not add more than one of the same membership type to your basket',
    'Invalid Source_no': 'Sorry, that does not appear to be a valid source ID',
    'Invalid Gift Certificate number':
      'Sorry, that does not appear to be a valid gift/credit voucher',
    addGiftCertificate:
      'Your gift certificate could not be added to your basket. Please try again.',
    createCustomer:
      'We could not register you with our ticketing system, please ensure that you do not already have an account registered using that address and try again.',
    sendEmail:
      'Sorry your message could not be delivered at the moment. Please try again later.'
  }
}
