import BaseService from '~/services/base'
import * as seats from '~/api/queries/seats.gql'
import { SeatMap } from '~/@types/skyway'

export default class SeatsService extends BaseService {
  async getSeatMap(instance_ref: string): Promise<any> {
    const response = await this.client.query({
      query: seats['getSeatMap'],
      variables: {
        instance_ref: instance_ref,
      },
      context: {
        public: false,
        credentials: 'include',
      },
      fetchPolicy: 'network-only',
    })

    return this.respond(response)
  }

  async getSeatMapMeta(instance_ref: string): Promise<{ getSeatMap: SeatMap }> {
    const response = await this.client.query({
      query: seats['getSeatMapMeta'],
      variables: {
        instance_ref: instance_ref,
      },
      context: {
        public: false,
        credentials: 'include',
      },
      fetchPolicy: 'network-only',
    })

    return this.respond(response)
  }

  async getSeatAvailability(
    instance_ref: string,
    screens?: string[]
  ): Promise<any> {
    const response = await this.client.query({
      query: seats['getSeatAvailability'],
      variables: {
        instance_ref: instance_ref,
        screens: screens,
      },
      context: {
        public: false,
        credentials: 'include',
      },
      fetchPolicy: 'network-only',
    })

    return this.respond(response)
  }

  async getSeatMapZones(instance_ref: string): Promise<any> {
    const response = await this.client.query({
      query: seats['getSeatMapZones'],
      variables: {
        instance_ref: instance_ref,
      },
      context: {
        public: false,
        credentials: 'include',
      },
      fetchPolicy: 'network-only',
    })

    return this.respond(response)
  }

  async getScreenAvailability(instance_ref: string): Promise<any> {
    const response = await this.client.query({
      query: seats['getScreensWithAvailability'],
      variables: {
        instance_ref: instance_ref,
      },
      context: {
        public: false,
        credentials: 'include',
      },
      fetchPolicy: 'network-only',
    })
    return this.respond(response)
  }

  async getScreenZones(
    instance_ref: string,
    facility_ref: string
  ): Promise<any> {
    const response = await this.client.query({
      query: seats['getScreenZones'],
      variables: {
        instance_ref,
        facility_ref,
      },
      context: {
        public: false,
        credentials: 'include',
      },
      fetchPolicy: 'network-only',
    })
    return this.respond(response)
  }

  async getSeatTypes(): Promise<any> {
    const response = await this.client.query({
      query: seats['getSeatTypes'],
      context: {
        public: true,
      },
    })

    return this.respond(response)
  }
}
