import { config } from '~/config'

export default (context, inject) => {
  const formatCurrency = (number) => {
    return number.toLocaleString(context.app.$config.get('LOCALE'), {
      style: 'currency',
      currency: context.app.$config.get('CURRENCY')
    })
  }
  inject('formatCurrency', formatCurrency)
}
