
import { Component, Vue } from 'nuxt-property-decorator'
import SvgActivity from '~/static/images/sprites/activity.svg'
import TicketItem from '~/components/menu/drawers/tickets/TicketItem.vue'
import AccountMembershipInfo from '~/components/account/AccountMembershipInfo.vue'

@Component({
  components: {
    SvgActivity,
    TicketItem,
    AccountMembershipInfo
  }
})
export default class DrawerContentMyTicketsPasses extends Vue {
  get loggedIn(): boolean {
    return this.$store.getters['customer/isLoggedIn']
  }

  get customer(): any {
    return this.$store.getters['customer/customer']
  }

  get tickets(): any {
    return this.customer ? this.customer.tickets : []
  }

  get membership(): any {
    const memberships = this.customer ? this.customer.memberships : []

    if (memberships.length > 0) {
      const current = memberships.filter((membership) => membership.current)
      if (current.length > 0) {
        return current[0]
      }
    }

    return false
  }

  onClickSignIn() {
    this.$eventBus.emit('meta-nav-item:trigger-close')

    this.$router.push('/sign-in/')
  }
}
