
import { Component, Vue, Prop, namespace } from 'nuxt-property-decorator'

const events = namespace('events')

@Component
export default class BreadCrumbs extends Vue {
  @Prop({ type: Array, default: () => [] }) items!: Array<{
    name: string
    link: string
  }>
  @Prop({ type: Boolean, default: true }) enabled?: boolean
  @Prop({ type: String, default: null }) currentTitle?: string | null

  @events.Getter
  private eventType

  get generated() {
    if (this.items.length) {
      return this.items
    } else {
      return this.generateCrumbs()
    }
  }

  get previousIndex() {
    let index = this.generated.length >= 1 ? this.generated.length - 1 : 0
    if (index >= 1) {
      index -= 1
    }
    return index
  }

  get mobileLink() {
    return this.generated && this.generated[this.previousIndex]
      ? this.generated[this.previousIndex].link
      : '/'
  }

  get mobileName() {
    let name =
      this.generated && this.generated[this.previousIndex]
        ? this.generated[this.previousIndex].name
        : ''
    if (name == 'Posts') {
      name = 'Stories'
    }
    return `Back to ${name}`
  }

  generateCrumbs(): Array<{ name: string; link: string }> {
    let pathArray = this.$route.path.split('/')
    pathArray.shift()

    pathArray = pathArray.filter((item) => item !== '')

    const crumbs: { link: string; name: string }[] = []

    crumbs.push({
      link: '/',
      name: 'Home',
    })

    pathArray.forEach((item, index) => {
      let crumb
      if (pathArray.length - 1 === index) {
        crumb = {
          link: '',
          name: this.currentTitle || item.replace(/-/g, ' '),
        }
      } else {
        if (this.eventType === 'exhibition' && item === 'events') {
          crumb = {
            link: `/exhibitions/`,
            name: 'Exhibitions',
          }
        } else {
          crumb = {
            link: `/${item}/`,
            name: item.replace(/-/g, ' '),
          }
        }
      }

      crumbs.push(crumb)
    })

    return crumbs
  }
}
