export default function ({ app, route }) {
    if (route.query.source) {
        app.$eventBus.emit('update_source', route.query.source)
    }

    if (route.query.promo || route.query.promocode) {
        const promo = route.query.promo || route.query.promocode

        app.store.commit('selection/CLEAR_SELECTION')
        app.$eventBus.emit('update_promo_code', promo)
    }
}