
import { Component, Vue, Prop } from 'nuxt-property-decorator'
// @ts-ignore
import SvgChevron from '~/static/images/sprites/chevron-down.svg'

@Component({
  components: {
    SvgChevron,
  },
})
export default class Accordion extends Vue {
  @Prop() id!: string
  @Prop({ type: Boolean, default: false }) defaultOpen!: boolean

  isOpen: boolean = false
  content: HTMLElement | null = null

  open(): void {
    const content = this.content as HTMLElement

    if (content) {
      const maxHeight = content.firstElementChild
        ? content.firstElementChild.clientHeight
        : 0

      this.isOpen = true
      this.$nextTick(() => {
        content.style.maxHeight = `${maxHeight}px`
        content.style.visibility = 'visible'
      })
    }
  }

  close(): void {
    const content = this.content as HTMLElement
    if (content) {
      this.isOpen = false
      content.style.maxHeight = '0'
      content.style.visibility = 'hidden'
    }
  }

  toggle(): void {
    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  mounted(): void {
    this.content = this.$el.querySelector('.accordion__content')
    this.isOpen = this.defaultOpen

    if (this.isOpen) {
      this.open()
    }
  }
}
