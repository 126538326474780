import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../src/layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_86717aca from 'nuxt_plugin_sentryserver_86717aca' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_570dd113 from 'nuxt_plugin_sentryclient_570dd113' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_pluginindexc105599c_d966372a from 'nuxt_plugin_pluginindexc105599c_d966372a' // Source: ./plugin.index.c105599c.ts (mode: 'client')
import nuxt_plugin_recaptcha_f0e9d674 from 'nuxt_plugin_recaptcha_f0e9d674' // Source: ./recaptcha.js (mode: 'all')
import nuxt_plugin_libmoduleplugincf58b552_1e9485ee from 'nuxt_plugin_libmoduleplugincf58b552_1e9485ee' // Source: ./lib.module.plugin.cf58b552.js (mode: 'all')
import nuxt_plugin_pluginutils_a39da578 from 'nuxt_plugin_pluginutils_a39da578' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_c6c90ce2 from 'nuxt_plugin_pluginrouting_c6c90ce2' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_8c411780 from 'nuxt_plugin_pluginmain_8c411780' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_nuxtmq_63af311f from 'nuxt_plugin_nuxtmq_63af311f' // Source: ./nuxt-mq.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_60265b7f from 'nuxt_plugin_cookieuniversalnuxt_60265b7f' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_portalvue_93d59e48 from 'nuxt_plugin_portalvue_93d59e48' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_axios_73956c52 from 'nuxt_plugin_axios_73956c52' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_moment_0f1f82a1 from 'nuxt_plugin_moment_0f1f82a1' // Source: ./moment.js (mode: 'all')
import nuxt_plugin_polyfills_473a22e9 from 'nuxt_plugin_polyfills_473a22e9' // Source: ../src/plugins/polyfills.js (mode: 'client')
import nuxt_plugin_htmlElement_08a86f08 from 'nuxt_plugin_htmlElement_08a86f08' // Source: ../src/plugins/htmlElement.js (mode: 'client')
import nuxt_plugin_axios_5659d192 from 'nuxt_plugin_axios_5659d192' // Source: ../src/plugins/axios.js (mode: 'all')
import nuxt_plugin_config_3fddc1f5 from 'nuxt_plugin_config_3fddc1f5' // Source: ../src/plugins/config.ts (mode: 'all')
import nuxt_plugin_eventBus_aaeb250a from 'nuxt_plugin_eventBus_aaeb250a' // Source: ../src/plugins/eventBus.js (mode: 'all')
import nuxt_plugin_apollo_028f10fe from 'nuxt_plugin_apollo_028f10fe' // Source: ../src/plugins/apollo/apollo.js (mode: 'all')
import nuxt_plugin_registerservices_3c17ab92 from 'nuxt_plugin_registerservices_3c17ab92' // Source: ../src/plugins/register-services (mode: 'all')
import nuxt_plugin_currency_37da79e0 from 'nuxt_plugin_currency_37da79e0' // Source: ../src/plugins/currency.js (mode: 'all')
import nuxt_plugin_cookies_2c3cf8fe from 'nuxt_plugin_cookies_2c3cf8fe' // Source: ../src/plugins/cookies.js (mode: 'client')
import nuxt_plugin_reactivesearch_1a530080 from 'nuxt_plugin_reactivesearch_1a530080' // Source: ../src/plugins/reactivesearch.js (mode: 'all')
import nuxt_plugin_decodeEntities_15b4f9dc from 'nuxt_plugin_decodeEntities_15b4f9dc' // Source: ../src/plugins/decodeEntities.js (mode: 'all')
import nuxt_plugin_globalcomponents_52ce1226 from 'nuxt_plugin_globalcomponents_52ce1226' // Source: ../src/plugins/global-components.js (mode: 'all')
import nuxt_plugin_globallisteners_5fa9820c from 'nuxt_plugin_globallisteners_5fa9820c' // Source: ../src/plugins/global-listeners.js (mode: 'all')
import nuxt_plugin_veevalidate_1a0c1998 from 'nuxt_plugin_veevalidate_1a0c1998' // Source: ../src/plugins/vee-validate.js (mode: 'all')
import nuxt_plugin_vuexpersist_13f465a2 from 'nuxt_plugin_vuexpersist_13f465a2' // Source: ../src/plugins/vuex-persist (mode: 'client')
import nuxt_plugin_momentlocaleoverrides_575238cd from 'nuxt_plugin_momentlocaleoverrides_575238cd' // Source: ../src/plugins/moment-locale-overrides.js (mode: 'all')
import nuxt_plugin_i18n_3032693c from 'nuxt_plugin_i18n_3032693c' // Source: ../src/plugins/i18n.js (mode: 'all')
import nuxt_plugin_aos_78651e70 from 'nuxt_plugin_aos_78651e70' // Source: ../src/plugins/aos.js (mode: 'client')
import nuxt_plugin_lazyload_10f2b7b2 from 'nuxt_plugin_lazyload_10f2b7b2' // Source: ../src/plugins/lazyload.js (mode: 'client')
import nuxt_plugin_announcer_6bc55ea4 from 'nuxt_plugin_announcer_6bc55ea4' // Source: ../src/plugins/announcer.js (mode: 'client')
import nuxt_plugin_custommessages_19200ac5 from 'nuxt_plugin_custommessages_19200ac5' // Source: ../src/plugins/custom-messages.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Honolulu Museum of Art","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, viewport-fit=cover"},{"hid":"description","name":"description","content":"Honolulu Museum of Art"},{"hid":"robots","name":"robots","content":"index, follow"},{"hid":"google-site-verification","name":"google-site-verification","content":""}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"apple-touch-icon","sizes":"180x180","href":"\u002Fapple-touch-icon.png"},{"rel":"icon","type":"image\u002Fpng","sizes":"32x32","href":"\u002Ffavicon-32x32.png"},{"rel":"icon","type":"image\u002Fpng","sizes":"192x192","href":"\u002Fandroid-chrome-192x192.png"},{"rel":"icon","type":"image\u002Fpng","sizes":"16x16","href":"\u002Ffavicon-16x16.png"},{"rel":"manifest","href":"\u002Fsite.webmanifest","crossorigin":"use-credentials"},{"rel":"mask-icon","href":"\u002Fsafari-pinned-tab.svg","color":"#00B4CD"},{"rel":"shortcut icon","href":"\u002Ffavicon.ico","type":"image\u002Fx-icon"},{"rel":"icon","href":"\u002Ffavicon.ico","type":"image\u002Fx-icon"}],"__dangerouslyDisableSanitizers":["script"],"script":[{"hid":"queueit-client","src":"\u002F\u002Fstatic.queue-it.net\u002Fscript\u002Fqueueclient.min.js"},{"hid":"queueit-config","src":"\u002F\u002Fstatic.queue-it.net\u002Fscript\u002Fqueueconfigloader.min.js","data-queueit-c":"honolulumuseum"},{"src":"https:\u002F\u002Fwww.youtube.com\u002Fiframe_api"}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_86717aca === 'function') {
    await nuxt_plugin_sentryserver_86717aca(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_570dd113 === 'function') {
    await nuxt_plugin_sentryclient_570dd113(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginindexc105599c_d966372a === 'function') {
    await nuxt_plugin_pluginindexc105599c_d966372a(app.context, inject)
  }

  if (typeof nuxt_plugin_recaptcha_f0e9d674 === 'function') {
    await nuxt_plugin_recaptcha_f0e9d674(app.context, inject)
  }

  if (typeof nuxt_plugin_libmoduleplugincf58b552_1e9485ee === 'function') {
    await nuxt_plugin_libmoduleplugincf58b552_1e9485ee(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_a39da578 === 'function') {
    await nuxt_plugin_pluginutils_a39da578(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_c6c90ce2 === 'function') {
    await nuxt_plugin_pluginrouting_c6c90ce2(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_8c411780 === 'function') {
    await nuxt_plugin_pluginmain_8c411780(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtmq_63af311f === 'function') {
    await nuxt_plugin_nuxtmq_63af311f(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_60265b7f === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_60265b7f(app.context, inject)
  }

  if (typeof nuxt_plugin_portalvue_93d59e48 === 'function') {
    await nuxt_plugin_portalvue_93d59e48(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_73956c52 === 'function') {
    await nuxt_plugin_axios_73956c52(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_0f1f82a1 === 'function') {
    await nuxt_plugin_moment_0f1f82a1(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_polyfills_473a22e9 === 'function') {
    await nuxt_plugin_polyfills_473a22e9(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_htmlElement_08a86f08 === 'function') {
    await nuxt_plugin_htmlElement_08a86f08(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_5659d192 === 'function') {
    await nuxt_plugin_axios_5659d192(app.context, inject)
  }

  if (typeof nuxt_plugin_config_3fddc1f5 === 'function') {
    await nuxt_plugin_config_3fddc1f5(app.context, inject)
  }

  if (typeof nuxt_plugin_eventBus_aaeb250a === 'function') {
    await nuxt_plugin_eventBus_aaeb250a(app.context, inject)
  }

  if (typeof nuxt_plugin_apollo_028f10fe === 'function') {
    await nuxt_plugin_apollo_028f10fe(app.context, inject)
  }

  if (typeof nuxt_plugin_registerservices_3c17ab92 === 'function') {
    await nuxt_plugin_registerservices_3c17ab92(app.context, inject)
  }

  if (typeof nuxt_plugin_currency_37da79e0 === 'function') {
    await nuxt_plugin_currency_37da79e0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_cookies_2c3cf8fe === 'function') {
    await nuxt_plugin_cookies_2c3cf8fe(app.context, inject)
  }

  if (typeof nuxt_plugin_reactivesearch_1a530080 === 'function') {
    await nuxt_plugin_reactivesearch_1a530080(app.context, inject)
  }

  if (typeof nuxt_plugin_decodeEntities_15b4f9dc === 'function') {
    await nuxt_plugin_decodeEntities_15b4f9dc(app.context, inject)
  }

  if (typeof nuxt_plugin_globalcomponents_52ce1226 === 'function') {
    await nuxt_plugin_globalcomponents_52ce1226(app.context, inject)
  }

  if (typeof nuxt_plugin_globallisteners_5fa9820c === 'function') {
    await nuxt_plugin_globallisteners_5fa9820c(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_1a0c1998 === 'function') {
    await nuxt_plugin_veevalidate_1a0c1998(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuexpersist_13f465a2 === 'function') {
    await nuxt_plugin_vuexpersist_13f465a2(app.context, inject)
  }

  if (typeof nuxt_plugin_momentlocaleoverrides_575238cd === 'function') {
    await nuxt_plugin_momentlocaleoverrides_575238cd(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_3032693c === 'function') {
    await nuxt_plugin_i18n_3032693c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_aos_78651e70 === 'function') {
    await nuxt_plugin_aos_78651e70(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_lazyload_10f2b7b2 === 'function') {
    await nuxt_plugin_lazyload_10f2b7b2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_announcer_6bc55ea4 === 'function') {
    await nuxt_plugin_announcer_6bc55ea4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_custommessages_19200ac5 === 'function') {
    await nuxt_plugin_custommessages_19200ac5(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
