
import { Component, Vue } from 'nuxt-property-decorator'
import DonationTextField from '~/components/ui/fields/DonationTextField.vue'
import Loader from '~/components/ui/Loader.vue'

@Component({
  components: {
    DonationTextField,
    Loader,
  },
})
export default class DonationSelector extends Vue {
  private loading: boolean = false
  private fund_ref = this.$config.get('DONATION_FUND_REF')

  private selected?: number | null = null
  private customAmount: number | string = 0

  private amounts: Array<any> = [
    {
      value: 25,
      label: '25.00',
    },
    {
      value: 100,
      label: '100.00',
    },
    {
      value: 250,
      label: '250.00',
    },
  ]

  async getCurrentBasketDonation() {
    const donations = this.$store.getters['basket/contributions']
    const basket_donation = donations.find(
      (group) => group.extra.fund_ref == this.fund_ref
    )

    return basket_donation
  }

  addDonation() {
    return this.removeDonation().then((res) => {
      this.loading = true
      return this.$store
        .dispatch('funds/addContribution', {
          fund_ref: this.fund_ref,
          amount: parseFloat(this.calculatedAmount),
        })
        .then((result) => {
          this.$store.dispatch('basket/getBasket', 'network-only')
          this.$emit('donation-selected', true)
          this.$eventBus.notifySuccess(
            'Thank You! Your donation has been added to your cart',
            null,
            false,
            { to: '/cart/', label: 'Go to cart' }
          )
          this.$eventBus.emit('meta-nav-item:trigger-close')
          this.loading = false
          return result
        })
    })
  }

  get calculatedAmount() {
    return this.selected == 0
      ? parseFloat(this.customAmount)
      : parseFloat(this.selected)
  }

  async removeDonation(): Promise<void> {
    this.loading = true

    return this.getCurrentBasketDonation()
      .then(async (donation) => {
        const removed = await this.$store.dispatch(
          'funds/removeContribution',
          parseInt(donation.item_ref)
        )
        this.loading = false
        return removed
      })
      .catch((err) => true)
  }

  /**
   * Reset the donation form to its default state when donation removed from basket
   */
  processDonationRemoved(): void {
    this.selected = null
    this.customAmount = 0
    this.$emit('donation-selected', false)
  }

  preset() {
    this.getCurrentBasketDonation()
      .then((donation) => {
        if (this.amounts.map((a) => a.value).includes(donation.unit_price)) {
          this.selected = donation.unit_price
        } else {
          this.selected = 0
          this.customAmount = donation.unit_price
        }
      })
      .catch((err) => {
        this.selected = null
      })
  }

  created() {
    this.preset()

    this.$eventBus.on('basket:donation-removed', this.processDonationRemoved)
    this.$eventBus.on('basket:finished-loading', this.preset)
  }

  beforeDestroy() {
    this.$eventBus.off('basket:donation-removed', this.processDonationRemoved)
  }

  protected getFormattedCurrency(amount: number): string {
    return this.$formatCurrency(amount)
  }
}
