
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class UIButton extends Vue {
  @Prop() modifier!: string
  @Prop() color!: string
  @Prop() label!: string
  @Prop() custom?: string
  @Prop() icon?: string
  @Prop({ default: false }) to?: string
  @Prop({ default: 'button' }) type?: string
  @Prop({ type: String }) srText?: string
  @Prop({ type: Boolean, default: false }) disabled?: boolean

  handleClick($event) {
    if (this.to) {
      this.$router.push(this.to)
    } else {
      this.$emit('click', $event)
    }
  }
}
