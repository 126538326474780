import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages as english } from 'vee-validate/dist/locale/en.json'
import { messages as japanese } from 'vee-validate/dist/locale/ja.json'

export default ({ app, route }, inject) => {
  const locale = app.i18n.getLocaleCookie() || app.i18n.defaultLocale

  let messages = english
  if (locale == 'ja') {
    messages = japanese
  }

  Object.keys(rules).forEach((rule) => {
    extend(rule, {
      ...rules[rule], // copies rule configuration
      message: messages[rule] // assign message
    })
  })

  extend('required', {
    message: 'This field is required'
  })

  extend('confirmed', {
    message: 'The password confirmation does not match'
  })

  extend('email', {
    message: 'Please enter a valid email address'
  })

  extend('regex', {
    message: 'Please use alphabetic characters only'
  })

  extend('password', (value) => {
    if (value.match(/[0-9]/g) !== null && value.match(/[a-zA-Z]/g) !== null) {
      return true
    }

    return 'The {_field_} must contain at least one number and one letter'
  })

  extend('phone', (value) => {
    if (value.match(/[a-zA-Z]/g) === null) {
      return true
    }

    return 'This field must contain only numbers and symbols'
  })
}
