export const facets = {
  settings: {},
  datasearch: {
    componentId: 'SearchSensor',
    dataField: ['post_title', 'post_content', 'title', 'description', 'terms.category.name'],
    fieldWeights: [5, 1, 5, 1],
    autosuggest: false,
    placeholder: 'Search for an article or topic',
    showIcon: false,
    showClear: false,
    highlight: false,
    debounce: 500,
    queryFormat: 'and',
    innerClass: {
      input: 'catalogue-search__input'
    }
  },
  result: {
    className: 'results',
    componentId: 'SearchResult',
    dataField: 'post_title,title',
    size: 12,
    showResultStats: true,
    showEndPage: true,
    pagination: true,
    excludeFields: ['post_content*','comment*', 'date_terms', 'description'],
    react: {
      and: ['SearchSensor']
    },
    innerClass: {
      sortOptions: 'hidden',
      pagination: 'search__pagination-wrapper',
      noResults: 'no-results'
    }
  }
}