import VueGtm, {
  GtmSupportOptions,
  VueGtmUseOptions,
} from '@gtm-support/vue2-gtm'
import Vue from 'vue'
import gtmEvents from '@ad/gtm'
import { config } from '~/config'

export default ({ app, route }, inject) => {
  const tags: string | string[] = config.GOOGLE_TAG_MANAGER.split(',')
  console.log(tags)
  if (!tags[0]) tags[0] = 'GTM-P32C6CF' // @todo add back to options.id

  const options: GtmSupportOptions | VueGtmUseOptions = {
    id: ['GTM-P32C6CF'],
    enabled: true,
    debug: true,
    loadScript: true,
    vueRouter: app.router,
  }

  Vue.use(VueGtm, options)

  const gtm = Vue.gtm

  const eventsInstance = gtmEvents(['ga4'], gtm, config)

  inject('gtmEvents', eventsInstance)
  // inject('gtm', gtm)

  /**
   * SM - Commented out event listener registration below
   * the event bus isn't ready at this point.
   * We'll probably need to add these in plugins/global-listeners.js
   * to get this working
   */

  /**
   * GOOGLE ANALYTICS
   */
  // GA4

  // app.$eventBus.on('gtm:add-to-basket', (product) => {
  //   const payload: AddTicketPayload = {
  //     instance_ref: product.id,
  //     name: product.name,
  //     qty: product.quantity,
  //     value: product.value,
  //     category: product.category,
  //   }
  //   gtmEvents.addToCart(payload)
  //   // app.$gtm.trackEvent({
  //   //   event: 'addToCart',
  //   //   ecommerce: {
  //   //     currencyCode: 'GBP',
  //   //     add: {
  //   //       products: [product]
  //   //     }
  //   //   }
  //   // })
  // })

  // app.$eventBus.on('gtm:remove-from-basket', (product) => {
  //   app.$gtm.trackEvent({
  //     event: 'removeFromCart',
  //     ecommerce: {
  //       remove: {
  //         products: [product]
  //       }
  //     }
  //   })
  // })

  // app.$eventBus.on('gtm:select-delivery-method', ({step, option}) => {
  //   app.$gtm.trackEvent({
  //     event: 'checkoutOption',
  //     ecommerce: {
  //       currencyCode: 'GBP',
  //       checkout_option: {
  //         actionField: {
  //           step: step,
  //           option: option
  //         }
  //       }
  //     }
  //   })
  // })

  // app.$eventBus.on('gtm:proceed-to-delivery', (products) => {
  //   app.$gtm.trackEvent({
  //     event: 'checkout',
  //     ecommerce: {
  //       checkout: {
  //         actionField: {
  //           step: 1
  //         },
  //         products: gtmProductsTransformer(products)
  //       }
  //     }
  //   })
  // })

  // app.$eventBus.on('gtm:proceed-to-payment', (params) => {
  //   app.$gtm.trackEvent({
  //     event: 'checkout',
  //     ecommerce: {
  //       checkout: {
  //         actionField: {
  //           step: 2,
  //           option: params.deliveryMethodName
  //         },
  //         products: gtmProductsTransformer(params.products)
  //       }
  //     }
  //   })
  // })

  // app.$eventBus.on('gtm:complete-transaction', ({order, promo}) => {
  //   app.$gtm.trackEvent({
  //     ecommerce: {
  //       purchase: {
  //         actionField: {
  //           id: order.order_ref,
  //           affiliation: 'Southbank Centre',
  //           revenue: order.total,
  //           tax: 0,
  //           shipping: order.order_fees.length
  //             ? sumBy(order.order_fees, (fee) => fee.amount)
  //             : 0,
  //           coupon: promo
  //         },
  //         products: gtmProductsTransformer(order.groups)
  //       }
  //     }
  //   })
  // })
}
