
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import { Artifact } from '~/store/artifact'

/**
 * Fallback page component, will match anything not caught by a more specific route
 */
@Component({})
export default class ArtifactActions extends Vue {
  @Prop() artifact: Artifact
  changing: boolean = false

  @Watch('saved')
  watchSaved(saved, old) {
    this.$emit('change', saved)
  }

  toggleSavedArtifact() {
    if (!this.isLoggedIn) {
      this.$eventBus.notifyFailure(
        'Please Sign in to save artifacts to your account.'
      )
      return
    }

    this.changing = true
    const reload = async () =>
      this.$store
        .dispatch('artifact/loadSavedArtifacts')
        .then((res) => (this.changing = false))
    const handleError = () =>
      this.$eventBus.notifyFailure('Could not update saved artifacts.')

    if (this.saved) {
      return this.$store
        .dispatch('artifact/removeSavedArtifact', this.artifact.ID)
        .catch(handleError)
        .then(reload)
    } else {
      return this.$store
        .dispatch('artifact/addSavedArtifact', this.artifact.ID)
        .catch(handleError)
        .then(reload)
    }
  }

  get active() {
    return (this.saved && !this.changing) || (!this.saved && this.changing)
  }

  get saved() {
    try {
      return this.$store.state.artifact.saved_artifacts.includes(
        this.artifact.ID
      )
    } catch (err) {
      return false
    }
  }

  get isLoggedIn(): boolean {
    return this.$store.getters['customer/isLoggedIn']
  }

  get instruction() {
    return this.saved ? 'Remove saved item' : 'Save item'
  }

  mounted() {
    this.$emit('change', this.saved)
  }
}
