import BaseService from '~/services/base'
import * as basket from '~/api/queries/basket.gql'
import { CustomerAddress, PaymentInput } from '~/@types/skyway'

export default class BasketService extends BaseService {
  async getBasket(fetchPolicy = 'network-only') {
    const response = await this.client.query({
      query: basket['getBasket'],
      fetchPolicy,
    })

    return this.respond(response)
  }

  async getBasketProperties(fetchPolicy = 'network-only') {
    const response = await this.client.query({
      query: basket['getBasketProperties'],
      fetchPolicy,
    })

    const data = this.respond(response)

    if (!data) {
      this.hardRetry()
    }

    return data
  }

  async initiateCheckout(
    input: PaymentInput,
    recaptcha: string
  ): Promise<{ initiateCheckout: string }> {
    const response = await this.client.mutate({
      mutation: basket['initiateCheckout'],
      variables: {
        input,
        recaptcha,
      },
    })

    return this.respond(response)
  }

  async setBillingAddress(address: CustomerAddress): Promise<boolean> {
    if (address.address_ref) {
      const response = await this.client.mutate({
        mutation: basket['setBillingAddress'],
        variables: {
          id: address.address_ref,
        },
      })

      return response && response.data && response.data.setBillingAddress
    } else {
      return Promise.resolve(false)
    }
  }

  async setBasketNotes(notes: string): Promise<boolean> {
    const response = await this.client.mutate({
      mutation: basket['setBasketNotes'],
      variables: {
        notes,
      },
    })

    return this.respond(response)
  }

  async removeMembership(id: string) {
    const response = await this.client.mutate({
      mutation: basket['removeContribution'],
      variables: {
        id: parseInt(id),
      },
    })

    return this.respond(response)
  }

  async loadOrder(order_ref: string) {
    const response = await this.client.mutate({
      mutation: basket['loadOrder'],
      variables: {
        order_ref,
      },
    })

    return this.respond(response)
  }
}
