
import { Component, Vue, namespace } from 'nuxt-property-decorator'
import { ValidationObserver } from 'vee-validate'
import Modal from '~/components/ui/Modal.vue'
import FooterNewsletterOptions from '~/components/layout/footer/FooterNewsletterOptions.vue'
import TextField from '~/components/ui/fields/TextField.vue'

const customer = namespace('customer')

@Component({
  components: {
    Modal,
    TextField,
    FooterNewsletterOptions,
    ValidationObserver,
  },
})
export default class FooterNewsletter extends Vue {
  public emailAddress: string = ''

  public showNewsletter: boolean = false

  @customer.Getter
  isLoggedIn
}
