
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import { translateIn, translateOut, Watch } from '~/modules/helpers/translate'
import { throttle } from 'lodash'
import MetaNavPrimary from '~/components/menu/MetaNavPrimary.vue'
import MetaNavSecondary from '~/components/menu/MetaNavSecondary.vue'
import MetaNavItemDrawer from '~/components/menu/MetaNavItemDrawer.vue'

@Component({
  components: {
    MetaNavPrimary,
    MetaNavSecondary,
    MetaNavItemDrawer
  }
})
/**
 * Secondary menu items
 */
export default class MetaNav extends Vue {
  @Prop() header
  @Prop({ type: Number, default: 0 }) announcementHeight: number

  public shouldAnimate: boolean = true

  /**
   * Not the most rock solid approach.
   * The meta nav animation should be triggered when the user scrolls down the page
   * However, we are setting focus on route change back to the top of the page, which
   * triggers a scroll event and causes the animation to happen.
   *
   * We are setting a 5 second timeout on route change to prevent the animation
   */
  @Watch('$route.path')
  onPropertyChanged(newValue, oldValue) {
    this.shouldAnimate = false

    if (process.client) {
      setTimeout(() => {
        this.shouldAnimate = true
      }, 5000)
    }
  }

  public previousPosition = 0

  get isHomePage() {
    const post = this.$store.state.post

    return post.item && post.item.url ? post.item.url === '/' : false
  }

  addAnimations() {
    this.previousPosition = window.pageYOffset
    this.handleScroll = throttle(this.handleScroll, 500)
    if (process.client) {
      setTimeout(
        () => {
          window.addEventListener('scroll', this.handleScroll)
        },
        this.isHomePage ? 1300 : 0
      )
    }
  }

  handleScroll() {
    if (!this.shouldAnimate) {
      return false
    }
    const currentPosition = window.pageYOffset
    const metaNavHeight = this.$refs.list.clientHeight + this.announcementHeight
    if (currentPosition > this.previousPosition + metaNavHeight) {
      translateOut(this.header, 'y', '0', `-${metaNavHeight}px`)
      this.previousPosition = currentPosition
    }

    if (currentPosition < this.previousPosition - metaNavHeight) {
      translateIn(this.header, 'y', `-${metaNavHeight}px`, '0')
      this.previousPosition = currentPosition
    }
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  mounted() {
    this.addAnimations()
  }
}
