import { ActionTree, MutationTree, GetterTree, ActionContext } from 'vuex'
import { RootState, PrimaryNav, MetaNav } from './types'
import { config } from '~/config'

export const name = 'navigation'

export const types = {
  SET_META_NAV: 'SET_META_NAV',
  SET_PRIMARY_NAV: 'SET_PRIMARY_NAV'
}

export const namespaced = true

export interface State {
  metaNav: MetaNav
  primaryNav: PrimaryNav
}

export const state = (): State => ({
  metaNav: {
    primary: [
      { label: 'Opening times', datakey: 'opening_times', icon: 'SvgClock' },
      { label: 'Our venues', datakey: 'our_venues', icon: 'SvgLocation' },
      { label: 'On today', datakey: 'on_today', icon: 'SvgCalendar' },
      {
        label: 'My tickets & passes',
        datakey: 'tickets',
        icon: 'SvgLocalActivity'
      },
      { label: 'Donate', datakey: 'donate', icon: 'SvgDonate' }
    ],
    secondary: [
      { label: 'Lang', img: '/assets/images/sprites/language.svg' },
      { label: 'Sign In', img: '/assets/images/sprites/account.svg' },
      { label: 'Cart', img: '/assets/images/sprites/cart.svg' }
    ]
  },
  primaryNav: {
    items: []
  }
})

export const getters: GetterTree<State, RootState> = {}

export const actions: ActionTree<State, RootState> = {
  /**
   * Not using this just now, hardcoded meta nav into the state at the moment
   */
  async getMetaNav(context: ActionContext<State, RootState>): Promise<any> {
    const meta = await this.$axios
      .get(`${config.WORDPRESS_ENDPOINT}/menu/primary-nav`)
      .then((res: any) => res.data)

    context.commit(types.SET_META_NAV, meta)

    return meta
  },

  async getPrimaryNav(context: ActionContext<State, RootState>): Promise<any> {
    const primary = await this.$axios
      .get(`${config.WORDPRESS_ENDPOINT}/menu/primary-nav`)
      .then((res: any) => res.data)

    context.commit(types.SET_PRIMARY_NAV, primary.data)

    return primary
  }
}

const filterMenuItems = (items) => {
  const blacklist = ["Art Classes", "What's On"]
  return items.filter(item => !blacklist.includes(item.name));
}

export const mutations: MutationTree<State> = {
  [types.SET_META_NAV](state, payload: MetaNav) {
    state.metaNav = payload
  },
  [types.SET_PRIMARY_NAV](state, payload: PrimaryNav) {
    // payload.items = filterMenuItems(payload.items);
    state.primaryNav = payload
  }
}
