
import { Component, Vue, namespace } from 'nuxt-property-decorator'

import Logo from '~/components/Logo.vue'
import MainNavItem from '~/components/menu/MainNavItem.vue'
import SvgMenuOpen from '~/static/images/sprites/menu.svg'
import SvgMenuClose from '~/static/images/sprites/menu-close.svg'
import MetaNavSecondary from '~/components/menu/MetaNavSecondary.vue'
import SvgSearch from '~/static/images/sprites/search.svg'
import GlobalSearch from '~/components/search/GlobalSearch.vue'

const navigation = namespace('navigation')

@Component({
  components: {
    MainNavItem,
    Logo,
    SvgMenuOpen,
    SvgMenuClose,
    MetaNavSecondary,
    SvgSearch,
    GlobalSearch,
  },
  async fetch() {
    if (!this.items.length > 0) {
      await this.$store.dispatch('navigation/getPrimaryNav')
    }
  },
})
export default class MainNav extends Vue {
  public primaryNav

  @navigation.State
  private primaryNav

  /**
   * Initialise subMenuID, must be null.
   * Initialising it as undefined will mean it isn't reactive (quirk of Vue/Typescript)
   * Equivalent to the following in standard vue component
   *   data() {
   *    return {
   *     subMenuID: null
   *    }
   *   }
   */
  public subMenuID?: number | null = null
  public mobileNavOpen: boolean = false
  public handlersRegistered: boolean = false

  get items() {
    return this.primaryNav && this.primaryNav.items ? this.primaryNav.items : []
  }

  setSubMenu(value: number): void {
    this.subMenuID = value
  }

  openNavigation(id: number): boolean {
    return id === this.subMenuID
  }

  closeNavigation(): void {
    this.subMenuID = null
  }

  /**
   * Add a listener on the document body to detect when we hover off the menu
   * Adding ref="container" allows us to select the containing element via this.$refs
   */
  addBodyListener(): void {
    document.body.addEventListener('mouseover', (event) => {
      event.preventDefault()

      const container: any = this.$refs.container
      if (container) {
        if (!container.contains(event.target)) {
          this.closeNavigation()
        }
      }
    })
  }

  toggleMobileNav() {
    this.mobileNavOpen = !this.mobileNavOpen
    this.toggleBodyOverflow()
    this.$eventBus.$emit('toggle-mobile-nav', this.mobileNavOpen)
  }

  /**
   * Toggles the body overflow to remove scrolling on the page behind the mobile nav.
   */
  toggleBodyOverflow() {
    const body = document.querySelector('body')

    if (this.mobileNavOpen && body) {
      body.setAttribute('style', 'overflow: hidden;')
    } else {
      if (body) {
        body.removeAttribute('style')
      }
    }
  }

  openSearch() {
    this.$refs.globalSearch.openModal()
  }

  /**
   * Fire on mounted hook so we have the DOM ready
   */
  mounted(): void {
    if (!this.handlersRegistered) {
      this.addBodyListener()
      this.$eventBus.$on('mobile-nav:click-item', () => this.toggleMobileNav())
      this.handlersRegistered = true
    }
  }
}
