
import { Component, Prop, Vue, Watch, namespace } from 'nuxt-property-decorator'
import { types } from '~/store/options'
import { throttle } from 'lodash'
import md5 from 'md5'
import CloseSvg from '~/static/images/sprites/menu-close.svg'

const options = namespace('options')

@Component({
  components: {
    CloseSvg
  }
})
export default class AnnouncementBanner extends Vue {
  @Prop({
    type: Object,
    default: { heading: '', text: '', link: { url: '', title: '' } }
  })
  data?: any

  @options.State
  announcementHash

  get textFormatted() {
    return `<strong>${this.heading}</strong> ${this.text}`
  }

  get heading() {
    return this.data.heading || ''
  }

  get text() {
    return this.data.text || ''
  }

  get link() {
    return this.data.link || { url: '', title: '' }
  }

  get hash() {
    return this.data.text ? md5(this.data.text) : ''
  }

  onClickDismiss(): void {
    this.$store.commit(`options/${types.SET_ANNOUNCEMENT_STATE}`, false)
    this.$store.commit(`options/${types.SET_ANNOUNCEMENT_HASH}`, this.hash)
  }

  handleResize() {
    this.$emit('announcement:height', this.$refs.banner.clientHeight)
  }

  mounted() {
    this.handleResize = throttle(this.handleResize, 500)
    window.addEventListener('resize', this.handleResize)
    this.$emit('announcement:height', this.$refs.banner.clientHeight)
    this.$nextTick(() => {
      if (this.hash != this.announcementHash) {
        this.$store.commit(`options/${types.SET_ANNOUNCEMENT_STATE}`, true)
      }
    })
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  }
}
