export type ConfigOptions = {
  TIMEZONE: string
  GRAPHQL_ENDPOINT: string
  QRCODE_ENDPOINT: string
  PDF_ENDPOINT: string
  WORDPRESS_ENDPOINT: string
  TRANSLATION_ENDPOINT: string
  PAGEINTRO_ENDPOINT: string
  ELASTICSEARCH_ENDPOINT: string
  ELASTICSEARCH_INDEX_PREFIX: string
  CURRENCY_SYMBOL: string
  ASSET_PATH: string
  CURRENCY: string
  LOCALE: string
  HOST: string
  PORT: string
  APP_URL: string
  PARAMETERS: object
  DEFAULT_ADDRESS: {
    street1: string
    street2: string
    city: string
    postal_code: string
    country_ref: number
  }
  GOOGLE_CLIENT_ID: string
  GOOGLE_RECAPTCHA_CLIENT_ID: string
  GOOGLE_TAG_MANAGER: string
  URLS: object
  DONATION_FUND_REF: number
  TESSITURA_VERSION: any
  MAINTENANCE_MODE_PAGE: string
}

export const config: ConfigOptions = {
  TIMEZONE: 'Pacific/Honolulu',
  GRAPHQL_ENDPOINT: `${process.env.skywayEndpoint}`,
  QRCODE_ENDPOINT: `${process.env.qrEndpoint}`,
  PDF_ENDPOINT: `${process.env.pdfEndpoint}`,
  WORDPRESS_ENDPOINT: `${process.env.wordpressEndpoint}`,
  TRANSLATION_ENDPOINT: `${process.env.wordpressEndpoint}/translations`,
  PAGEINTRO_ENDPOINT: `${process.env.pageIntroEndpoint}`,
  CURRENCY_SYMBOL: '$',
  ASSET_PATH: `${process.env.assetPath}`,
  CURRENCY: `${process.env.curreny}`,
  LOCALE: `${process.env.locale}`,
  HOST: `${process.env.HOST}`,
  PORT: `${process.env.PORT}`,
  APP_URL: `${process.env.APP_URL}`,
  ELASTICSEARCH_ENDPOINT: `${process.env.ELASTICSEARCH_ENDPOINT}`,
  ELASTICSEARCH_INDEX_PREFIX: `${process.env.ELASTICSEARCH_INDEX_PREFIX}`,
  DEFAULT_ADDRESS: {
    street1: '900 S Beretania St',
    street2: '',
    city: 'Honolulu',
    postal_code: 'SE1 8XX',
    country_ref: 1,
  },
  PARAMETERS: {
    GIFT_MEMBERSHIP_PAYMENT_METHOD_REF: 102,
    GIFT_VOUCHER_PAYMENT_METHOD_REF: 102,
  },
  GOOGLE_CLIENT_ID: `${process.env.google.clientId}`,
  GOOGLE_RECAPTCHA_CLIENT_ID: `${process.env.google.recaptchaClientId}`,
  GOOGLE_TAG_MANAGER: `${process.env.google.tagManagerId}`,
  DONATION_FUND_REF: 3,
  TESSITURA_VERSION: process.env.TESSITURA_VERSION || 15,
  URLS: {
    home: '/',
    whats_on: '/events/',
    tickets: '/purchase/tickets/',
    best_available: '/best-available/',
    memberships: '/join-give/membership/',
    donations: '/join-give/donate/',
    forgot_password: '/forgotten-password/',
    gift_vouchers: '/purchase/gifts/voucher/',
    gift_memberships: '/purchase/gifts/membership/',
    sign_in: '/sign-in/',
    basket: '/cart/',
    checkout: '/purchase/checkout/',
    account: '/account/',
    register: '/register/',
    payment: '/purchase/checkout/payment/',
    success: '/purchase/checkout/success/',
    adult_classes: '/adult-classes/',
    youth_classes: '/youth-classes/',
    field_trips: '/field-trips/',
    forms: '/forms/',
  },
  MAINTENANCE_MODE_PAGE: '/maintenance/',
}
