import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex'

import { RootState } from './types'
import * as memberships from '~/api/queries/membership.gql'
import { Membership } from './types'

export const name = 'memberships'

export const namespaced = true

export const types = {
  SET_MEMBERSHIPS: 'SET_MEMBERSHIPS'
}

export interface State {
  memberships?: Array<Membership>
}

/**
 * Initial state, empty array of events
 */
export const state = (): State => ({
  memberships: []
})

export const getters: GetterTree<State, RootState> = {}

export const actions: ActionTree<State, RootState> = {
  async getMemberships(context: ActionContext<State, RootState>): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: memberships['getMemberships']
    })

    const { data } = response

    context.commit(types.SET_MEMBERSHIPS, data.getMemberships)
    return data.getMemberships
  },

  async getMembershipByRef(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: memberships['getMembershipByRef'],
      variables: {
        ref: id
      }
    })

    const { data } = response

    return data.getMembershipByRef
  },

  async addMembership(
    context: ActionContext<State, RootState>,
    input
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: memberships['addMembership'],
      variables: {
        fund_ref: parseInt(input.fund_ref),
        amount: parseFloat(input.start_amount),
        level_ref: parseInt(input.level_ref)
      }
    })

    const { data } = response

    // when a membership is added to the basket we check to see if this should change the MoS
    const membership = await this.dispatch(
      'memberships/getMembershipByRef',
      parseInt(input.level_ref)
    )

    if (
      membership &&
      membership.mode_of_sale_ref != undefined &&
      membership.mode_of_sale_ref != null
    ) {
      this.app.$eventBus.emit(
        'update_mode_of_sale',
        membership.mode_of_sale_ref
      )
    }

    return data.addMembership
  },

  async renewMembership(
    context: ActionContext<State, RootState>,
    input
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: memberships['addMembership'],
      variables: {
        fund_ref: parseInt(input.fund_ref),
        amount: input.amount,
        level_ref: parseInt(input.level_ref),
        renew: true
      }
    })

    const { data } = response

    return data.addMembership
  },

  async upgradeMembership(
    context: ActionContext<State, RootState>,
    input
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: memberships['addMembership'],
      variables: {
        fund_ref: parseInt(input.fund_ref),
        amount: input.amount,
        level_ref: parseInt(input.level_ref),
        upgrade: true
      }
    })

    const { data } = response

    return data.addMembership
  },

  async addGiftMembership(
    context: ActionContext<State, RootState>,
    input
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: memberships['addGiftMembership'],
      variables: {
        amount: input.start_amount,
        name: input.name,
        notes: input.notes
      }
    })

    const { data } = response

    return data.addMembership
  }
}

export const mutations: MutationTree<State> = {
  [types.SET_MEMBERSHIPS](state: State, payload: Array<Membership>): void {
    if (payload) {
      payload.sort((a, b) => {
        if (a.start_amount > b.start_amount) return 1
        if (a.start_amount < b.start_amount) return -1
        return 0
      })
    }
    state.memberships = payload
  }
}
