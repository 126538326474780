
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class UIRadioButton extends Vue {
  @Prop() isChecked!: boolean
  @Prop() id!: string
  @Prop() name!: string
  @Prop() label!: string
  @Prop() data!: string
  @Prop() value!: any
}
