import VuexPersistence from 'vuex-persist'

export default ({ store }) => {
  new VuexPersistence({
    storage: window.localStorage, // or window.sessionStorage or localForage instance.
    // // Function that passes the state and returns the state with only the objects you want to store.
    reducer: (state) => ({
      customer: {
        logged_in: state.customer.logged_in,
        token: state.customer.token,
        maliciousActivity: state.customer
          ? state.customer.maliciousActivity
          : false,
      },
      forms: state.forms,
      gifts: {
        gift_memberships: state.gifts.gift_memberships,
        gift_vouchers: state.gifts.gift_vouchers,
      },
      selection: {
        zone: state.selection.zone,
      },
      options: {
        showAnnouncement: state.options.showAnnouncement,
        announcementHash: state.options.announcementHash,
      },
    }),
  }).plugin(store)
}
