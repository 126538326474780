
import { Component, Vue } from 'nuxt-property-decorator'
import SvgLogo from '~/static/images/logo-2line.svg'

@Component({
  components: {
    SvgLogo,
  },
})
export default class FooterLogo extends Vue {}
