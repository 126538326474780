
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { MembershipInstance, Customer } from '~/@types/skyway'
import { createPDF } from '~/modules/helpers/createPDF'

@Component
export default class AccountMembershipInfo extends Vue {
  @Prop(Object) membership?: MembershipInstance
  @Prop(Object) customer?: Customer
  @Prop() sidebar?: boolean

  protected downloading: boolean = false

  get type(): Membership {
    return this.membership ? this.membership.membership_type : {}
  }

  get qrcodeImage() {
    return `${this.$config.get('QRCODE_ENDPOINT')}/C${
      this.customer.customer_ref.padStart(9, '0')
    }/400`
  }

  get inceptionDate() {
    return this.$moment(this.membership.dates.inception)
  }

  get inceptionDateFormatted() {
    return this.inceptionDate.format('MMM D YYYY')
  }

  get expiryDate() {
    return this.$moment(this.membership.dates.expiration)
  }

  get expiryDateFormatted() {
    return this.expiryDate.format('MMM D YYYY')
  }

  get renewalDate() {
    return this.$moment(this.membership.dates.renewal).format('MMM D YYYY')
  }

  get downloadPassBtnTxt() {
    return this.downloading ? this.$t('Downloading...') : this.$t('Download pdf pass')
  }

  async membershipPassHTML() {
    const template = await this.$store.dispatch('customer/getCustomerDetailsHtml')
    const customer_name_regex = new RegExp(`${this.customer.first_name}, `)
    const qrcode = `<img src="${this.qrcodeImage}" />`;
    const membershipNumber = `Member No: ${this.customer.customer_ref}`

    return template
      .replace(/<h2>HoMA Constituent Info<\/h2>/, qrcode)
      .replace(/Please review the information below to ensure accuracy\./, membershipNumber)
      .replace(customer_name_regex, '')
  }

  async sendToCreatePDF() {
    this.downloading = true
    
    const html = await this.membershipPassHTML()
    return createPDF(this, html, 'Membership Pass').then(res => {
      this.downloading = false
      return res
    })
  }
}
