
import { Component, Vue } from 'nuxt-property-decorator'
import MetaNavItem from '~/components/menu/MetaNavItem.vue'
import { translateIn, translateOut } from '~/modules/helpers/translate'
import { mapState } from 'vuex'
import { throttle } from 'lodash'
import SvgSearch from '~/static/images/sprites/search.svg'
import GlobalSearch from '~/components/search/GlobalSearch.vue'

@Component({
  components: {
    MetaNavItem,
    SvgSearch,
    GlobalSearch
  },
  computed: mapState('navigation', ['metaNav'])
})
export default class MetaNavPrimary extends Vue {
  openSearch() {
    if (this.$refs.globalSearch) {
      this.$refs.globalSearch.openModal()
    }
  }

  public previousPosition = 0

  addAnimations() {
    this.previousPosition = window.pageYOffset
    this.handleScroll = throttle(this.handleScroll, 500)
    window.addEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    if (this.$mq !== 'sm') {
      return
    }
    const currentPosition = window.pageYOffset
    const metaNavHeight = this.$refs.list.clientHeight

    if (currentPosition > this.previousPosition + 100) {
      translateOut(this.$refs.container, 'y', '0', `${metaNavHeight}px`)
      this.previousPosition = currentPosition
    }

    if (currentPosition < this.previousPosition - 500) {
      translateIn(this.$refs.container, 'y', `${metaNavHeight}px`, '0')
      this.previousPosition = currentPosition
    }
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  mounted() {
    this.addAnimations()
  }
}
