
import { Component, Vue } from 'nuxt-property-decorator'
import { Facility } from '~/@types/skyway'
import Loader from '~/components/ui/Loader.vue'
import OurVenuesDirectionsLink from '~/components/flexible/our-venues/OurVenuesDirectionsLink.vue'

@Component({
  components: {
    Loader,
    OurVenuesDirectionsLink
  }
})
export default class DrawerContentOurVenues extends Vue {
  public loading: boolean = false

  getVenues(): void {
    this.loading = true
    this.$store
      .dispatch('venues/getFacilities')
      .then(() => {
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }

  get venues(): Array<Facility> {
    return this.$store.getters['venues/getVenues']
  }

  created(): void {
    this.getVenues()
  }
}
