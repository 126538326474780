
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Customer } from '~/@types/skyway'
import AccountLogout from '~/components/account/AccountLogout.vue'

@Component({
  components: {
    AccountLogout
  }
})
export default class AccountHeader extends Vue {
  @Prop({ type: Object }) customer?: Customer
}
