
import { Component, Vue } from 'nuxt-property-decorator'
import DonationSelector from '~/components/donations/DonationSelector.vue'

@Component({
  components: {
    DonationSelector
  }
})
export default class DrawerContentDonate extends Vue {}
