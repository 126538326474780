
import { Vue, Component, Prop } from 'vue-property-decorator'
import SvgLocation from '~/static/images/sprites/location.svg'
import SvgClock from '~/static/images/sprites/clock.svg'
import SvgCalendar from '~/static/images/sprites/calendar.svg'
import SvgMember from '~/static/images/sprites/member.svg'
import ArtifactSaveButton from '~/components/artifact/ArtifactSaveButton.vue'

@Component({
  components: {
    ArtifactSaveButton,
    SvgLocation,
    SvgClock,
    SvgCalendar,
    SvgMember
  }
})
export default class ArtifactCard extends Vue {
  @Prop(Object) artifact!: object

  onClickCard(): void {
    this.$router.push(this.link)
  }

  get link() : string {
    return `/collections/${this.artifact.ID}/`
  }

  get date() {
    try {
      return this.artifact.meta.displayDate[0].raw
    } catch (err) {
      return ''
    }
  }

  get category() {
    try {
      return this.artifact.collection.name
    } catch (err) {
      return 'Uncategorized'
    }
  }

  /**
   * Lorem image for now if the artifact has nothing uploaded
   */
  get imageStyle(): string {
    try {
      return `background-image: url('${this.artifact.image}');`
    } catch (err) {
      return `background-image: url('')`
    }
  }
}
