
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class TicketItem extends Vue {
  @Prop({ type: Object }) ticket?: any

  get date() {
    return this.$moment(this.ticket.date).format('MMM D')
  }

  get details() {
    return this.ticket.details || []
  }
}
