import { Vue } from 'nuxt-property-decorator'
import { GetterTree, MutationTree } from 'vuex'

import { RootState } from '../types'

import {
  Order,
  Customer,
  CustomerAttribute,
  CustomerContribution,
  MembershipInstance,
  CustomerContactPreference,
} from '~/@types/skyway'
import actions from './actions'

const name = 'customer'

const namespaced = true

const types = {
  SET_KNOWN_USERS: 'SET_KNOWN_USERS',
  SET_LOGGED_IN: 'SET_LOGGED_IN',
  SET_TOKEN: 'SET_TOKEN',
  SET_CUSTOMER: 'SET_CUSTOMER',
  SET_TICKET_HISTORY: 'SET_TICKET_HISTORY',
  SET_ORDER_HISTORY: 'SET_ORDER_HISTORY',
  SET_CONTRIBUTION_HISTORY: 'SET_CONTRIBUTION_HISTORY',
  SET_LATEST_ORDER_ID: 'SET_LATEST_ORDER_ID',
  SET_MEMBERSHIP_HISTORY: 'SET_MEMBERSHIP_HISTORY',
  TRANSFER_SESSION_FAILED: 'TRANSFER_SESSION_FAILED',
  LOGOUT: 'LOGOUT',
  SET_TEMPORARY_EMAIL: 'SET_TEMPORARY_EMAIL',
  CLEAR_TEMPORARY_EMAIL: 'CLEAR_TEMPORARY_EMAIL',
  SET_CUSTOMER_CONTACT_DETAILS: 'SET_CUSTOMER_CONTACT_DETAILS',
  SET_CUSTOMER_INTERESTS: 'SET_CUSTOMER_INTERESTS',
  SET_CUSTOMER_ATTRIBUTES: 'SET_CUSTOMER_ATTRIBUTES',
  SET_CUSTOMER_CONSTITUENCIES: 'SET_CUSTOMER_CONSTITUENCIES',
  SET_CUSTOMER_BALANCES: 'SET_CUSTOMER_BALANCES',
  SET_CUSTOMER_MEMBERSHIPS: 'SET_CUSTOMER_MEMBERSHIPS',
  SET_CUSTOMER_GIFT_AID: 'SET_CUSTOMER_GIFT_AID',
  SET_CUSTOMER_CONTACT_PREFERENCES: 'SET_CUSTOMER_CONTACT_PREFERENCES',
  UPDATE_CUSTOMER_CONTACT_PREFERENCES: 'UPDATE_CUSTOMER_CONTACT_PREFERENCES',
  SET_MALICIOUS_ACTIVITY_FLAG: 'SET_MALICIOUS_ACTIVITY_FLAG',
}

export interface State {
  logged_in?: boolean
  token?: string
  known_users: Set<string>
  customer?: Customer
  orders?: Array<Order>
  tickets?: Array<Order>
  contributions?: Array<CustomerContribution>
  temporary_email?: string
  membership_history?: Array<MembershipInstance>
  latest_order_id: Number
  maliciousActivity: boolean
}

/**
 *
 */
const state = (): State => ({
  logged_in: false,
  token: undefined,
  known_users: new Set(),
  customer: undefined,
  orders: [],
  tickets: [],
  contributions: [],
  temporary_email: undefined,
  membership_history: [],
  latest_order_id: 0,
  maliciousActivity: false,
})

const getters: GetterTree<State, RootState> = {
  token: (state: State): string | undefined => {
    return state.token
  },

  isLoggedIn: (state: State): boolean => {
    return state.logged_in == true
  },
  displayName: (state: State): string | undefined | null => {
    if (state.customer && state.customer.customer_ref) {
      return state.customer.first_name
        ? state.customer.first_name
        : state.customer.display_name
    } else {
      return ''
    }
  },
  customer: (state: State): Customer | undefined => {
    return state.customer ? state.customer : undefined
  },
  attributes: (state: State): CustomerAttribute[] => {
    return (state.customer && state.customer.attributes) || []
  },
  customerRef: (state: State): Customer | undefined => {
    return state.customer && state.customer.customer_ref
      ? state.customer.customer_ref
      : undefined
  },
  updatedAt: (state: State): string | undefined => {
    return state.customer && state.customer.customer_ref
      ? state.customer.updated_at
      : undefined
  },
  isMember: (state: State): boolean => {
    return state.customer && state.customer.customer_ref
      ? state.customer.memberships.length > 0
      : false
  },
  orders: (state: State): [] => {
    return state.customer && state.customer.orders ? state.customer.orders : []
  },
  interests: (state: State): [] => {
    return state.customer && state.customer.interests
      ? state.customer.interests
      : []
  },
  preferences: (state: State): [] => {
    return (state.customer && state.customer.preferences) || []
  },
  tickets: (state: State): [] => {
    return state.customer && state.customer.tickets
      ? state.customer.tickets
      : []
  },
  addresses: (state: State): [] => {
    return state.customer && state.customer.addresses
      ? state.customer.addresses
      : []
  },
  maliciousActivity: (state: State): boolean => {
    return state.maliciousActivity
  },
}

const mutations: MutationTree<State> = {
  [types.SET_KNOWN_USERS](state: State, knownUsers: string): void {
    state.known_users = new Set(knownUsers)
  },

  [types.SET_LOGGED_IN](state: State, status: boolean): void {
    state.logged_in = status
  },

  [types.SET_TOKEN](state: State, token: string): void {
    state.token = token
  },

  [types.SET_CUSTOMER](state: State, payload: Customer): void {
    state.customer = payload
    if (!payload.tickets) {
      state.customer && Vue.set(state.customer, 'tickets', [])
    } else {
      state.customer && Vue.set(state.customer, 'tickets', [])
      Vue.set(
        state.customer,
        'tickets',
        payload.tickets.sort((a, b) => {
          if (a && b) {
            if (a.date > b.date) return 1
            if (a.date < b.date) return -1
          }
          return 0
        })
      )
    }
  },

  [types.SET_TICKET_HISTORY](state: State, payload: any): void {
    if (payload && state.customer) {
      const tix = payload.tickets.sort((a, b) => {
        if (a.date > b.date) return 1
        if (a.date < b.date) return -1
        return 0
      })
      state.customer.tickets = tix
      state.tickets = tix
    }
  },

  [types.SET_CONTRIBUTION_HISTORY](state: State, payload: any): void {
    if (payload && state.customer) {
      state.customer.contributions = payload.contributions
      state.contributions = payload.contributions
    }
  },

  [types.SET_ORDER_HISTORY](state: State, orders: any): void {
    if (orders && state.customer) {
      state.customer.orders = orders
      state.orders = orders
    }
  },

  [types.SET_LATEST_ORDER_ID](state: State, orderId: any): void {
    state.latest_order_id = orderId
  },

  [types.SET_MEMBERSHIP_HISTORY](state: State, payload: any): void {
    state.membership_history = payload
  },

  [types.LOGOUT](state: State): void {
    state.logged_in = false
    state.token = undefined
    state.customer = undefined
  },

  [types.TRANSFER_SESSION_FAILED](state: State): void {
    state.customer = undefined
  },

  /**
   * Temporary email is committed when someone tries to sign in
   * with an unregistered email and is redirected to the register form
   */
  [types.SET_TEMPORARY_EMAIL](state: State, payload: string): void {
    state.temporary_email = payload
  },

  [types.CLEAR_TEMPORARY_EMAIL](state: State): void {
    state.temporary_email = undefined
  },

  [types.SET_CUSTOMER_CONTACT_DETAILS](state: State, payload: Customer): void {
    if (payload && payload.addresses) {
      if (state.customer == undefined) state.customer = {}
      state.customer.addresses = payload.addresses
      state.customer.billing_address = payload.billing_address
      state.customer.phones = payload.phones
      state.customer.emails = payload.emails
    }
  },

  [types.SET_CUSTOMER_INTERESTS](state: State, payload: Customer): void {
    if (payload && payload.interests) {
      if (state.customer == undefined) state.customer = {}
      state.customer.interests = payload.interests
    }
  },

  [types.SET_CUSTOMER_ATTRIBUTES](state: State, payload: Customer): void {
    if (payload && payload.attributes) {
      if (state.customer == undefined) state.customer = {}
      Vue.set(state.customer, 'attributes', payload.attributes)
    }
  },

  [types.SET_CUSTOMER_CONSTITUENCIES](state: State, payload: Customer): void {
    if (payload && payload.constituencies) {
      if (state.customer == undefined) state.customer = {}
      state.customer.constituencies = payload.constituencies
    }
  },

  [types.SET_CUSTOMER_BALANCES](state: State, payload: Customer): void {
    if (payload && payload.balances) {
      if (state.customer == undefined) state.customer = {}
      state.customer.balances = payload.balances
    }
  },

  [types.SET_CUSTOMER_MEMBERSHIPS](state: State, payload: Customer): void {
    if (payload && payload.memberships) {
      if (state.customer == undefined) state.customer = {}
      Vue.set(state.customer, 'memberships', payload.memberships)
    }
  },

  [types.SET_CUSTOMER_GIFT_AID](state: State, payload: string): void {
    if (payload && payload.giftaid) {
      if (state.customer == undefined) state.customer = {}
      state.customer.giftaid = payload.giftaid
    }
  },

  [types.SET_CUSTOMER_CONTACT_PREFERENCES](
    state: State,
    payload: Customer
  ): void {
    if (payload && payload.preferences) {
      if (state.customer == undefined) state.customer = {}
      Vue.set(state.customer, 'preferences', payload.preferences)
    }
  },

  [types.UPDATE_CUSTOMER_CONTACT_PREFERENCES](
    state: State,
    payload: CustomerContactPreference[]
  ): void {
    if (payload) {
      if (state.customer == undefined) state.customer = {}
      Vue.set(state.customer, 'preferences', payload)
    }
  },

  [types.SET_MALICIOUS_ACTIVITY_FLAG](state: State, payload: boolean): void {
    state.maliciousActivity = payload
  },
}

export { actions, name, namespaced, state, getters, mutations, types }
