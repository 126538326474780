import Vue from 'vue'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

Vue.$cookies.config('7d', '/', `.${window.location.hostname.replace(/tickets\./g, '')}`, false, false) 
const cookies = Vue.$cookies

export default ({ app }, inject) => {
  inject('cookies', cookies)
}
