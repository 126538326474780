export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: true,
}
export const options = {
  vueI18n: {"fallbackLocale":"en","messages":{},"silentTranslationWarn":true,"silentFallbackWarn":true,"warnHtmlInMessage":"off","dateTimeFormats":{"en":{"day":{"weekday":"long"},"on_today_drawer":{"year":"numeric","month":"short","day":"numeric","weekday":"short"}},"ja":{"day":{"weekday":"short"},"on_today_drawer":{"year":"numeric","month":"short","day":"numeric","weekday":"short"}}}},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en","name":"EN"},{"code":"ja","iso":"ja","name":"JP"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: false,
  langDir: null,
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_language","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en","name":"EN"},{"code":"ja","iso":"ja","name":"JP"}],
  localeCodes: ["en","ja"],
}

export const localeMessages = {}
