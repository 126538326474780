export default ({ app }) => {
    app.$moment.updateLocale('en', {
        longDateFormat : {
            LT : 'h:mma',
            LTS : 'H:mm',
            L : 'DD/MM/YYYY',
            l : 'DD/MM/YY',
            LL : 'MMMM D YYYY',
            ll : 'MMM D YY',
            LLL : 'Do MMMM, YYYY',
            lll : 'Do MMM, YYYY',
            LLLL : 'dddd Do MMMM, YYYY',
            llll : 'ddd D MMM, YYYY'
        }
    })

    app.$moment.updateLocale('ja', {
        longDateFormat : {
            LT : 'h:mma',
            LTS : 'H:mm',
            L : 'YYYY/MM/DD',
            l : 'YY/MM/DD',
            LL : 'YYYY年M月D日',
            ll : 'YY年M月D日',
            LLL : 'YYYY年M月D日',
            lll : 'YYYY年M月D日',
            LLLL : 'YYYY年M月D日 dddd',
            llll : 'YYYY年M月D日(ddd)'
        }
    })
}