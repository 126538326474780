import stickybits from 'stickybits'

/**
 * Initialise stickybits for position: sticky polyfill
 * Should only be called in mounted hook as we must have
 * the window object available
 *
 * @param HTMLElement - the DOM element
 * @param Object - Breakpoints and offsets in format { 0: 119, 640: 155 }
 *
 * @return Function - initialise stickybits
 */
export const stickybitsInit = (selector, breakpoints = null) => {
  if (!breakpoints) return stickybits(selector)

  const _breakpoints = Object.keys(breakpoints)

  const innerWidth = window.innerWidth

  let currentOffset

  for (let i = 0; i <= _breakpoints.length; i++) {
    if (innerWidth > _breakpoints[i]) {
      currentOffset = Object.values(breakpoints)[i]
    }
  }

  if (!currentOffset) return

  return stickybits(selector, {
    stickyBitStickyOffset: currentOffset,
    useStickyClasses: true
  })
}
