
import { Component, Vue } from 'nuxt-property-decorator'
import { mapState } from 'vuex'
import MobileNavItem from './MobileNavItem.vue'
import SvgLanguage from '~/static/images/sprites/language.svg'
import MobileLanguageSwitcher from '~/components/language/MobileLanguageSwitcher.vue'

@Component({
  components: {
    MobileNavItem,
    SvgLanguage,
    MobileLanguageSwitcher
  },
  computed: mapState('navigation', ['primaryNav'])
})
export default class MobileNav extends Vue {
  public primaryNav
  public headerHeight: number | null = null
  public mobileNavOpen: Boolean = false
  public handlersRegistered: Boolean = false

  getHeaderHeight() {
    const header = document.querySelector('.header')

    if (header) {
      const height = header.clientHeight
      this.headerHeight = height
    }
    return
  }

  registerHandlers() {
    if (!this.handlersRegistered) {
      //Receives toggle mobile nav from Main Nav.
      this.$eventBus.$on('toggle-mobile-nav', (mobileNavOpen) => {
        this.mobileNavOpen = mobileNavOpen
        this.getHeaderHeight()
      })

      this.handlersRegistered = true
    }
  }

  onClickOutside(event) {
    const menu = this.$refs.navContainer

    if (this.mobileNavOpen && menu && !menu.contains(event.target)) {
      this.$eventBus.$emit('mobile-nav:click-item')
    }
  }

  mounted() {
    this.getHeaderHeight()
    this.registerHandlers()
  }

  beforeDestroy() {
    const overlay = this.$refs.overlay
    if (overlay) {
      overlay.removeEventListener('click', this.onClickOutside)
    }
  }
}
