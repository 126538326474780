import { ActionTree, MutationTree, GetterTree, ActionContext } from 'vuex'
import { config } from '~/config'

export const name = 'session'

import { RootState } from './types'

export const namespaced = true

export const types = {
  SET_REDIRECT: 'SET_REDIRECT',
  SET_AUTO_DONATION_APPLIED: 'SET_AUTO_DONATION_APPLIED',
  SET_AUTO_DONATION_REFUSED: 'SET_AUTO_DONATION_REFUSED',
  SET_AUTO_DONATION_BASKET_ID: 'SET_AUTO_DONATION_BASKET_ID',
  SET_SEAT_MAP_INSTRUCTIONS: 'SET_SEAT_MAP_INSTRUCTIONS',
  SET_MALICIOUS_ACTIVITY_FLAG: 'SET_MALICIOUS_ACTIVITY_FLAG',
  SET_SSR_IP: 'SET_SSR_IP',
}

export interface State {
  redirect: string
  autoDonationApplied?: boolean
  autoDonationRefused?: boolean
  autoDonationBasketId?: number
  seatMapInstructionsDismissed?: boolean
  maliciousActivity: boolean
  ssrIp?: string
}

/**
 * Initial state, empty array of events
 */
export const state = (): State => ({
  redirect: config.URLS.account,
  autoDonationApplied: false,
  autoDonationRefused: false,
  autoDonationBasketId: 0,
  seatMapInstructionsDismissed: false,
  maliciousActivity: false,
  ssrIp: undefined,
})

export const getters: GetterTree<State, RootState> = {
  maliciousActivity: (state: State): boolean => {
    return state.maliciousActivity
  },
}

export const actions: ActionTree<State, RootState> = {
  async setRedirect(
    context: ActionContext<State, RootState>,
    redirect: string
  ): Promise<boolean> {
    return new Promise((resolve) => {
      context.commit(types.SET_REDIRECT, redirect)
      resolve(true)
    })
  },

  async getRedirect(context: ActionContext<State, RootState>): Promise<string> {
    return new Promise((resolve) => {
      resolve(context.state.redirect)
    })
  },

  async getAutoDonationBasketId(
    context: ActionContext<State, RootState>
  ): Promise<number> {
    return new Promise((resolve) => {
      resolve(context.state.autoDonationBasketId)
    })
  },

  async flagMaliciousActivity(
    context: ActionContext<State, RootState>
  ): Promise<boolean> {
    return new Promise((resolve) => {
      context.commit(types.SET_MALICIOUS_ACTIVITY_FLAG, true)
      resolve(true)
    })
  },
}

export const mutations: MutationTree<State> = {
  [types.SET_REDIRECT](state: State, payload: string): void {
    localStorage.setItem('redirect', payload) // safety until we can migrate to using the store throughout
    state.redirect = payload
  },
  [types.SET_AUTO_DONATION_APPLIED](state: State, payload: boolean): void {
    state.autoDonationApplied = payload
  },
  [types.SET_AUTO_DONATION_REFUSED](state: State, payload: boolean): void {
    state.autoDonationRefused = payload
  },
  [types.SET_AUTO_DONATION_BASKET_ID](state: State, payload: number): void {
    state.autoDonationBasketId = payload
  },
  [types.SET_SEAT_MAP_INSTRUCTIONS](state: State, payload: boolean): void {
    state.seatMapInstructionsDismissed = payload
  },
  [types.SET_MALICIOUS_ACTIVITY_FLAG](state: State, payload: boolean): void {
    state.maliciousActivity = payload
  },
  [types.SET_SSR_IP](state: State, payload: string): void {
    state.ssrIp = payload
  },
}
