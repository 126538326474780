
import { Component, Vue } from 'nuxt-property-decorator'
import { Facility } from '~/@types/skyway'
import Loader from '~/components/ui/Loader.vue'
import DrawerContentOurVenues from '~/components/menu/drawers/DrawerContentOurVenues.vue'

@Component({
  components: {
    Loader
  }
})
export default class DrawerContentOpeningTimes extends DrawerContentOurVenues {
  
  translateTimes(times: string): string {
    return times.replace(/\d+/g, (matched, index, original) => {
      return this.$n(matched)  
    })
  }
}
