
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class MainNavItem extends Vue {
  @Prop({ type: Object }) item?: any
  @Prop({ type: Boolean }) subMenuOpen?: boolean

  get classList(): string {
    return this.hasChildren
      ? `main-menu__list-item main-menu__list-item--has-children`
      : `main-menu__list-item`
  }

  get hasChildren(): boolean {
    return this.item.children.length > 0
  }

  /**
   * Fire mouse over event
   * Try to follow kebab case convention component:event
   */
  mouseOver(): void {
    this.$emit('menu-item:mouse-over', this.item.id)
  }
}
