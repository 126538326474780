
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import { stickybitsInit } from '~/modules/stickybits/stickybits'
import MenuSvg from '~/static/images/sprites/menu.svg'

export interface ITab {
  name: string
  label: string
}

@Component({
  components: {
    MenuSvg,
  },
})
export default class AccountTabs extends Vue {
  @Prop({ type: Array }) tabs?: ITab[]
  @Prop({ type: Object }) defaultSelection?: ITab

  public selected?: ITab
  public mobileTabsOpen?: boolean = false

  constructor() {
    super()
    this.selected = this.defaultSelection
  }

  @Watch('$route')
  onPropertyChanged() {
    this.mobileTabsOpen = false
  }

  get showMobileTrigger() {
    return this.$mq !== 'lg' && this.$mq !== 'xl'
  }

  onClickToggleTabs() {
    this.mobileTabsOpen = !this.mobileTabsOpen
  }

  onClickTab(tab?: ITab): void {
    this.selected = tab
    this.$emit('account:tab-select', tab)
  }

  mounted() {
    const container = this.$refs.container
    if (container) {
      const accountMenu = stickybitsInit(container, {
        0: 71,
        640: 99,
      })
    }
  }
}
