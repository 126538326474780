// /**
//  * animates an object with translate
//  * @function
//  * @param {Object} element - the element to animate
//  * @param {String} direction - accepts Y and X
//  * @param {String} start - the elements position before animating in
//  * @param {String} end - the elements position after animating in
//  * @param {Number} delayIn - animation delay on animating in
//  * @param {Number} delayOut - animation delay on animating out
//  * @example
//  * // Get your element
//  * const metanav = document.querySelector('[data-metanav]')
//  *
//  * // Translates your element on a Y axis, from a starting point of 100% to an ending point of 0, with a 2000ms delay
//  * translateIn(metanav, 'y', '100%', '0', '2000ms')
//  */

export const translateIn = (
  element,
  direction,
  start = '100%',
  end = '0',
  delay = null
) => {
  if (!direction || !element) return null

  element.style.transitionDelay = delay
  element.style.transform = `translate${direction}(${end})`

  if (delay) {
    setTimeout(() => {
      element.style.removeProperty('transition-delay')
    }, delay)
  }
}

export const translateOut = (
  element,
  direction,
  start = '0',
  end = '100%',
  delay = null
) => {
  if (!direction || !element) return null

  element.style.transitionDelay = delay
  element.style.transform = `translate${direction}(${end})`

  if (delay) {
    setTimeout(() => {
      element.style.removeProperty('transition-delay')
    }, delay)
  }
}
