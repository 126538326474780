import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { config } from '~/config'
import fetch from 'node-fetch'
import { TokenLink } from './token'
import { promiseToObservable } from '~/plugins/apollo/utils/index'
import { Notification } from '~/modules/helpers/notification'

export const DefaultHttpLink = new HttpLink({
  uri: config.GRAPHQL_ENDPOINT,
  fetch: fetch,
  credentials: 'omit',
})

const getToken = (
  tokenLink: TokenLink,
  operation: any
): Promise<string | void> => {
  const oldHeaders = operation.getContext().headers || {}

  oldHeaders.Authorization = ''

  return tokenLink.fetchRefreshedToken().then((token: string): void => {
    tokenLink.setAccessToken(token)
    operation.setContext({
      headers: {
        ...oldHeaders,
        Authorization: token ? `Bearer ${token}` : null,
      },
    })
  })
}

const getStatusCode = (err): number | string => {
  if (err.message && err.message === 'Forbidden resource') {
    return 401
  }

  const code =
    err.message &&
    (err.message.statusCode ||
      (err.extensions &&
        err.extensions.exception &&
        err.extensions.exception.status) ||
      (err.extensions && err.extensions.code))

  return code
}

export class ErrorLink {
  protected ctx: any
  protected tokenLink: TokenLink

  constructor(ctx) {
    this.ctx = ctx
    this.tokenLink = new TokenLink(ctx)
  }

  getLink() {
    return onError(
      ({ graphQLErrors, networkError, operation, forward, response }) => {
        const context = {
          graphQLErrors,
          networkError,
          operation,
          forward,
          response,
        }
        if (graphQLErrors) {
          for (let err of graphQLErrors) {
            const code = getStatusCode(err)
            if (code) {
              switch (code) {
                case 401:
                case 403:
                  return promiseToObservable(
                    getToken(this.tokenLink, operation)
                  ).flatMap(() => forward(operation))
                default:
                  return this.handleBadRequest(err, context)
              }
            } else if (err.extensions && err.extensions.code) {
              return this.handleBadRequest(err, context)
            }
          }
        }
        // If there is a network error, it suggests that the connection to skyway is not available
        // In this case, retrying will only cause more problems so we log the error and do nothing else
        if (networkError) {
          console.log(
            `[APOLLO Network error]: ${networkError}`,
            'Reply: ',
            Notification.NETWORK_ERROR
          )
          this.ctx.app.$eventBus.notifyFailure(Notification.NETWORK_ERROR)
          if (response) {
            response.errors = undefined
          }
        }
      }
    )
  }

  // GQL Validation errors must be handled on a case by case basis
  handleGqlValidationError(err, context) {
    const message = err.message.message ? err.message.message : err.message

    if (
      typeof message.indexOf !== 'undefined' &&
      message.indexOf('This request will exceed the ticket limit') !== -1
    ) {
      this.ctx.app.$eventBus.notifyFailure(
        'Sorry, this request will exceed the ticket limit for this event'
      )
    }

    switch (message) {
      case 'GraphQL introspection is not allowed by Apollo Server, but the query contained __schema or __type. To enable introspection, pass introspection: true to ApolloServer in production':
        return context.forward(context.operation)
        break
      /**
       * This is happening on clear basket call when the basket expires
       * it prevents the notification from showing for basket expiry and
       * just shows a confusing Failed to load data message
       * Suppress it for now until we can investigate
       */
      case 'TESSITURA_SEATSERVER_EXCEPTION:Error -1: Unable to find requested session.':
        return context.forward(context.operation)
        break
    }

    return this.handleOperationSpecificError(context, message)
  }

  handleBadRequest(err, context) {
    switch (err.extensions.code) {
      case 'TESSITURA_SEAT_LOCKING_ERROR':
        return promiseToObservable(
          this.handleSeatLockingError(err, context)
        ).flatMap(() => context.forward(context.operation))
        break
      case 'PERSISTED_QUERY_NOT_FOUND':
        return context.forward(context.operation)
        break
      case 'GRAPHQL_VALIDATION_FAILED':
      case 'TESSITURA_SERVER_ERROR':
      case 'TESSITURA_BAD_REQUEST':
        if (
          err.message &&
          (err.message === 'Invalid Order_no' ||
            err.message.includes('Unable to find requested session.') ||
            err.message.includes('This seat is already reserved in this order'))
        ) {
          return promiseToObservable(
            this.handleInvalidOrderError(err, context)
          ).flatMap(() => context.forward(context.operation))
        } else {
          return this.handleGqlValidationError(err, context)
        }
        break
      default:
        return this.handleOperationSpecificError(context, err.message)
        break
    }
  }

  handleOperationSpecificError(context, msg = '') {
    let str = Notification.GQL_ERROR[msg]

    if (!str) {
      str = Notification.GQL_ERROR[context.operation.operationName]
    }

    this.ctx.app.$eventBus.notifyFailure(
      str ? str : Notification.GQL_ERROR.generic
    )

    if (context.response && context.response.errors) {
      delete context.response.errors
    }

    return context.forward(context.operation)
  }

  handleSeatLockingError(err, context) {
    return this.ctx.store.dispatch(`customer/transferSession`).then(() => {
      console.log('Transferred session')
      try {
        return context.forward(context.operation)
      } catch (err_) {
        if (process.client) window.location.reload()
      }
    })
  }

  handleInvalidOrderError(err, context) {
    return this.ctx.store.dispatch(`customer/transferSession`).then(() => {
      console.log('Invalid order error')
      if (process.client) window.location.reload() // added this in as although session is transfered the original request still fails and can lead to strange behaviour
      return context.forward(context.operation)
    })
  }
}
