
import { Component, Vue, Watch } from 'nuxt-property-decorator'
// @ts-ignore
import SuccessSvg from '~/static/images/sprites/check-circle.svg'
// @ts-ignore
import WarningSvg from '~/static/images/sprites/error.svg'

@Component({
  components: {
    SuccessSvg,
    WarningSvg,
  },
})
export default class AlertNotification extends Vue {
  public isVisible: boolean = false
  public notificationText: string | null = null
  public notificationHeading: string = 'Error'
  public notificationType: string = 'notification--warning'
  public notificationLink: { to: string; label: string } | null = null
  public shouldPersist: boolean = false
  public registered: boolean = false

  public icons: any = {
    'notification--warning': 'WarningSvg',
    'notification--success': 'SuccessSvg',
  }

  @Watch('isVisible')
  onNotificationShow(newVal, oldVal) {
    if (newVal === true) {
      this.$eventBus.off('notification-show')
    }
    if (newVal === false) {
      this.addNotificationShowListener()
    }
  }

  showNotification(
    notificationText: 'string',
    notificationType: string = 'notification--warning',
    notificationHeading: string | null = null,
    shouldPersist: boolean = false,
    link: { to: string; label: string } | null = null
  ): void {
    this.notificationType = notificationType
    this.notificationText = notificationText
    this.notificationHeading = this.setHeading(notificationHeading)
    this.isVisible = true
    this.notificationLink = link

    if (!this.shouldPersist) {
      setTimeout(() => {
        this.$eventBus.emit('notification-close')
      }, 6000)
    }
  }

  protected setHeading(heading: string | null): string {
    if (heading) {
      return heading
    }
    switch (this.notificationType) {
      case 'notification--warning':
        return 'Warning'
        break
      case 'notification--error':
        return 'Error'
        break
      case 'notification--success':
        return 'Success'
        break
    }
    return 'Warning'
  }

  get icon() {
    return this.icons[this.notificationType] || 'WarningSvg'
  }

  hideNotification(): void {
    this.isVisible = false
    this.notificationText = ''
  }

  addNotificationShowListener(): void {
    this.$eventBus.on(
      'notification-show',
      (msg, type, heading, shouldPersist, link) => {
        this.showNotification(msg, type, heading, shouldPersist, link)
      }
    )
  }

  addEventListeners(): void {
    this.addNotificationShowListener()

    this.$eventBus.on('notification-close', this.hideNotification)
  }

  removeEventListeners(): void {
    this.$eventBus.off('notification-show')
    this.$eventBus.off('notification-close')
  }

  goToPage(link): void {
    this.$router.push(link)
    this.hideNotification()
  }

  mounted(): void {
    if (!this.registered) {
      this.addEventListeners()
      this.registered = true
    }
  }

  beforeDestroy(): void {
    this.removeEventListeners()
  }
}
