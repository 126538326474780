export default async function({ store, redirect }) {

  // make sure we have the customer record before checking login status
  if (store.getters['customer/customer'] == undefined) {
    await store.dispatch('customer/getActiveCustomer')
  }

  // If the user is not authenticated
  if (store.getters['customer/isLoggedIn']) {
    return redirect('/account/')
  }
}
