
import { Component, Prop, Vue, namespace } from 'nuxt-property-decorator'
import { ValidationObserver } from 'vee-validate'
import Loader from '~/components/ui/Loader.vue'
import TextField from '~/components/ui/fields/TextField.vue'
import CustomerPreferenceItem from '~/components/account/preferences/CustomerPreferenceItem.vue'
import {
  Customer,
  NewsletterInput,
  CustomerContactPreference,
  ContactPreferenceCategory,
  ContactPreferenceType,
  CustomerContactPreferenceInput,
} from '~/@types/skyway'
import UIButton from '~/components/ui/UIButton.vue'

const customer = namespace('customer')
const preferences = namespace('preferences')

export type UpdatedContactPreferenceType = ContactPreferenceType & {
  selected: boolean
}

@Component({
  components: {
    Loader,
    TextField,
    ValidationObserver,
    CustomerPreferenceItem,
    UIButton,
  },
})
export default class FooterNewsletterOptions extends Vue {
  @Prop() emailAddress!: string

  protected firstName: string = ''
  protected lastName: string = ''
  protected loading: boolean = false
  protected editing: boolean = true

  @preferences.Action
  protected getPreferenceCategories!: () => Promise<ContactPreferenceCategory[]>

  @preferences.Getter
  protected preferencesByMethod!: (method: string) => ContactPreferenceType[]

  @customer.Action
  protected getCustomerContactPreferences!: () => Promise<Customer>

  @customer.Mutation('UPDATE_CUSTOMER_CONTACT_PREFERENCES')
  protected updatePreference!: (newVal: CustomerContactPreference[]) => void

  @customer.Action
  protected updateContactPreferences!: (
    newVal: CustomerContactPreferenceInput[]
  ) => Promise<CustomerContactPreferenceInput[]>

  @customer.Getter
  protected isLoggedIn!: boolean

  @customer.Action
  protected newsletterSignup!: (params: {
    input: NewsletterInput
    recaptcha: string
  }) => Promise<any>

  @customer.Getter('preferences')
  protected existingPreferences!: CustomerContactPreference[]

  get isLoading() {
    return this.$fetchState.pending || this.loading
  }

  get contactPreferenceInput(): CustomerContactPreferenceInput[] {
    return this.existingPreferences.map((s) => {
      return {
        type_ref: s.type_ref,
      }
    })
  }

  onUpdatePreference(value) {
    this.updatePreference(value)
  }

  onSubmit() {
    if (this.isLoggedIn) {
      this.updateCustomer()
      this.$emit('complete')
    } else {
      this.processSignUp()
    }
  }

  async processSignUp() {
    this.loading = true
    await this.$recaptcha.init()

    const input: NewsletterInput = {
      first_name: this.firstName,
      last_name: this.lastName,
      email: this.emailAddress,
      preferences: this.contactPreferenceInput,
    }
    try {
      const result = await this.newsletterSignup({
        input,
        recaptcha: await this.$recaptcha.execute('newsletter'),
      })
      if (result) {
        this.$emit('complete')
        this.$eventBus.notifySuccess(
          this.$t('Thank you, you have been signed up to our mailing list.')
        )
        this.loading = false
      } else {
        this.loading = false
        this.$eventBus.notifyFailure(
          this.$t('Sorry, there was a problem adding you to the mailing list.')
        )
      }
    } catch (err_) {
      this.loading = false
      this.$eventBus.notifyFailure(
        this.$t('Sorry, there was a problem adding you to the mailing list.')
      )
    }
  }

  async updateCustomer(): Promise<void> {
    this.loading = true
    try {
      await this.updateContactPreferences(this.contactPreferenceInput)
      this.$eventBus.notifySuccess(
        this.$t('Thank you your preferences have been updated')
      )
      this.loading = false
    } catch (err_) {
      await this.getCustomerContactPreferences()
      this.loading = false
      this.$eventBus.notifyFailure(
        this.$t(
          'Sorry, there was a problem updating your preferences. Please try again'
        )
      )
    }
  }

  get emailTypes(): ContactPreferenceType[] {
    return this.preferencesByMethod('e-mail')
  }

  get mailTypes(): ContactPreferenceType[] {
    return this.preferencesByMethod('mail')
  }

  async fetchPreferenceCategories() {
    if ([...this.emailTypes, ...this.mailTypes].length < 1) {
      await this.getPreferenceCategories()
    }
  }

  async fetch() {
    if (this.isLoggedIn) {
      await this.getCustomerContactPreferences()
      return await this.fetchPreferenceCategories()
    }

    const res = await this.$store.dispatch(
      'customer/checkEmailExists',
      this.emailAddress
    )

    if (res === 1) {
      this.$eventBus.notifySuccess(
        'As you are a registered user, please login to change your newsletter preferences.'
      )
      this.$router.push(this.$config.get('URLS').account)
      this.$emit('complete')
    } else {
      await this.fetchPreferenceCategories()
    }
  }

  beforeDestroy() {
    if (this.$recaptcha) {
      this.$recaptcha.destroy()
    }
  }
}
