export default function({ store, redirect, route, config }) {
  if (!process.client) {
    return
  }

  // If the user is logged in, but we don't have customer data
  if (store.getters['customer/isLoggedIn'] && store.getters['customer/displayName'] == '') {
    store.dispatch('customer/getActiveCustomer');
  }
}
