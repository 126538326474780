
import { Component, Vue } from 'vue-property-decorator'
import SvgLanguage from '~/static/images/sprites/language.svg'
import LanguageSwitcher from '~/components/language/LanguageSwitcher.vue'

@Component({
  components: {
    SvgLanguage
  }
})
export default class MobileLanguageSwitcher extends LanguageSwitcher {}
