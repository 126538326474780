
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  components: {
    ValidationProvider,
  },
})
export default class UICheckboxField extends Vue {
  @Prop({ type: String, required: true }) label!: string
  @Prop({ type: String }) id!: string
  @Prop({ type: Boolean, required: true, default: false }) value!: boolean
  @Prop({ type: Boolean, required: false, default: false }) disabled?: boolean
  @Prop({ type: Boolean, required: false, default: false }) required?: boolean

  trueValue = true
  falseValue = false

  get isRequired() {
    return this.required ? { required: { allowFalse: false } } : ''
  }

  get compiledLabel() {
    return this.isRequired
      ? `${this.label} <span style='color:red'>*</span>`
      : this.label
  }

  get isChecked() {
    return this.value === this.trueValue
  }

  update() {
    if (this.disabled) {
      return
    }

    this.$emit('input', this.isChecked)
  }
}
