import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex'
import { RootState } from './types'
import { config } from '~/config'

export const name = 'artifact'

export const namespaced = true

export const types = {
  SET_ARTIFACT: 'SET_ARTIFACT',
  SET_RELATED_ARTIFACTS: 'SET_RELATED_ARTIFACTS',
  SET_SAVED_ARTIFACTS: 'SET_SAVED_ARTIFACTS'
}

export interface Artifact {
  ID: number
  collection: any
  description: string
  image: string
  meta: any
  name: string
}

export interface State {
  artifact?: Artifact,
  related?: Array<Artifact>
  saved_artifacts: Array<Number>
}

/**
 * Initial state, empty array of events
 */
export const state = (): State => ({
  artifact: undefined,
  related: [],
  saved_artifacts: []
})

export const getters: GetterTree<State, RootState> = {}

export const actions: ActionTree<State, RootState> = {
  async getArtifact(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {

    const endpoint = config.ELASTICSEARCH_ENDPOINT;
    const prefix = config.ELASTICSEARCH_INDEX_PREFIX;

    const artifact = await this.$axios
      .get(`${endpoint}/${prefix}-artifact/artifact/${id}`)
      .then((res: any) => res.data._source)

    if (artifact) {
      context.commit(types.SET_ARTIFACT, artifact)
      return artifact
    }

    return null

  },

  async getRelatedArtifacts(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {

    const endpoint = config.ELASTICSEARCH_ENDPOINT;
    const prefix = config.ELASTICSEARCH_INDEX_PREFIX;

    const query = {
      "query": {
          "more_like_this" : {
              "like": {
                "_id": id
              },
              // "fields" : ['name', 'description', 'collection.name'],
              "fields" : ['artist','name','classification','collection.name', 'description'],
              "min_term_freq" : 1,
          }
      }
  
  };

  let related: Array<Artifact>;

  try {
    related = await this.$axios
      .post(`${endpoint}/${prefix}-artifact/_search`, query)
      .then((res: any) => {
        return res.data.hits.hits.map(item => {
          return item._source;
        })
      });
  } catch (err) {
    related = [];
  }

  context.commit(types.SET_RELATED_ARTIFACTS, related)
  return related;

  },

  async loadSavedArtifacts(
    context: ActionContext<State, RootState>
  ): Promise<Array<Number>> {
    try {
      const requestConfig = {
        headers: {
          'Authorization': `Bearer ${this.app.store.getters['customer/token']}`
        },
        params: {
          ts: new Date().getTime()
        }
      }

      const artifacts = await this.$axios
        .get(`${config.WORDPRESS_ENDPOINT}/artifacts`, requestConfig)
        .then((res: any) => res.data)

      if (artifacts) {
        context.commit(types.SET_SAVED_ARTIFACTS, artifacts)
        return artifacts
      }
    } catch (error_) {
      console.warn(error_)
    }

    return [];
  },

  async addSavedArtifact(
    context: ActionContext<State, RootState>,
    object_id: Number
  ): Promise<Array<Number>> {
      const requestConfig = {
        headers: {
          'Authorization': `Bearer ${this.app.store.getters['customer/token']}`
        }
      }
      return this.$axios.post(`${config.WORDPRESS_ENDPOINT}/artifacts`, {object_id: object_id}, requestConfig);
  },

  async removeSavedArtifact(
    context: ActionContext<State, RootState>,
    artifactId: Number
  ): Promise<Array<Number>> {
      const requestConfig = {
        headers: {
          'Authorization': `Bearer ${this.app.store.getters['customer/token']}`
        }
      }
      return this.$axios.delete(`${config.WORDPRESS_ENDPOINT}/artifacts/${artifactId}`, requestConfig);
  }
}

export const mutations: MutationTree<State> = {
  [types.SET_ARTIFACT](state: State, payload: Artifact): void {
    state.artifact = payload
  },

  [types.SET_RELATED_ARTIFACTS](state: State, payload: Array<Artifact>): void {
    state.related = payload
  },

  [types.SET_SAVED_ARTIFACTS](
    state: State,
    payload: Array<Number>
  ): void {
    if (payload) {
      state.saved_artifacts = payload
    }
  }
}
