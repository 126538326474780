
import { Component, Vue } from 'nuxt-property-decorator'
import MetaNavItemBasket from '~/components/menu/MetaNavItemBasket.vue'
import { mapState } from 'vuex'
import SvgAccount from '~/static/images/sprites/account.svg'
import LanguageSwitcher from '~/components/language/LanguageSwitcher.vue'

@Component({
  components: {
    MetaNavItemBasket,
    SvgAccount,
    LanguageSwitcher
  },
  computed: mapState('navigation', ['metaNav'])
})
export default class MetaNavSecondary extends Vue {
  get accountLinkTo(): string {
    return this.$store.getters['customer/isLoggedIn'] ? this.$config.get('URLS').account : this.$config.get('URLS').sign_in
  }

  get accountText(): string {
    return this.$store.getters['customer/isLoggedIn']
      ? this.$store.getters['customer/displayName'] != '' 
        ? `${this.$t('Hi')}, ${this.$store.getters['customer/displayName']}`
        : this.$t('Your account') // fallback in case customer data isn't loaded
      : this.$t('Sign in')
  }
}
