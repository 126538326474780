import { Observable } from 'apollo-link'

export const promiseToObservable = (promise) => {
  return new Observable((subscriber: any): void => {
    promise.then(
      (value) => {
        if (subscriber.closed) return
        subscriber.next(value)
        subscriber.complete()
      },
      (err) => subscriber.error(err)
    )
    return subscriber
  })
}
