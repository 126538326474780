import { ActionTree, ActionContext } from 'vuex'
import * as donations from '~/api/queries/donation.gql'

export const actions: ActionTree<State, RootState> = {
  async addContribution(
    context: ActionContext<State, RootState>,
    input
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: donations['addContribution'],
      variables: input
    })

    const { data } = response

    return data.addContribution
  }
}
