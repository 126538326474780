import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex'

import { RootState } from './types'
import * as preferences from '~/api/queries/preferences.gql'
import {
  ContactPreferenceCategory,
  ContactPreferenceType,
} from '~/@types/skyway'

export const name = 'preferences'

export const namespaced = true

export const types = {
  SET_PREFERENCE_TYPES: 'SET_PREFERENCE_TYPES',
  SET_PREFERENCE_TYPE: 'SET_PREFERENCE_TYPE',
  SET_PREFERENCE_CATEGORIES: 'SET_PREFERENCE_CATEGORIES',
}

export interface State {
  preference_types: ContactPreferenceType[]
  preference_categories: ContactPreferenceCategory[]
}

/**
 * Initial state, empty array of events
 */
export const state = (): State => ({
  preference_types: [],
  preference_categories: [],
})

export const getters: GetterTree<State, RootState> = {
  preferenceTypesByCategory:
    (state: State) => (category_ref: number | string) => {
      return state.preference_types.filter(
        (type) =>
          parseInt(type.category.category_ref) === parseInt(category_ref)
      )
    },
  preferencesByMethod: (state: State) => (method: string) => {
    return (
      state.preference_types.filter(
        (type) => type.name.toLowerCase() === method
      ) || []
    )
  },
}

export const actions: ActionTree<State, RootState> = {
  async getPreferenceTypes(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: preferences['getContactPreferenceTypes'],
      context: {
        public: true,
      },
    })

    const { data } = response

    context.commit(types.SET_PREFERENCE_TYPES, data.getContactPreferenceTypes)
    return data.getContactPreferenceTypes
  },
  async getPreferenceCategories(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: preferences['getContactPreferenceCategories'],
      context: {
        public: true,
      },
    })

    const { data } = response

    context.commit(
      types.SET_PREFERENCE_CATEGORIES,
      data.getContactPreferenceCategories
    )

    if (context.state.preference_categories) {
      for (const cat of context.state.preference_categories) {
        for (const type of cat.types as ContactPreferenceType[]) {
          const data = {
            ...type,
            category_name: cat.name,
          }
          context.commit(types.SET_PREFERENCE_TYPE, data)
        }
      }
    }

    return data.getContactPreferenceCategories
  },
}

export const mutations: MutationTree<State> = {
  [types.SET_PREFERENCE_TYPES](
    state: State,
    payload: ContactPreferenceType[]
  ): void {
    state.preference_types = payload
  },
  [types.SET_PREFERENCE_TYPE](state: State, payload: ContactPreferenceType) {
    if (
      !state.preference_types.find((t) => t && t.type_ref === payload.type_ref)
    ) {
      state.preference_types.push(payload)
    }
  },
  [types.SET_PREFERENCE_CATEGORIES](
    state: State,
    payload: ContactPreferenceCategory[]
  ): void {
    state.preference_categories = payload
  },
}
