import 'core-js/fn/object/entries'
import 'core-js/fn/array/includes'
import 'core-js/fn/array/find'
import 'core-js/fn/array/from'
import 'core-js/es6/promise'
import 'core-js/fn/object/assign'
import 'core-js/es6/symbol'
import 'core-js/es6/number'
import 'whatwg-fetch'
import fetchPonyfill from 'fetch-ponyfill'
const { fetch, Request, Response, Headers } = fetchPonyfill()
import { remove } from './polyfills/remove'
import { closest } from './polyfills/closest'
