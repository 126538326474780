import { ActionTree, MutationTree, GetterTree, ActionContext } from 'vuex'

export const name = 'options'

export const types = {
  SET_ANNOUNCEMENT_STATE: 'SET_ANNOUNCEMENT_STATE',
}

import { RootState } from './types'

export interface State {
  showAnnouncement?: boolean
  announcementHash?: string
}

export const state = (): State => ({
  showAnnouncement: true,
  announcementHash: '',
})

export const mutations: MutationTree<State> = {
  [types.SET_ANNOUNCEMENT_STATE](state, payload: boolean) {
    state.showAnnouncement = payload
  },
  [types.SET_ANNOUNCEMENT_HASH](state, payload: string) {
    state.announcementHash = payload
  },
}
