import BaseService from '~/services/base'
import * as donations from '~/api/queries/donation.gql'

export default class DonationsService extends BaseService {
  async getFunds(): Promise<any> {
    const response = await this.client.query({
      query: donations.getFunds,
    })

    return this.respond(response)
  }

  async getAllFunds(): Promise<any> {
    const response = await this.client.query({
      query: donations.getAllFunds,
    })

    return this.respond(response)
  }

  async addContribution(input): Promise<any> {
    const response = await this.client.mutate({
      mutation: donations.addContribution,
      variables: {
        fund_ref: parseInt(input.fund_ref),
        amount: input.amount,
        note: input.note,
      },
    })
    return this.respond(response)
  }

  async removeContribution(id): Promise<any> {
    const response = await this.client.mutate({
      mutation: donations.removeContribution,
      variables: {
        id,
      },
    })
    return this.respond(response)
  }
}
