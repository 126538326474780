import { cloneDeep } from 'lodash'

const groupNameMap = {
    Class: "Classes",
    Performance: "Admission & Events",
    Contribution: "Donations",
    Membership: "Memberships",
    GiftCertificate: "Gift Certificates",
};

/**
 * We want to create a fake group for classes here
 */
export const decorate = (basket) => {

    if (!basket || basket.decorated) {
        return basket;
    }

    // Clone basket to prevent conflict
    basket = cloneDeep(basket);

    try {
        basket.groups = decorateClassGroup(basket.groups);
        basket.groups = sortGroups(basket.groups);
        basket.groups = decorateGroupNames(basket.groups);

        basket.decorated = true;
    
    } catch(err) {
        console.log(err);
    }

    return basket;
}

const sortGroups = (groups) => {
    const groupNameKeys = Object.keys(groupNameMap);
    return groups.sort((a, b) => groupNameKeys.indexOf(a.name) - groupNameKeys.indexOf(b.name));
}

const decorateGroupNames = (groups) => {
    return groups.map(group => {
        group.name = groupNameMap[group.name] ? groupNameMap[group.name] : group.name;
        return group;
    });
}

const decorateClassGroup = (groups) => {
    const performanceGroup = groups.find(g => g.name == "Performance");

    // if performance group exists
    if (performanceGroup) {
        const classes = performanceGroup.items.filter(item => item.details.type.toLowerCase().includes('class'));

        // ... and it has classes
        if (classes.length) {
            const nonClasses = performanceGroup.items.filter(item => !item.details.type.toLowerCase().includes('class'));
            performanceGroup.items = nonClasses;
            
            // ... put the classes in a new group
            groups.push({
                name: "Class",
                items: classes
            });

            // ... then remove performance group if none are left
            if (!nonClasses.length) {
                groups = groups.filter(g => g.name != 'Performance')
            }
        }
    }

    return groups;
}