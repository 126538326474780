import { ActionTree, ActionContext } from 'vuex'
import { RootState } from '../types'
import { State, types } from './'
import { BasketProperties } from '~/@types/skyway'
import * as basket from '~/api/queries/basket.gql'

const actions: ActionTree<State, RootState> = {
  async getBasket(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    this.app.$eventBus.emit('basket:loading')

    const data = await this.$basket.getBasket(fetchPolicy)

    context.commit(types.SET_BASKET, data.getBasket)

    if (data.getBasket && data.getBasket.expiry) {
      context.commit(types.SET_BASKET_EXPIRY, data.getBasket.expiry)
    }

    if (data.getBasket != null) {
      this.app.$eventBus.emit('update_cookie', {
        basket_count: data.getBasket.groups.length,
      })

      if (data.getBasket.groups.length == 0) {
        this.app.$eventBus.emit('basket:empty')
      }

      this.app.store.commit('forms/UPDATE_ORDER', data.getBasket.order_ref)
    } else {
      this.app.$eventBus.emit('update_cookie', {
        basket_count: 0,
      })
    }

    this.app.$eventBus.emit('basket:finished-loading')

    return data.getBasket
  },

  async getBasketProperties(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const data: { getBasketProperties: BasketProperties } =
      await this.$basket.getBasketProperties(fetchPolicy)

    // debug helper code
    if (data && data.getBasketProperties) {
      console.log(
        `Current mode of sale ${data.getBasketProperties.mode_of_sale_ref}`
      )
    }

    context.commit(types.SET_BASKET_PROPERTIES, data.getBasketProperties)
    return data.getBasketProperties
  },

  async getMessages(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: basket.getMessages,
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_BASKET_MESSAGES, data.getMessages)
    return data.getMessages
  },

  async getBasketExpiry(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: basket.getBasketExpiry,
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_BASKET_EXPIRY, data.getBasketExpiry)
  },

  async updateBasketExpiry(
    context: ActionContext<State, RootState>,
    expiryTime: string
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: basket.updateBasketExpiry,
      variables: {
        expiryTime,
      },
    })

    const { data } = response

    context.commit(types.SET_BASKET_EXPIRY, expiryTime)
  },

  async getBasketComplete(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.query({
      query: basket.getBasketComplete,
      fetchPolicy,
    })

    const { data } = response

    return data.getBasketComplete
  },

  async addFixedPackages(
    context: ActionContext<State, RootState>,
    packages: any
  ): Promise<any> {
    let result = false
    for (const i in packages) {
      const pkg = packages[i]
      const input: AddFixedPackageInput = pkg
      result = await this.dispatch('basket/addFixedPackage', input)
    }
    return result
  },

  async addFixedPackage(
    context: ActionContext<State, RootState>,
    addFixedPackageInput: AddFixedPackageInput
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.addFixedPackage,
      variables: {
        addFixedPackageInput,
      },
    })

    const { data } = response

    this.app.$eventBus.emit('basket:item-added')

    return data.addFixedPackage
  },

  async addFlexPackage(
    context: ActionContext<State, RootState>,
    addFlexPackageInput: AddFlexdPackageInput
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.addFlexPackage,
      variables: {
        addFlexPackageInput,
      },
    })

    const { data } = response

    this.app.$eventBus.emit('basket:item-added')

    return data.addFlexPackage
  },

  async removeFlexPackage(
    context: ActionContext<State, RootState>,
    li_id
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.removeFlexPackage,
      variables: {
        li_id,
      },
    })

    const { data } = response

    return data.removeFlexPackage
  },

  async addTickets(
    context: ActionContext<State, RootState>,
    tickets: any
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.addTickets,
      variables: {
        tickets,
      },
    })

    const { data } = response

    this.app.$eventBus.emit('basket:item-added')

    return data.addTickets
  },

  async addTicket(
    context: ActionContext<State, RootState>,
    { instance_ref, qty, seats, extra }
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.addTicket,
      variables: {
        instance_ref,
        seats,
        qty: Number(qty),
        extra,
      },
    })

    if (response && response.data) {
      const { data } = response

      return data.addTicket
    } else {
      return null
    }
  },

  async removeTickets(
    context: ActionContext<State, RootState>,
    { instance_ref, li_id }
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.removeTickets,
      variables: {
        li_id,
        instance_ref,
      },
    })

    const { data } = response

    // remove any associated form submissions
    this.app.store.commit('forms/UNSET_SUBMISSION', {
      type: 'instance',
      ref: instance_ref,
    })

    return data.removeTickets
  },

  async removeTicket(
    context: ActionContext<State, RootState>,
    { li_id, sli_id }
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.removeTicket,
      variables: {
        li_id,
        sli_id,
      },
    })

    const { data } = response

    return data.removeTicket
  },

  async removeTicketsByInstance(
    context: ActionContext<State, RootState>,
    instance_ref
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.removeTicketsByInstance,
      variables: {
        instance_ref,
      },
    })

    const { data } = response

    return data.removeTicketsByInstance
  },

  async removeContribution(
    context: ActionContext<State, RootState>,
    id
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.removeContribution,
      variables: {
        id: parseInt(id),
      },
    })

    const { data } = response

    return data.removeContribution
  },

  async removeMembership(
    context: ActionContext<State, RootState>,
    id
  ): Promise<any> {
    const data = await this.$basket.removeMembership(id)

    // make sure user has default mode of sale when membership is removed from cart
    this.app.$eventBus.emit('revert_mode_of_sale')
    return data.removeContribution
  },

  async setSource(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: basket.setSource,
      variables: {
        id,
      },
    })

    return response
  },

  async setModeOfSale(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: basket.setModeOfSale,
      variables: {
        id,
      },
    })

    return response
  },

  async revertModeOfSale(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: basket.revertModeOfSale,
    })

    return response
  },

  async setDeliveryMethod(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: basket.setDeliveryMethod,
      variables: {
        id,
      },
    })

    return response
  },

  async setBillingAddress(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    return await this.$basket.setBillingAddress(id)
  },

  async setDeliveryAddress(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: basket.setDeliveryAddress,
      variables: {
        id,
      },
    })

    return response
  },

  async getDeliveryMethods(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<DeliveryMethod[]> {
    const client = this.app.$apollo
    const response = await client.query({
      query: basket.getDeliveryMethods,
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_DELIVERY_METHODS, data.getAvailableDeliveryMethods)

    return data.getAvailableDeliveryMethods
  },

  async applyPromoCode(
    context: ActionContext<State, RootState>,
    code: string
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: basket.applyPromoCode,
      variables: {
        code,
      },
    })

    const { data } = response

    if (data && data.applyPromoCode) {
      context.commit(types.SET_PROMO_CODE, code)
    }

    return data.applyPromoCode
  },

  async resetPromoCode(context: ActionContext<State, RootState>): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: basket.resetPromoCode,
    })

    context.commit(types.SET_PROMO_CODE, '')

    return response
  },

  async getThirdPartyContactPermissions(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: basket.getThirdPartyContactPermissions,
    })

    const { data } = response

    return data.getThirdPartyContactPermissions
  },

  async applyGiftCertificate(
    context: ActionContext<State, RootState>,
    code
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.applyGiftCertificate,
      variables: {
        code,
      },
    })

    const { data } = response

    return data.applyGiftCertificate
  },

  async unapplyGiftCertificate(
    context: ActionContext<State, RootState>,
    gc_no
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.unapplyGiftCertificate,
      variables: {
        gc_no,
      },
    })

    const { data } = response

    return data.unapplyGiftCertificate
  },

  async applyCreditToBasket(
    context: ActionContext<State, RootState>,
    applyCreditToBasketInput
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.applyCreditToBasket,
      variables: {
        applyCreditToBasketInput,
      },
    })

    const { data } = response

    return data.applyCreditToBasket
  },

  async removeCreditFromBasket(
    context: ActionContext<State, RootState>,
    payment_id
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.removeCreditFromBasket,
      variables: {
        payment_id,
      },
    })

    const { data } = response

    return data.removeCreditFromBasket
  },

  async clearBasket(context: ActionContext<State, RootState>): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.clearBasket,
      variables: {},
    })

    const { data } = response

    return data.clearBasket
  },

  async transferBasket(context: ActionContext<State, RootState>): Promise<any> {
    await this.dispatch('customer/transferSession', 'network-only')
    this.app.$eventBus.$emit('basket:transferred-session')
    context.commit(types.SET_BASKET, null)
  },

  async getVerfiedDirectDebitAddresses(
    context: ActionContext<State, RootState>,
    postcode
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: basket.getVerfiedAddresses,
      variables: {
        postcode,
      },
    })

    const { data } = response

    return data.getVerfiedAddresses
  },

  async checkBankAccountDetails(
    context: ActionContext<State, RootState>,
    account_details
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: basket.checkBankAccountDetails,
      variables: {
        account_details,
      },
    })

    const { data } = response

    return data.checkBankAccountDetails
  },

  async verifyBankAccountOwnership(
    context: ActionContext<State, RootState>,
    account_owner
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: basket.verifyBankAccountOwnership,
      variables: {
        account_owner,
      },
    })

    const { data } = response

    return data.verifyBankAccountOwnership
  },

  async directDebitCheckout(
    context: ActionContext<State, RootState>,
    membership
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.directDebitCheckout,
      variables: {
        membership,
      },
    })

    const { data } = response

    return data.directDebitCheckout
  },

  async getRelatedProductByEventRef(
    context: ActionContext<State, RootState>,
    event_ref
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.getRelatedProductByEventRef,
      variables: {
        event_ref,
      },
    })

    const { data } = response

    return data.getRelatedProductByEventRef
  },

  async completeFreeCheckout(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.completeFreeCheckout,
    })

    const { data } = response

    return data.completeFreeCheckout
  },
}

export default actions
