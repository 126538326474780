import { ActionTree, ActionContext, GetterTree } from 'vuex'
import moment from 'moment-timezone'
import { RootState } from './types'
import * as skyway_package from '~/api/queries/packages.gql'
import { Package } from '~/@types/skyway'

export const name = 'packages'

export const namespaced = true

export const types = {
  SET_PACKAGES: 'SET_PACKAGES',
  SET_PACKAGE: 'SET_PACKAGE',
  SET_SELECTED_DATE: 'SET_SELECTED_DATE',
}

export interface State {
  package?: Package
  packages?: [Package]
  selectedDate?: moment.Moment
}

/**
 * Initial state, empty array of events
 */
export const state = (): State => ({
  package: undefined,
  packages: undefined,
  selectedDate: moment(),
})

export const getters: GetterTree<State, RootState> = {}

export const actions: ActionTree<State, RootState> = {
  async getPackagesByInternalType(
    context: ActionContext<State, RootState>,
    internal_type: string
  ): Promise<Array<Package>> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: skyway_package.getPackagesByInternalType,
      variables: {
        internal_type,
      },
      fetchPolicy: 'no-cache',
    })

    const { data } = response

    context.commit(types.SET_PACKAGES, data.getPackagesByInternalType)

    return data.getPackagesByInternalType
  },

  async getPackage(
    context: ActionContext<State, RootState>,
    package_id: number
  ): Promise<Array<Package>> {
    const client = this.app.$apollo

    const response = await client.query({
      query: skyway_package.getPackage,
      variables: {
        id: package_id,
      },
    })

    const { data } = response

    context.commit(types.SET_PACKAGE, data.package)

    return data.package
  },

  async getPackageByRef(
    context: ActionContext<State, RootState>,
    package_ref: OID
  ): Promise<Array<Package>> {
    const client = this.app.$apollo

    const response = await client.query({
      query: skyway_package.getPackageByRef,
      variables: {
        package_ref,
      },
    })

    const { data } = response

    context.commit(types.SET_PACKAGE, data.getPackageByRef)

    return data.getPackageByRef
  },

  async getPackageByRefNoCache(
    context: ActionContext<State, RootState>,
    package_ref: OID
  ): Promise<Array<Package>> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: skyway_package.getPackageByRef,
      variables: {
        package_ref,
        bust_cache: true,
      },
      fetchPolicy: 'no-cache',
    })

    const { data } = response

    context.commit(types.SET_PACKAGE, data.getPackageByRef)

    return data.getPackageByRef
  },
}

export const mutations: MutationTree<State> = {
  [types.SET_PACKAGES](state: State, payload: [Package]): void {
    state.packages = payload
  },
  [types.SET_PACKAGE](state: State, payload: [Package]): void {
    state.package = payload
  },
  [types.SET_SELECTED_DATE](state: State, payload: moment.Moment): void {
    state.selectedDate = payload
  },
}
