
import { Vue, Component, Prop } from 'vue-property-decorator'
import SvgLocation from '~/static/images/sprites/location.svg'
import SvgClock from '~/static/images/sprites/clock.svg'
import SvgCalendar from '~/static/images/sprites/calendar.svg'
import SvgMember from '~/static/images/sprites/member.svg'
import SvgNew from '~/static/images/sprites/exclamation.svg'
import Modal from '~/components/ui/Modal.vue'

@Component({
  components: {
    SvgLocation,
    SvgClock,
    SvgCalendar,
    SvgMember,
    SvgNew,
    Modal
  }
})
export default class FAQCard extends Vue {
  @Prop(Object) story!: object

  /**
   * Lorem image for now if the story has nothing uploaded
   */
  get imageStyle(): string {
    try {
      const imageUrl = this.story.meta.featured_image_url[0].raw;
      const url = imageUrl ? imageUrl : '/images/default-faq.jpeg';
    } catch (err) {
      const url = '';  
    }

    return `background-image: url('${url}')`;
  }
}
