import { ActionTree, MutationTree, ActionContext } from 'vuex'
import { RootState } from './types'
import { config } from '~/config'

export const name = 'post'

export const namespaced = true

export const types = {
  SET_POST: 'SET_POST',
  POST_ERROR: 'POST_ERROR',
  SET_POST_ACCESS: 'SET_POST_ACCESS',
}

export interface MaintenanceResponse {
  enable_maintenance_mode: boolean
  schedule_maintenance_mode: boolean
  schedule_maintenance_mode_start: string
  schedule_maintenance_mode_end: string
  ip_whitelist: false | { address: string }[]
}

export interface ComponentConfig {
  type: string
  name: string
}

export interface FlexibleComponent {
  component?: ComponentConfig
  data?: Record<string, any>
}

export interface PostItem {
  id?: number
  slug?: string
  title?: string
  content?: string
  type?: string
}

export interface State {
  item: PostItem
  components: { [key: string]: FlexibleComponent }
  elements: any
  fixed: { [key: string]: FlexibleComponent }
  images: any
  author: string
  access: string
  passwordRequired: boolean
}

export const state = (): State => ({
  item: {},
  components: {},
  elements: {},
  images: {},
  author: '',
  fixed: {},
  access: 'public',
  passwordRequired: false,
})

export const actions: ActionTree<State, RootState> = {
  async getPost(context: ActionContext<State, RootState>, route): Promise<any> {
    let { path } = route

    try {
      const post = await this.$axios
        .get(`${config.WORDPRESS_ENDPOINT}${path}`)
        .then((res: any) => res.data)

      if (post) {
        context.commit(types.SET_POST, post.data)

        if (post.meta && post.meta.access) {
          context.commit(types.SET_POST_ACCESS, post.meta.access)
        }
        return post.data
      }
    } catch (error_) {
      console.warn(error_)
    }

    return false
  },

  async getPreview(
    context: ActionContext<State, RootState>,
    route
  ): Promise<any> {
    let { query } = route

    let post_id = query.preview_id

    if (!post_id) {
      if (query.page_id) {
        post_id = query.page_id
      } else if (query.p) {
        post_id = query.p
      }
    }

    try {
      const post = await this.$axios
        .get(`${config.WORDPRESS_ENDPOINT}/preview/${post_id}`)
        .then((res: any) => res.data)

      if (post) {
        context.commit(types.SET_POST, post.data)

        if (post.meta && post.meta.access) {
          context.commit(types.SET_POST_ACCESS, post.meta.access)
        }
        return post.data
      }
    } catch (error_) {
      console.warn(error_)
    }

    return false
  },

  async getPasswordProtectedPost(
    context: ActionContext<State, RootState>,
    route
  ): Promise<any> {
    let { path, password } = route
    let queryStr = `?password=${password}`

    try {
      const post = await this.$axios
        .get(`${config.WORDPRESS_ENDPOINT}${path}${queryStr}`)
        .then((res: any) => res.data)

      if (post) {
        context.commit(types.SET_POST, post.data)

        if (post.meta.access) {
          context.commit(types.SET_POST_ACCESS, post.meta.access)
        }
        return post.data
      }
    } catch (error_) {
      console.warn(error_)
    }

    return false
  },

  async checkMaintenanceMode(
    context: ActionContext<State, RootState>
  ): Promise<MaintenanceResponse | false> {
    try {
      const maintenance = await this.$axios
        .get(`${config.WORDPRESS_ENDPOINT}/maintenance-mode`)
        .then((res: any) => res.data)

      return maintenance && maintenance.data
    } catch (error_) {
      return false
    }
  },
}

export const mutations: MutationTree<State> = {
  [types.SET_POST](state: any, payload: State) {
    if (typeof payload == 'string' && payload == 'Password protected') {
      state.passwordRequired = true
    } else {
      state.item = payload.item
      state.components = payload.components
      state.elements = payload.elements
      state.fixed = payload.fixed
      state.images = payload.images
      state.author = payload.author
      state.passwordRequired = false
    }
  },
  [types.SET_POST_ACCESS](state: any, payload: string) {
    if (payload != '') {
      state.access = payload
    }
  },
}
