import { MutationTree, GetterTree } from 'vuex'
import * as BasketDecorator from '~/modules/helpers/basketDecorator'
import {
  Basket,
  DeliveryMethod,
  BasketProperties,
  Message,
} from '~/@types/skyway'
import actions from './actions'

export { actions }

export const name = 'basket'

export const namespaced = true

export const types = {
  SET_BASKET: 'SET_BASKET',
  SET_BASKET_EXPIRY: 'SET_BASKET_EXPIRY',
  SET_DELIVERY_METHODS: 'SET_DELIVERY_METHODS',
  SET_BASKET_PROPERTIES: 'SET_BASKET_PROPERTIES',
  SET_PROMO_CODE: 'SET_PROMO_CODE',
  SET_BASKET_MESSAGES: 'SET_BASKET_MESSAGES',
}

export interface State {
  basket?: Basket
  expiry?: any
  delivery_methods?: DeliveryMethod[]
  properties?: BasketProperties
  promo_code?: string
  messages?: Message[]
}

export const state = (): State => ({
  basket: undefined,
  expiry: undefined,
  delivery_methods: undefined,
  properties: undefined,
  promo_code: undefined,
  messages: undefined,
})

export const getters: GetterTree<State, RootState> = {
  basketIsEmpty: (state: State): boolean => {
    return state.basket === undefined
  },

  basketExpiry: (state: State): number | undefined => {
    if (state.expiry) {
      const diff = this.$moment().diff(this.$moment(state.expiry), 'seconds')

      return diff
    } else {
      return undefined
    }
  },

  basket: (state: State) => {
    if (state.basket && state.basket.groups.length) {
      return state.basket
    } else {
      return false
    }
  },

  properties: (state: State) => {
    if (state.properties) {
      return state.properties
    } else {
      return null
    }
  },

  lastAddedItemRef: (state: State, getters: GetterTree) => {
    const tickets = getters.tickets
    const packages = getters.packages
    const classes = getters.classes
    const item_refs: any[] = []
    tickets.forEach((t) => item_refs.push(t.item_ref))
    packages.forEach((t) => item_refs.push(t.item_ref))
    classes.forEach((t) => item_refs.push(t.item_ref))

    item_refs.sort((a, b) => {
      if (a > b) return 1
      if (a < b) return -1
      return 0
    })
    return parseInt(item_refs.pop())
  },

  tickets: (state: State) => {
    if (state.basket && state.basket.groups && state.basket.groups.length) {
      const tickets = state.basket.groups.reduce((output, group) => {
        // group name is mutated by BasketDecorater, so easier to check for other groups
        if (
          group &&
          group.name !== 'Packages' &&
          group.name !== 'Membership' &&
          group.name !== 'Contribution' &&
          group.name !== 'GiftCertificate'
        ) {
          output.push(group)
        }
        return output
      }, [])

      return tickets.length ? tickets[0].items : []
    } else {
      return []
    }
  },

  packages: (state: State) => {
    if (state.basket && state.basket.groups && state.basket.groups.length) {
      const packages = state.basket.groups.reduce((output, group) => {
        if (group.name === 'Package') {
          output.push(group)
        }
        return output
      }, [])

      return packages.length ? packages[0].items : []
    } else {
      return []
    }
  },

  classes: (state: State) => {
    if (state.basket && state.basket.groups && state.basket.groups.length) {
      const tickets = state.basket.groups.reduce((output, group) => {
        if (group.name === 'Classes') {
          output.push(group)
        }
        return output
      }, [])

      return tickets.length ? tickets[0].items : []
    } else {
      return []
    }
  },

  memberships: (state: State) => {
    if (
      state.basket != undefined &&
      state.basket != null &&
      state.basket.groups.length
    ) {
      const memberships = state.basket.groups.reduce((output, group) => {
        if (group.name === 'Membership') {
          output.push(group)
        }
        return output
      }, [])

      return memberships.length ? memberships[0].items : []
    } else {
      return []
    }
  },

  contributions: (state: State) => {
    if (
      state.basket != undefined &&
      state.basket != null &&
      state.basket.groups.length
    ) {
      const contributions = state.basket.groups.reduce((output, group) => {
        if (group.name == 'Contribution') {
          output.push(group)
        }
        return output
      }, [])

      return contributions.length ? contributions[0].items : []
    } else {
      return []
    }
  },

  vouchers: (state: State) => {
    if (
      state.basket != undefined &&
      state.basket != null &&
      state.basket.groups.length
    ) {
      const vouchers = state.basket.groups.reduce((output, group) => {
        if (group.name !== 'GiftCertificate') {
          output.push(group)
        }
        return output
      }, [])

      return vouchers.length ? vouchers[0].items : []
    } else {
      return []
    }
  },

  promoCode: (state: State): string | undefined => {
    return state.promo_code != undefined ? state.promo_code : ''
  },

  seats: (state: State, getters: GetterTree) => {
    const seats = []
    getters.tickets.forEach((ticket) => {
      if (ticket.children && ticket.children.length) {
        ticket.children.forEach((child) => {
          seats.push({
            price: child.unit_price,
            instance_ref: ticket.extra.instance_ref,
            item_ref: ticket.item_ref,
            sub_item_ref: child.sub_item_ref,
            seat: {
              row: child.extra.seat_row,
              number: child.extra.seat_number,
              seat_type: child.extra.section,
              seat_ref: child.extra.seat_ref,
              screen_ref: child.extra.section_ref,
            },
          })
        })
      }
    })

    return seats
  },
}

export const mutations: MutationTree<State> = {
  [types.SET_BASKET](state: State, payload: Basket): void {
    state.basket = BasketDecorator.decorate(payload)
    // state.basket = payload;
  },
  [types.SET_BASKET_EXPIRY](state: State, payload: any): void {
    state.expiry = payload
  },
  [types.SET_PROMO_CODE](state: State, payload: any): void {
    state.promo_code = payload
  },
  [types.SET_DELIVERY_METHODS](state: State, payload: DeliveryMethod[]): void {
    state.delivery_methods = payload
  },
  [types.SET_BASKET_PROPERTIES](state: State, payload: BasketProperties): void {
    state.properties = payload
  },
  [types.SET_BASKET_MESSAGES](state: State, payload: Message[]): void {
    state.messages = payload
  },
}
