import BaseService from '~/services/base'
import * as events from '~/api/queries/events.gql'
import { DateRangeInput, Series, Event } from '~/@types/skyway'

export default class EventsService extends BaseService {
  async getEvents(
    query: string = 'getEvents'
  ): Promise<{ getEvents: Event[] }> {
    const response = await this.client.query({
      query: events[query],
    })

    return this.respond(response)
  }

  async getEventsByDate(
    range: DateRangeInput
  ): Promise<{ getEventsByDate: Event[] }> {
    const response = await this.client.query({
      query: events.getEventsByDate,
      variables: {
        range,
      },
      context: {
        public: true,
      },
    })

    return this.respond(response)
  }

  async getEventByRef(
    event_ref: string,
    range: DateRangeInput,
    fetchPolicy: string,
    preview: boolean = false
  ): Promise<{ getEventByRef: Event }> {
    const response = await this.client.query({
      query: events.getEventByRef,
      variables: {
        id: event_ref,
        range,
        preview,
      },
      context: {
        public: true,
      },
      fetchPolicy,
    })

    return this.respond(response)
  }

  async getEventById(
    id: string,
    range: DateRangeInput,
    fetchPolicy: string,
    preview: boolean = false
  ): Promise<{ getEventById: Event }> {
    const response = await this.client.query({
      query: events.event,
      variables: {
        id,
        preview,
      },
      context: {
        public: true,
      },
      fetchPolicy,
    })

    return this.respond(response)
  }

  async getTimedEventByRef(
    event_ref: string,
    fetchPolicy: string = 'cache-first'
  ): Promise<{ getTimedEventByRef: Event }> {
    const response = await this.client.query({
      query: events['getTimedEventByRef'],
      variables: {
        event_ref,
      },
      context: {
        public: true,
      },
      fetchPolicy: 'no-cache',
    })

    return this.respond(response)
  }

  async getEventInstancesByRef(
    event_ref: string,
    range: DateRangeInput,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const response = await this.client.query({
      query: events.getInstancesByEventRef,
      variables: {
        id: event_ref,
        range,
      },
      context: {
        public: true,
      },
      fetchPolicy,
    })

    return this.respond(response)
  }

  async getEventAvailability(
    event_ref: string,
    range: DateRangeInput,
    bust_cache: boolean = false
  ): Promise<any> {
    const response = await this.nonPersistedClient.query({
      query: events.getEventAvailability,
      variables: {
        id: event_ref,
        range: range,
        bust_cache,
      },
      context: {
        public: false,
      },
      fetchPolicy: 'network-only',
    })

    return this.respond(response)
  }

  async getEventPrices(event_ref: string): Promise<any> {
    const response = await this.nonPersistedClient.query({
      query: events.getEventPrices,
      variables: {
        id: event_ref,
      },
      context: {
        public: false,
      },
      fetchPolicy: 'network-only',
    })

    return this.respond(response)
  }

  async getEventBySlug(
    slug: string,
    fetchPolicy: string = 'cache-first'
  ): Promise<any> {
    // const preview = !!(
    //   this.app.context.route.query !== undefined &&
    //   this.app.context.route.query.preview !== undefined
    // )

    const response = await this.client.query({
      query: events.getEventBySlug,
      variables: {
        slug,
        preview: false,
      },
      context: {
        public: true,
      },
      fetchPolicy,
    })

    return this.respond(response)
  }

  async getEventMetaBySlug(
    slug: number,
    fetchPolicy: string = 'no-cache'
  ): Promise<Event> {
    const response = await this.client.query({
      query: events.getEventMetaBySlug,
      variables: {
        slug,
      },
      context: {
        public: true,
      },
      fetchPolicy,
    })

    return this.respond(response)
  }

  async getSeries(): Promise<{ getSeries: Series[] }> {
    const response = await this.client.query({
      query: events.getSeries,
      context: {
        public: true,
      },
    })

    return this.respond(response)
  }

  async getSeriesByAlias(alias: string): Promise<any> {
    const response = await this.client.query({
      query: events.series,
      variables: {
        alias,
      },
      context: {
        public: true,
      },
    })

    return this.respond(response)
  }
}
