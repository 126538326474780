import Vue from 'vue';
import VueRS from '@appbaseio/reactivesearch-vue';
import { config } from '~/config'

Vue.use(VueRS);


export default (context, inject) => {

    const indexPrefix = context.app.$config.get('ELASTICSEARCH_INDEX_PREFIX');
    
    let settings = {
        app: `${indexPrefix}*`,
        url: context.app.$config.get('ELASTICSEARCH_ENDPOINT'),
        theme: {
            typography: {
                fontFamily: '"StagSans", sans-serif',
            }
        }
    }

    const reactive = {
        
        setIndexes: (indexes) => {
            indexes = indexes.map(index => `${indexPrefix}-${index}`).join(',');
            settings = {...settings, ...{app: indexes}}
        },

        getSettings: () => {
            return settings;
        }
    }
    inject('reactive', reactive)
}
