
import { Component, Mixins } from 'vue-mixin-decorator'
import SkipToContent from '~/components/SkipToContent.vue'
import Header from '~/components/layout/header/Header.vue'
import Footer from '~/components/layout/footer/Footer.vue'
import AlertNotification from '~/components/ui/AlertNotification.vue'
import MetaNavPrimary from '~/components/menu/MetaNavPrimary.vue'
import { FocusOnRouteChange } from '~/mixins/FocusOnRouteChange'

@Component({
  head() {
    return {
      titleTemplate: '%s | Honolulu Museum of Art',
    }
  },
  components: {
    SkipToContent,
    Header,
    Footer,
    AlertNotification,
    MetaNavPrimary,
  },
})
export default class DefaultLayout extends Mixins(FocusOnRouteChange) {
  private headerHeight: number = 0

  get locale() {
    return this.$i18n.locale
  }

  get mainSectionStyle() {
    return `margin-top: ${this.headerHeight}px`
  }

  onHeaderHeightChanged(value) {
    this.headerHeight = value
  }

  onWindowFocusChange(e) {
    if ({ focus: 1, pageshow: 1 }[e.type]) {
      this.$eventBus.emit('tab-focus-change', true)
    }
  }

  detectFocusChange() {
    window.addEventListener('focus', this.onWindowFocusChange)
    window.addEventListener('blur', this.onWindowFocusChange)
    window.addEventListener('pageshow', this.onWindowFocusChange)
    window.addEventListener('pagehide', this.onWindowFocusChange)
  }

  beforeDestroy() {
    window.removeEventListener('focus', this.onWindowFocusChange)
    window.removeEventListener('blur', this.onWindowFocusChange)
    window.removeEventListener('pageshow', this.onWindowFocusChange)
    window.removeEventListener('pagehide', this.onWindowFocusChange)
  }

  mounted() {
    this.detectFocusChange()
    this.$store.dispatch('custom_messages/getCustomMessages')
  }
}
