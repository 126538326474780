export default ({ app }) => {
  // onLanguageSwitched called right after a new locale has been set
  app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    app.$axios.defaults.headers.common['Content-Language'] = newLocale
  }

  // Set the moment locale
  const locale = app.i18n.getLocaleCookie() || app.i18n.defaultLocale
  app.$moment.locale(locale)

  // Add content-language header to axios
  app.$axios.defaults.headers.common[
    'Content-Language'
  ] = locale

  // Get the translations from Wordpress and load into i18n,
  // if we are not currently using the default language
  if (app.i18n.locale != app.i18n.defaultLocale) {
    app.$axios
      .get(app.$config.get('TRANSLATION_ENDPOINT'))
      .then(({ data }) => {
        data.data.forEach((translation) => {
          const t = {}
          t[translation.original] = translation.translation
          app.i18n.mergeLocaleMessage(translation.language, t)
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
