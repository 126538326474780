import Vue from 'vue'
import Loader from '~/components/ui/Loader'
import Accordion from '~/components/ui/Accordion.vue'
import AlertNotification from '~/components/ui/AlertNotification'
import SkipToContent from '~/components/SkipToContent'
import UIButton from '~/components/ui/UIButton'
import UIRadioButton from '~/components/ui/UIRadioButton'
import Breadcrumbs from '~/components/ui/Breadcrumbs.vue'

const components = {
  Loader,
  Accordion,
  AlertNotification,
  SkipToContent,
  UIButton,
  UIRadioButton,
  Breadcrumbs,
}

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})
