
import { Component, Mixins } from 'vue-mixin-decorator'
import SkipToContent from '~/components/SkipToContent.vue'
import Logo from '~/components/Logo.vue'
import AlertNotification from '~/components/ui/AlertNotification.vue'
import MetaNavPrimary from '~/components/menu/MetaNavPrimary.vue'
import { FocusOnRouteChange } from '~/mixins/FocusOnRouteChange'

@Component({
  head() {
    return {
      titleTemplate: '%s | Honolulu Museum of Art',
    }
  },
  components: {
    SkipToContent,
    Logo,
    AlertNotification,
    MetaNavPrimary,
  },
})
export default class MaintenanceLayout extends Mixins(FocusOnRouteChange) {
  private headerHeight: number = 0

  get locale() {
    return this.$i18n.locale
  }

  get mainSectionStyle() {
    return `margin-top: ${this.headerHeight}px`
  }

  get locations(): Array<any> {
    // replace these with store calls once we can connect to skyway
    return [
      {
        title: 'The Honolulu Museum of Art',
        phone: '808.532.8700',
        address: {
          line_1: '900 South Beretania Street',
          line_2: 'Honolulu, Hawaii 96814',
        },
      },
      {
        title: 'Honolulu Museum of Art School',
        phone: '808.532.8741',
        address: {
          line_1: '1111 Victoria Street',
          line_2: 'Honolulu, Hawaii 96814',
        },
      },
      /*
      {
        title: 'First Hawaiian Center',
        phone: '808.532.8700',
        address: {
          line_1: '999 Bishop Street',
          line_2: 'Honolulu, Hawaii 96813'
        }
      }
      */
    ]
  }

  onHeaderHeightChanged(value) {
    this.headerHeight = value
  }

  onWindowFocusChange(e) {
    if ({ focus: 1, pageshow: 1 }[e.type]) {
      this.$eventBus.emit('tab-focus-change', true)
    }
  }

  detectFocusChange() {
    window.addEventListener('focus', this.onWindowFocusChange)
    window.addEventListener('blur', this.onWindowFocusChange)
    window.addEventListener('pageshow', this.onWindowFocusChange)
    window.addEventListener('pagehide', this.onWindowFocusChange)
  }

  beforeDestroy() {
    window.removeEventListener('focus', this.onWindowFocusChange)
    window.removeEventListener('blur', this.onWindowFocusChange)
    window.removeEventListener('pageshow', this.onWindowFocusChange)
    window.removeEventListener('pagehide', this.onWindowFocusChange)
  }

  mounted() {
    this.detectFocusChange()
  }
}
