
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import SVGLoadingIcon from '~/static/images/sprites/loading-icon.svg'

/**
 * Loading component will fill 60% of the viewport height unless a
 * fixed height value is passed as a prop
 */
@Component({
  components: { SVGLoadingIcon }
})
export default class Loader extends Vue {
  @Prop({ type: Number, default: 0 }) height: number

  get containerHeight(): string | boolean {
    return this.height === 0 ? false : `${this.height}px`
  }
}
