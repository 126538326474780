
import { Component, Vue } from 'vue-property-decorator'
import SvgClose from '~/static/images/sprites/menu-close.svg'
import DrawerContentOnToday from '~/components/menu/drawers/DrawerContentOnToday.vue'
import DrawerContentOurVenues from '~/components/menu/drawers/DrawerContentOurVenues.vue'
import DrawerContentDonate from '~/components/menu/drawers/DrawerContentDonate.vue'
import DrawerContentMyTicketsPasses from '~/components/menu/drawers/DrawerContentMyTicketsPasses.vue'
import DrawerContentOpeningTimes from '~/components/menu/drawers/DrawerContentOpeningTimes.vue'
import Lock from 'vue-focus-lock'

@Component({
  components: {
    SvgClose,
    DrawerContentOnToday,
    DrawerContentOurVenues,
    DrawerContentDonate,
    DrawerContentMyTicketsPasses,
    DrawerContentOpeningTimes,
    Lock
  }
})
export default class MetaNavItemDrawer extends Vue {
  public isOpen: boolean = false
  public item: any = null

  /**
   * Open drawer and toggle content
   *
   * @param item - the meta nav item clicked
   *
   * @return void
   */
  toggleState(item: any): void {
    this.item = item
    this.isOpen = true
    document.body.classList.add('overflow-hidden')
  }

  /**
   * Handle Drawer close
   *
   * @return void
   */
  onClickClose(): void {
    this.isOpen = false
    document.body.classList.remove('overflow-hidden')
  }

  /**
   * Get a component name to dynamically render drawer content
   *
   * @return string
   */
  get componentName(): string {
    return (
      'DrawerContent' +
      ` ${this.item.label}`
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
    )
  }

  /**
   * Register event bus listener for meta nav item clicks
   *
   * @return void
   */
  mounted(): void {
    this.$eventBus.on('meta-nav-item:click', (item: any) => {
      this.toggleState(item)
    })

    this.$eventBus.on('meta-nav-item:trigger-close', () => {
      this.onClickClose()
    })
  }
}
