import * as customer from '~/api/queries/customer.gql'
import BaseService from '~/services/base'

export default class AuthService extends BaseService {
  async login({ username, password }) {
    const response = await this.client.mutate({
      mutation: customer['login'],
      variables: {
        username,
        password,
      },
      context: {
        credentials: 'include',
      },
    })

    return this.respond(response)
  }

  async reauthenticate(order_ref = null) {
    const response = await this.client.mutate({
      mutation: customer['reauthenticate'],
      variables: {
        order_ref,
      },
      context: {
        credentials: 'include',
      },
    })

    return this.respond(response)
  }

  async checkEmailExists(email): Promise<{ checkEmailExists: number }> {
    const response = await this.client.mutate({
      mutation: customer['checkEmailExists'],
      variables: {
        email,
      },
      context: {
        public: true,
      },
    })

    return this.respond(response)
  }

  async socialLogin(input): Promise<any> {
    const response = await this.client.mutate({
      mutation: customer['socialLogin'],
      variables: {
        input,
      },
      context: {
        credentials: 'include',
      },
    })

    return this.respond(response)
  }

  /**
   * Login and fetch the active customer details if successful
   */
  async idLogin({ customer_ref, password }): Promise<any> {
    const response = await this.client.mutate({
      mutation: customer['idLogin'],
      variables: {
        customer_ref,
        password,
      },
      context: {
        credentials: 'include',
      },
    })

    return this.respond(response)
  }

  async tokenLogin({ email, token }): Promise<any> {
    const response = await this.client.mutate({
      mutation: customer['tokenLogin'],
      variables: {
        email,
        token,
      },
      context: {
        credentials: 'include',
      },
    })

    return this.respond(response)
  }

  async createWebLogin(email: string, login_type_ref: string) {
    const response = await this.client.mutate({
      mutation: customer['createWebLogin'],
      variables: {
        email,
        login_type_ref,
      },
      context: {
        credentials: 'include',
      },
    })

    return this.respond(response)
  }

  /**
   * Remove the refresh cookies and customer details from store
   * Will need to hit an endpoint to clear the cookies
   */
  async logout() {
    const response = await this.client.query({
      query: customer['logout'],
      context: {
        credentials: 'include',
      },
    })

    return this.respond(response)
  }
}
