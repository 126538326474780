
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import Lock from 'vue-focus-lock'
import MenuCloseSvg from '~/static/images/sprites/menu-close.svg'

@Component({
  components: {
    MenuCloseSvg,
    Lock
  }
})
export default class Modal extends Vue {
  @Prop({ type: Boolean, default: true }) showTrigger!: boolean
  @Prop({ type: Boolean, default: false }) isFullscreen!: boolean

  isOpen: boolean = false
  header: any = null

  open(): void {
    this.isOpen = true
    disableBodyScroll(this.$el.querySelector('.modal'))
  }

  close(): void {
    this.isOpen = false
    enableBodyScroll(this.$el.querySelector('.modal'))
  }
}
