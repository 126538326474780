import { MaintenanceResponse } from '~/store/post'
import { config } from '~/config'

/**
 * These pages should bypass maintenance mode
 * as payment may be in progress, we don't want
 * to interupt this.
 */
const exclusions = [
  'purchase/checkout/success',
  'purchase/checkout/cancelled',
  'purchase/checkout/payment',
  'purchase/checkout',
]

/**
 * rough IP validation utility
 */
const validIp = (ip: string) => {
  const ipv4Pattern =
    /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]{1,2})(\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]{1,2})){3}$/
  const ipv6Pattern =
    /^([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}$|^([0-9a-fA-F]{1,4}:){1,7}:([0-9a-fA-F]{1,4}:){1,7}[0-9a-fA-F]{1,4}$|^([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}$|^([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}$|^([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}$|^([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}$|^([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}$|^[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})$/
  return ipv4Pattern.test(ip) || ipv6Pattern.test(ip)
}

/**
 * Get the IP address from the client side
 */
const getClientIpAddress = async (store): Promise<string | null> => {
  if (validIp(store.state.session.ssrIp)) {
    return store.state.session.ssrIp
  } else {
    try {
      const response = await fetch('https://api.ipify.org?format=json')
      const data = await response.json()
      return data.ip
    } catch (error) {
      console.error('Error fetching client IP address:', error)
      return null
    }
  }
}

/**
 * Get the IP address on the server side
 * this returns 127.0.0.1 when working locally
 * but the NGINX reverse proxy should forward
 * the real IP in prod
 */
const getServerIpAddress = (req, store) => {
  const possible =
    req.headers['x-forwarded-for'] || req.socket.remoteAddress || ''
  const ip = possible && possible.split(',')[0].trim()

  // for local testing - change first ternary to your own IP
  const toUse = ip === '127.0.0.1' ? '81.79.232.30' : ip

  store.commit('session/SET_SSR_IP', toUse)
  return toUse
}

/**
 * Middleware runs on SSR on first page load/refresh and on client side navigation
 * in both cases, we need to check WP for maintenance mode and return the
 * appropriate response, either a 302 on the server or a router redirect on client
 */
export default async function ({ store, redirect, route, app, $moment, req }) {
  const maintenance: MaintenanceResponse = await store.dispatch(
    'post/checkMaintenanceMode'
  )

  const ipAddress = process.server
    ? getServerIpAddress(req, store)
    : await getClientIpAddress(store)

  /**
   * Remove any rubbish from the Whitelist before checking
   */
  const validWhitelist = maintenance.ip_whitelist
    ? maintenance.ip_whitelist.map((a) => a.address).filter((ip) => validIp)
    : []

  const ipWhitelisted = validWhitelist.includes(ipAddress)

  const isOnExcludedPage = exclusions.some((ex) => route.path.includes(ex))

  const isOnMaintenancePage = route.path.includes(
    config.MAINTENANCE_MODE_PAGE.replace(/\//g, '')
  )

  /**
   * Check if scheduled maintenance is enabled
   * we should be between start and end time
   * all times converted to UTC to avoid timezone quirks
   */
  const isInScheduledWindow = (): boolean => {
    if (
      !$moment(maintenance.schedule_maintenance_mode_start).isValid() ||
      !$moment(maintenance.schedule_maintenance_mode_end).isValid()
    ) {
      return false
    }
    return $moment()
      .utc()
      .isBetween(
        $moment.utc(maintenance.schedule_maintenance_mode_start),
        $moment.utc(maintenance.schedule_maintenance_mode_end)
      )
  }

  /**
   * Check if maintenance mode is toggled on
   * or if we are in a scheduled window
   * the toggle should short circuit the window
   */
  const maintenanceModeEnabled = () => {
    if (maintenance.enable_maintenance_mode === true) {
      return true
    }

    if (maintenance.schedule_maintenance_mode === true) {
      return isInScheduledWindow()
    }

    return false
  }

  if (maintenanceModeEnabled()) {
    console.log({ whitelistedIps: validWhitelist })
    console.log({ clientIp: ipAddress, ssr: store.state.session.ssrIp })
    console.log({ ipWhitelisted })
  }

  /**
   * Redirect the user to the maintenance page
   * if conditions are met
   */
  if (
    maintenanceModeEnabled() &&
    !isOnMaintenancePage &&
    !isOnExcludedPage &&
    !ipWhitelisted
  ) {
    if (process.server) {
      redirect(302, config.MAINTENANCE_MODE_PAGE)
    } else {
      app.router.replace(config.MAINTENANCE_MODE_PAGE)
    }
  }

  /**
   * Redirect the user away from maintenance page
   * if conditions are met
   */
  if (isOnMaintenancePage && !maintenanceModeEnabled()) {
    if (process.server) {
      redirect(302, '/')
    } else {
      app.router.replace('/')
    }
  }

  /**
   * If we reach this point, app will continue as normal
   */
}
