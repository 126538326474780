
import { Vue, Component, Prop } from 'vue-property-decorator'
import SvgLocation from '~/static/images/sprites/location.svg'
import SvgClock from '~/static/images/sprites/clock.svg'
import SvgCalendar from '~/static/images/sprites/calendar.svg'
import SvgMember from '~/static/images/sprites/member.svg'
import SvgNew from '~/static/images/sprites/exclamation.svg'
import { config } from '~/config'

@Component({
  components: {
    SvgLocation,
    SvgClock,
    SvgCalendar,
    SvgMember,
    SvgNew
  }
})
export default class storyCard extends Vue {
  @Prop(Object) story!: object

  protected dateFormat = 'MMM D YYYY'

  onClickCard(): void {
    this.$router.push(this.link)
  }

  get link(): string {
    return this.story.permalink
  }

  /**
   * Use computed props to transform story data
   */
  get date() {
    let date = this.story.post_date

    return date ? this.$moment(date).format(this.dateFormat) : ''
  }

  get category() {
    try {
      return this.story.terms.category[0].name
    } catch (err) {
      return 'Uncategorized'
    }
  }

  get featured() {
    if(this.story.terms.post_tag && this.story.terms.post_tag.length > 0) {
      return this.story.terms.post_tag.find(tag => {
        const name = tag.name.toLowerCase();
        return name == 'featured'
      })
    }
    return false
  }

  /**
   * Lorem image for now if the story has nothing uploaded
   */
  get imageStyle(): string {
    try {
      return `background-image: url('${this.story.meta.featured_image_url[0].raw}');`
    } catch (err) {
      return `background-image: url('')`
    }
  }
}
