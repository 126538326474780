import Vue from 'vue'
import { Mixin } from 'vue-mixin-decorator'
import { Watch } from 'nuxt-property-decorator'

/**
 * Because we are not reloading the page, the browser doesn't handle
 * focus change in the way a user would expect.  Here we are watching
 * for a route change and shifting focus to the outer container
 * each layout should implement this mixin
 */
@Mixin()
export class FocusOnRouteChange extends Vue {
  @Watch('$route.path')
  onPropertyChanged(newValue, oldValue) {
    const top = this.$refs.top
    if (top) {
      top.focus()
    }
  }
}
