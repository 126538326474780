import https from 'https'

export default function({ $axios }) {
  process.env.NODE_TLS_REJECT_UNAUTHORIZED = false
  const basicAuth =
    'Basic ' + Buffer.from('homa' + ':' + 'afterdigital').toString('base64')
  $axios.setToken(basicAuth)

  $axios.onRequest((config) => {
    console.log(`[${config.method}] ${config.url}`)
  })

  $axios.onResponse((response) => {
    console.log(`[response] ${response.status}`)
  })

  $axios.onError((err) => {
    console.log(
      `[${err.response && err.response.status}] ${err.response &&
        err.response.request.path}`
    )
    console.log(err.response && err.response.data)
  })
}
