import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex'
import { Vue } from 'nuxt-property-decorator'
import moment from 'moment-timezone'
import { RootState } from './types'
import * as events from '~/api/queries/events.gql'
import { config } from '~/config'

import { Event, Day, Instance } from '~/@types/skyway'

export const name = 'events'

export const namespaced = true

export const types = {
  SET_EVENTS: 'SET_EVENTS',
  SET_EVENT: 'SET_EVENT',
  SET_EVENTS_VIEW: 'SET_EVENTS_VIEW',
  SET_SELECTED_DATE: 'SET_SELECTED_DATE',
  SET_CALENDAR: 'SET_CALENDAR',
  SET_FIRST_FEATURED_EVENT: 'SET_FIRST_FEATURED_EVENT',
  SET_EVENT_AVAILABILITY: 'SET_EVENT_AVAILABILITY',
}

export type ViewOptions = 'list' | 'grid'

export type EventTypes = {
  event: string
  education: string
  auxilliary: string
  member: string
  film: string
  performance: string
  lecture: string
  tour: string
  free: string
  exhibition: string
}

export interface State {
  event?: Event
  events: Array<Event>
  view: ViewOptions
  selectedDate?: moment.Moment
  calendar?: Array<any>
  types?: EventTypes
  firstFeaturedEvent?: Event | null
}

/**
 * Initial state, empty array of events
 */
export const state = (): State => ({
  event: undefined,
  events: [],
  view: 'list',
  selectedDate: moment(),
  calendar: [],
  types: {
    event: 'event',
    member: 'member',
    film: 'film',
    performance: 'performance',
    lecture: 'lecture',
    free: 'free',
    exhibition: 'exhibition',
    tour: 'tour',
  },
  firstFeaturedEvent: null,
})

export const getters: GetterTree<State, RootState> = {
  /**
   * Grid or list view?
   */
  chosenView(state: State): ViewOptions {
    return state.view
  },

  /**
   * Convenience method to pull a single event from our state
   */
  eventById:
    (state: State) =>
    (id: number | string): Event | undefined => {
      return state.events.find((event) => event.id == id)
    },

  /**
   * Transform calendar data to get days for a particular month
   */
  daysByMonth(state: State): Array<Day> {
    if (
      !state.calendar[moment(state.selectedDate).tz(config.TIMEZONE).month()]
    ) {
      return []
    }

    const month =
      state.calendar[moment(state.selectedDate).tz(config.TIMEZONE).month()] ||
      []

    return month
  },

  instancesByDay(state: State, getters: any): Array<Instance> {
    if (
      !getters.daysByMonth[moment(state.selectedDate).tz(config.TIMEZONE).day()]
    ) {
      return []
    }

    return getters.daysByMonth[
      moment(state.selectedDate).tz(config.TIMEZONE).day()
    ].instances
  },

  calendarInstancesByEventRef:
    (state: State, getters: any) => (ref: number | string) => {
      if (!getters.instancesByDay.length) {
        return []
      }

      return getters.instancesByDay.filter(
        (instance) => instance.event.event_ref == ref
      )
    },

  featuredEvents(state: State) {
    const featuredEvents = state.events.filter(
      (event) => event.featured === true
    )

    return featuredEvents
  },

  eventType(state: State) {
    return state.event && state.event.type
  },
}

export const actions: ActionTree<State, RootState> = {
  async getEvents(
    context: ActionContext<State, RootState>,
    type: String = 'all'
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: events['getEvents'],
      variables: {
        type,
      },
      context: {
        public: true,
      },
    })

    const { data } = response
    context.commit(types.SET_EVENTS, data.getEvents)

    return data.getEvents
  },

  async getEventsByDateRange(
    context: ActionContext<State, RootState>,
    { date_from, date_to }
  ): Promise<any> {
    const date_from_str = new Date(
      date_from.getTime() - date_from.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split('T')[0]

    const date_to_str = new Date(
      date_to.getTime() - date_to.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split('T')[0]

    const client = this.app.$apolloNonPersisted
    const response = await client.query({
      query: events['getEvents'],
      variables: {
        type: 'event,exhibition,member,film,performance,lecture,free,tour',
        start_date: date_from_str,
        end_date: date_to_str,
      },
      context: {
        public: true,
      },
    })

    const { data } = response
    context.commit(types.SET_EVENTS, data.getEvents)

    return data.getEvents
  },

  async getEventsForCalendar(
    context: ActionContext<State, RootState>,
    type: String = 'all'
  ): Promise<any> {
    const now = this.app.$moment()

    const client = this.app.$apollo
    const response = await client.query({
      query: events['getEventsForCalendar'],
      variables: {
        type,
        start_date: now.format('YYYY-MM-DDTHH:mm:ss'),
      },
      context: {
        public: true,
      },
    })

    const { data } = response
    context.commit(types.SET_EVENTS, data.getEvents)

    return data.getEvents
  },

  async getEvent(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const preview =
      this.app.context.route.query != undefined &&
      this.app.context.route.query.preview != undefined
        ? true
        : false

    const client = this.app.$apollo
    const response = await client.query({
      query: events['getEvent'],
      variables: {
        id,
        preview,
      },
      context: {
        public: true,
      },
    })

    const { data } = response
    context.commit(types.SET_EVENT, data.getEventByRef)

    return data.getEventByRef
  },

  async getEventAvailability(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.query({
      query: events['getEventAvailability'],
      variables: {
        id,
      },
      context: {
        public: true,
      },
    })

    const { data } = response
    context.commit(types.SET_EVENT_AVAILABILITY, data.getEventByRef)

    return data.getEventByRef
  },

  async getCalendarMonth(
    context: ActionContext<State, RootState>,
    isArchive = false
  ): Promise<any> {
    if (
      context.state.calendar == undefined ||
      context.state.calendar[
        moment(context.state.selectedDate).tz(config.TIMEZONE).month()
      ] == undefined
    ) {
      const client = this.app.$apollo
      const response = await client.query({
        query: events['getCalendarMonth'],
        variables: {
          year: moment(context.state.selectedDate).tz(config.TIMEZONE).year(),
          month: moment(context.state.selectedDate).tz(config.TIMEZONE).month(),
        },
        fetchPolicy: 'network-only',
      })

      const { data } = response

      context.commit(types.SET_CALENDAR, {
        calendar: data.calendarMonth,
        archive: isArchive,
      })
    }
  },

  async getTimedEventByRef(
    context: ActionContext<State, RootState>,
    id: number,
    fetchPolicy: string = 'cache-first'
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: events['getTimedEventByRef'],
      variables: {
        id,
      },
      context: {
        public: true,
      },
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_EVENT, data.getEventByRef)
    return data.getEventByRef
  },

  async getMultipleTimedEvents(
    context: ActionContext<State, RootState>,
    type: String = 'all'
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: events['getMultipleTimedEvents'],
      variables: {
        type,
      },
      context: {
        public: true,
      },
    })

    const { data } = response

    return data.getEvents
  },

  updateView(
    context: ActionContext<State, RootState>,
    view: ViewOptions
  ): void {
    context.commit(types.SET_EVENTS_VIEW, view)
  },

  getFirstFeaturedEvent(context: ActionContext<State, RootState>): void {
    context.commit(types.SET_FIRST_FEATURED_EVENT)
  },
}

export const mutations: MutationTree<State> = {
  [types.SET_EVENTS](state: State, payload: Event[]): void {
    state.events = payload.filter((item) => {
      return item.last_date && moment(item.last_date).isAfter(moment())
    })
  },
  [types.SET_EVENTS_VIEW](state: State, payload: ViewOptions): void {
    state.view = payload
  },
  [types.SET_EVENT](state: State, payload: Event): void {
    if (payload && payload.instances) {
      payload.instances.sort((a, b) => {
        if (a && b && a.date_time > b.date_time) return 1
        if (a && b && a.date_time < b.date_time) return -1
        return 0
      })
    }
    state.event = payload
  },
  [types.SET_EVENT_AVAILABILITY](state: State, payload: Event): void {
    if (payload && payload.instances && state.event && state.event.instances) {
      for (const i of state.event.instances) {
        const ref = i && i.instance_ref

        if (ref) {
          const data = payload.instances.find(
            (d) => d && d.instance_ref === ref
          )

          if (data) {
            const index = state.event.instances.findIndex((s) => s === ref)
            if (index !== -1) {
              Vue.set(state.event.instances, index, {
                ...i,
                availability: data.availability,
              })
            }
          }
        }
      }
    }
  },
  [types.SET_SELECTED_DATE](state: State, payload: moment.Moment): void {
    state.selectedDate = payload
  },
  [types.SET_CALENDAR](state: State, payload: any): void {
    if (state.calendar == undefined) state.calendar = []
    state.calendar[moment(state.selectedDate).month()] =
      payload.calendar.filter((item) => {
        const selectedMonth = moment(state.selectedDate).month() + 1
        const currentMonth = parseInt(moment().format('M'))

        if (selectedMonth != currentMonth) {
          return true
        }

        const itemDateIsInTheFuture = item.date >= moment().format('D')
        return payload.archive ? !itemDateIsInTheFuture : itemDateIsInTheFuture
      })
  },
  [types.SET_FIRST_FEATURED_EVENT](state: State): void {
    const firstFeaturedEvent =
      state.events &&
      state.events.reduce((firstEvent, event) => {
        if (event.featured && firstEvent.next_instance) {
          const nextInstance = moment(event.next_instance.date_time)
          const firstEventInstance = moment(firstEvent.next_instance.date_time)

          if (nextInstance.isBefore(firstEventInstance)) {
            firstEvent = event
          }
        } else if (event.featured) {
          firstEvent = event
        }

        return firstEvent
      }, {})

    state.firstFeaturedEvent = firstFeaturedEvent
  },
}
