import { ActionTree, ActionContext, GetterTree, MutationTree } from 'vuex'
import { RootState } from './types'
import * as custom_messages from '~/api/queries/custom_messages.gql'

import { CustomMessage, CreateCustomerServiceIssueInput } from '~/@types/skyway'

export const name = 'custom_messages'

export const namespaced = true

export const types = {
  SET_CUSTOM_MESSAGES: 'SET_CUSTOM_MESSAGES'
}

export interface State {
  custom_messages: CustomMessage[]
}

export const state = (): State => ({
  custom_messages: []
})

export const getters: GetterTree<State, RootState> = {
  getMessagesBySlug: (state: State) => (slug, page) => {
    const results = state.custom_messages.filter((message) => {
      return message.slug == slug && message.page && message.page.slug == page
    })
    if (results && results[0] && results[0].content) {
      return results[0].content
    }
    return slug
  }
}

export const actions: ActionTree<State, RootState> = {
  async getCustomMessages(
    context: ActionContext<State, RootState>,
    createCustomerServiceIssueInput: CreateCustomerServiceIssueInput
  ): Promise<any> {
    const response = await this.app.$apollo.mutate({
      mutation: custom_messages['getCustomMessages']
    })

    const { data } = response

    context.commit(types.SET_CUSTOM_MESSAGES, data.getCustomMessages)

    return data.getCustomMessages
  }
}

export const mutations: MutationTree<State> = {
  [types.SET_CUSTOM_MESSAGES](state: State, payload: CustomMessage[]): void {
    state.custom_messages = payload
  }
}
